import { useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  GetOrdersQuery,
  GetOrdersQueryVariables,
  GetOrdersUpdatesSubscription,
  GetOrdersUpdatesSubscriptionVariables,
  InvoiceState,
} from '../GraphQL/graphql'
import { GetOrdersDocument, GetOrdersUpdatesDocument } from '../GraphQL/graphql'

interface UseOrderOptions {
  invoiceState?: InvoiceState[]
}

export const useOrders = (options?: UseOrderOptions) => {
  const { data, loading, subscribeToMore } = useQuery<
    GetOrdersQuery,
    GetOrdersQueryVariables
  >(GetOrdersDocument, {
    variables: {
      invoiceState: options?.invoiceState || [],
    },
  })

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      GetOrdersUpdatesSubscription,
      GetOrdersUpdatesSubscriptionVariables
    >({
      document: GetOrdersUpdatesDocument,
      updateQuery: (prev, { subscriptionData: { data } }) => ({
        ...prev,
        ...data,
        __typename: prev.__typename,
      }),
    })

    return () => unsubscribe()
  }, [subscribeToMore])

  return useMemo(
    () => ({
      orders: data?.getOrders.orders ?? [],
      summary: data?.getOrders.summary ?? undefined,
      loading,
      error: data?.getOrders.error ?? undefined,
    }),
    [data, loading]
  )
}
