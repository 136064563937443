import { RocketLaunchIcon } from '@heroicons/react/24/solid'
import { DateTime } from 'luxon'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonLink } from '../Components/ButtonLink'
import type { MenuItem } from '../Components/DotMenu'
import { DotMenu } from '../Components/DotMenu'
import { Table } from '../Components/Table'
import type { ConversationTreeDataFragment } from '../GraphQL/graphql'
import {
  downloadContentAsZipFile,
  TREE_DATA_EXPORT_EXTENSION,
} from '../Helper/DownloadHelper'
import { useConversationTree } from '../Hooks/useConversationTree'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'
import type { AppShellComponent } from './AppShell'

type ConversationTreeDetailsParams = {
  treeId: string
}

export const ConversationTreeDetails: AppShellComponent = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const { treeId } = useParams<ConversationTreeDetailsParams>()

  const { tree } = useConversationTree(treeId)
  const {
    queries: { getConversationTreeData },
  } = useConversationTreeData()

  const getMenuItems = useCallback(
    (treeData: ConversationTreeDataFragment): MenuItem[] => [
      {
        label: t('Duplicate'),
        onClick: () =>
          navigate(`/conversation-trees/${tree?.id}/${treeData.id}/duplicate`),
      },
      {
        label: t('Export'),
        onClick: async () => {
          if (!tree) return

          const { data } = await getConversationTreeData({
            variables: { treeDataId: treeData.id },
          })

          const jsonContent = data?.getConversationTreeData.treeDataJson

          if (!jsonContent) return

          downloadContentAsZipFile(
            `export-${
              tree.identifier
            }-${DateTime.now().toISODate()}.${TREE_DATA_EXPORT_EXTENSION}`,
            JSON.stringify(jsonContent)
          )
        },
        disabled: !tree,
      },
      {
        label: t('Delete'),
        onClick: () =>
          navigate(`/conversation-trees/${tree?.id}/${treeData.id}/delete`),
      },
    ],
    [getConversationTreeData, navigate, t, tree]
  )

  return (
    <Table className="relative">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell className="text-left">
            {t('Version')}
          </Table.HeaderCell>
          <Table.HeaderCell className="text-left">
            {t('Active from')}
          </Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {tree?.data.map((treeData) => (
          <Table.Row
            key={treeData.id}
            onClick={(e) =>
              !e.isDefaultPrevented() &&
              navigate(`/conversation-trees/${tree.id}/${treeData.id}`)
            }
            className="cursor-pointer group hover:bg-gray-50"
          >
            <Table.Cell className="relative">
              {treeData.isActive && (
                <RocketLaunchIcon className="h-6 -mr-4 text-secondary-600" />
              )}
            </Table.Cell>
            <Table.Cell className="text-left">{treeData.identifier}</Table.Cell>
            <Table.Cell className="w-full truncate max-w-0">
              {treeData.activeFromTimestamp ? (
                <time dateTime={treeData.activeFromTimestamp}>
                  {DateTime.fromISO(
                    treeData.activeFromTimestamp
                  ).toLocaleString(DateTime.DATETIME_MED, {
                    locale: i18n.language ?? 'en-GB',
                  })}
                </time>
              ) : (
                <span className="italic text-gray-500">Not yet active</span>
              )}
            </Table.Cell>
            <Table.Cell className="text-left text-gray-500">
              <DotMenu menuItems={getMenuItems(treeData)} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

ConversationTreeDetails.Header = (() => {
  const { t } = useTranslation()

  const { treeId } = useParams<ConversationTreeDetailsParams>()

  const { tree } = useConversationTree(treeId)

  return (
    <>
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
          {t('Conversation Tree')}
        </h1>
        <div className="-mt-1">{tree?.identifier}</div>
        <div className="text-sm text-gray-500">{tree?.id}</div>
      </div>
      <div className="flex mt-6 space-x-3 sm:ml-4 sm:mt-0">
        <ButtonLink to="import" design="white">
          {t('Import')}
        </ButtonLink>
      </div>
    </>
  )
}) satisfies FC
