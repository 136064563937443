import classNames from 'classnames'
import { DateTime } from 'luxon'
import type { FC } from 'react'
import { Fragment, useMemo } from 'react'
import type {
  ReportDetailsFragment,
  ReportUserFragment,
} from '../GraphQL/graphql'

type Props = {
  title: string
  user: ReportUserFragment | NonNullable<ReportDetailsFragment['reviewer']>
}

const isLoading = false

export const CockpitPatientInformation: FC<Props> = ({ title, user }) => {
  const userReport = useMemo(
    () => [
      ['Anrede', user.title],
      ['Name', `${user.lastname}, ${user.firstname}`],
      [
        'Geburtsdatum',
        'birthdate' in user && user.birthdate
          ? DateTime.fromISO(user.birthdate).toLocaleString(
              DateTime.DATE_FULL,
              {
                locale: 'de-de',
              }
            )
          : undefined,
      ],
      ['E-Mail', 'email' in user ? user.email : undefined],
      ['Telefon', 'phone' in user ? user.phone : undefined],
    ],
    [user]
  )

  return (
    <>
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h2 className="text-lg font-medium leading-6 text-gray-900">{title}</h2>
      </div>
      <div className="max-w-md p-4 sm:p-6">
        <dl
          className={classNames(
            'grid grid-cols-[2fr_3fr] gap-2 items-center',
            isLoading && 'animate-pulse'
          )}
        >
          {userReport.map(([key, value]) => (
            <Fragment key={key}>
              <dt className="flex items-center justify-end text-sm font-medium text-gray-500">
                {key}
              </dt>
              <dd className="flex items-center text-sm text-gray-900">
                {isLoading ? (
                  <div className="w-12 h-3 bg-gray-300 rounded-full" />
                ) : (
                  value || (
                    <i className="text-xs text-gray-500">nicht angegeben</i>
                  )
                )}
              </dd>
            </Fragment>
          ))}
        </dl>
      </div>
    </>
  )
}
