import { Bars3Icon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import _ from 'lodash'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import type { MenuItem, MenuItemClickHandler } from '../Components/DotMenu'
import { DotMenu } from '../Components/DotMenu'
import type { ValueEditableHandler } from '../Components/ValueEditable'
import { ValueEditable } from '../Components/ValueEditable'
import type {
  ConversationTreeDataFragment,
  TreeMultiOptionObject,
  TreeStatementObject,
} from '../GraphQL/graphql'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'
import { CriterionInfo } from './CriterionInfo'

type Props = {
  treeData: ConversationTreeDataFragment
  statement: TreeStatementObject
  option: TreeMultiOptionObject | undefined
  optionGroupIndex: number
  optionIndex: number
}

export const ConversationTreeStatementMultiOption: FC<Props> = ({
  treeData,
  statement,
  option: remoteOption,
  optionGroupIndex,
  optionIndex,
}) => {
  const [option, setOption] = useState(remoteOption)

  useEffect(() => {
    setOption(remoteOption)
  }, [remoteOption])

  const {
    mutations: {
      upsertConversationTreeStatement: [upsertConversationTreeStatement],
    },
  } = useConversationTreeData()

  const onDelete: MenuItemClickHandler = useCallback(
    async ({ currentTarget }) => {
      const intermediateStatement = _.cloneDeep(statement)

      const optionGroup =
        intermediateStatement?.multiOptions?.at(optionGroupIndex)

      if (!optionGroup) return

      optionGroup.options.splice(optionIndex, 1)

      setOption(undefined)

      currentTarget.blur()

      await upsertConversationTreeStatement({
        variables: { input: intermediateStatement, treeDataId: treeData.id },
      })
    },
    [
      optionGroupIndex,
      optionIndex,
      statement,
      treeData.id,
      upsertConversationTreeStatement,
    ]
  )

  const onNameChange: ValueEditableHandler = useCallback(
    (value) => {
      if (!option || option.text.at(0)?.text === value) return

      const intermediateStatement = _.cloneDeep(statement)

      const intermediateOption = intermediateStatement.multiOptions
        ?.flatMap(({ options }) => options)
        .find(({ id }) => id === option.id)

      if (!intermediateOption) return

      const translation = intermediateOption.text.at(0)

      if (!translation) return

      translation.text = value

      setOption(intermediateOption)

      upsertConversationTreeStatement({
        variables: { input: intermediateStatement, treeDataId: treeData.id },
      })
    },
    [option, statement, treeData.id, upsertConversationTreeStatement]
  )

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        label: 'Delete',
        onClick: onDelete,
      },
    ],
    [onDelete]
  )

  if (!option) return null

  return (
    <Draggable index={optionIndex} draggableId={option.id}>
      {(provided, snapshot) => (
        <div
          className={classNames(
            'relative flex items-center justify-between px-4 py-3 gap-x-4 group transition-opacity',
            snapshot.isDragging && 'opacity-50'
          )}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="flex-none cursor-grab" {...provided.dragHandleProps}>
            <Bars3Icon
              className="w-6 h-6 text-primary-700"
              aria-hidden="true"
            />
          </div>
          <div className="flex-auto min-w-0 space-y-2">
            <ValueEditable
              className="w-full text-sm font-semibold truncate"
              value={option.text.at(0)?.text}
              onEditEnd={onNameChange}
              placeholder="No Name"
            />
            <CriterionInfo
              option={option}
              statement={statement}
              treeData={treeData}
            />
          </div>

          <DotMenu menuItems={menuItems} />
        </div>
      )}
    </Draggable>
  )
}
