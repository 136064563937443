import _ from 'lodash'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type {
  ComboboxOption,
  ComboboxOptionHandler,
} from '../Components/Combobox'
import { Combobox } from '../Components/Combobox'
import type {
  ConversationTreeDataFragment,
  ConversationTreeFragment,
  TreeMultiOptionContainerObject,
  TreeNextInput,
  TreeSingleOptionObject,
  TreeStatementObject,
} from '../GraphQL/graphql'
import { NextObjectType } from '../GraphQL/graphql'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'
import { useConversationTrees } from '../Hooks/useConversationTrees'

type Props = {
  treeData: ConversationTreeDataFragment
  statement: TreeStatementObject
  option: TreeSingleOptionObject | TreeMultiOptionContainerObject
}

export const ConversationTreeStatementOptionNext: FC<Props> = ({
  treeData,
  statement,
  option: remoteOption,
}) => {
  const [option, setOption] = useState(remoteOption)
  const [nextId, setNextId] = useState(option.next?.nextId ?? '')
  const [nextType, setNextType] = useState(option.next?.type ?? null)
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setOption(remoteOption)
  }, [remoteOption])

  const {
    mutations: {
      upsertConversationTreeStatement: [upsertConversationTreeStatement],
    },
  } = useConversationTreeData()

  const { trees } = useConversationTrees()

  const getIdsByType = useCallback(
    (
      type: NextObjectType
    ): Pick<ConversationTreeFragment, 'id' | 'identifier'>[] => {
      switch (type) {
        case NextObjectType.Action:
          return treeData.actions
        case NextObjectType.Check:
          return treeData.checks
        case NextObjectType.Statement:
          return treeData.statements
        case NextObjectType.Tree:
          return trees
      }
      return []
    },
    [treeData, trees]
  )

  const nextTypeOptions: ComboboxOption[] = useMemo(
    () =>
      Object.entries(NextObjectType).map(([value, key]) => ({
        key,
        value,
      })),
    []
  )

  const nextTypeOption: ComboboxOption | undefined = useMemo(
    () => nextTypeOptions.find(({ key }) => key === nextType),
    [nextType, nextTypeOptions]
  )

  const nextIdOptions: ComboboxOption[] = useMemo(
    () =>
      nextType
        ? _.sortBy(getIdsByType(nextType), ({ identifier }) =>
            identifier.toLowerCase()
          ).map(({ id, identifier }) => ({
            value: identifier,
            key: id,
          }))
        : [],
    [nextType, getIdsByType]
  )

  const nextIdOption: ComboboxOption | undefined = useMemo(
    () => nextIdOptions.find(({ key }) => key === nextId),
    [nextId, nextIdOptions]
  )

  const onNextChange: ComboboxOptionHandler = useCallback(
    (value) => {
      if (!nextType) return

      const intermediateStatement = _.cloneDeep(statement)

      const intermediateOption =
        intermediateStatement.singleOptions?.find(
          ({ id }) => id === option.id
        ) ??
        intermediateStatement.multiOptions?.find(({ id }) => id === option.id)

      if (!intermediateOption) return

      if (value) {
        intermediateOption.next = {
          nextId: value.key,
          type: nextType,
        } satisfies TreeNextInput

        setIsEditing(false)
        setNextId(value.key)
      } else {
        intermediateOption.next = null
        setNextId('')
      }
      setOption(intermediateOption)

      upsertConversationTreeStatement({
        variables: { input: intermediateStatement, treeDataId: treeData.id },
      })
    },
    [
      nextType,
      option.id,
      statement,
      treeData.id,
      upsertConversationTreeStatement,
    ]
  )

  return (
    <div className="flex flex-col md:flex-row md:gap-x-1">
      <div className="flex flex-row gap-x-1">
        <div className="font-semibold text-primary-700">Next</div>
        <Combobox
          className="w-28"
          inputClassName="font-semibold text-sm text-primary-700"
          options={nextTypeOptions}
          value={nextTypeOption}
          onChange={(option) =>
            option && setNextType(option.key as NextObjectType)
          }
          placeholder="Element"
          isDisabled={!isEditing}
          onDoubleClick={() => setIsEditing(true)}
        />
      </div>
      <Combobox
        inputClassName="text-sm"
        options={nextIdOptions}
        value={nextIdOption}
        onChange={onNextChange}
        onKeyDown={({ code }) => {
          switch (code) {
            case 'Escape':
              setIsEditing(false)
              break
          }
        }}
        placeholder="No Next"
        isDisabled={!isEditing}
        onDoubleClick={() => setIsEditing(true)}
      />
    </div>
  )
}
