import {
  AcademicCapIcon,
  BanknotesIcon,
  BuildingLibraryIcon,
  ShoppingBagIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { useMemo } from 'react'
import type { CardProps } from '../Components/Card'
import { CardGrid } from '../Components/CardGrid'
import { UserAvatar } from '../Components/UserAvatar'
import { AdminApplication, AdminDashboardContext } from '../GraphQL/graphql'
import { useDashboardInfo } from '../Hooks/useDashboardInfo'
import { useProfile } from '../Hooks/useProfile'
import type { AppShellComponent } from './AppShell'
import { Loading } from './Loading'

export const Dashboard: AppShellComponent = () => {
  const { infos, loading } = useDashboardInfo()

  const getUrl = (app: AdminApplication) => {
    switch (app) {
      case AdminApplication.Users:
        return '/user'
      case AdminApplication.Orders:
        return '/orders'
      case AdminApplication.OrdersReviewer:
        return '/reviewer-orders'
      case AdminApplication.Reports:
        return '/reports'
      case AdminApplication.ReportEditor:
        return '/reporteditor'
      default:
        return undefined
    }
  }

  const getIcon = (app: AdminApplication, context: AdminDashboardContext) => {
    if (context === AdminDashboardContext.Money) return BanknotesIcon
    switch (app) {
      case AdminApplication.Users:
        return UsersIcon
      case AdminApplication.Reports:
        return BuildingLibraryIcon
      case AdminApplication.Orders:
        return ShoppingBagIcon
      case AdminApplication.OrdersReviewer:
        return ShoppingBagIcon
      case AdminApplication.ReportEditor:
        return AcademicCapIcon
      default:
        return undefined
    }
  }

  const cards: CardProps[] = useMemo(() => {
    return infos.map((info) => {
      return {
        name: info.title,
        info: info.info,
        link: getUrl(info.app),
        Icon: getIcon(info.app, info.context),
      }
    })
  }, [infos])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      {/* <h2 className="text-lg font-medium leading-6">Overview</h2> */}
      <CardGrid cards={cards} />
    </>
  )
}

Dashboard.Header = (() => {
  const { profile } = useProfile()

  return (
    <div className="flex-1 min-w-0">
      {/* Profile */}
      <div className="flex items-center">
        <div>
          <div className="flex items-center">
            <UserAvatar size={8} user={profile} />
            <h1 className="ml-3 text-2xl font-bold leading-7 sm:truncate sm:leading-9">
              Welcome{profile && `, ${profile.firstname} ${profile.lastname}`}
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}) satisfies AppShellComponent['Header']
