import dicomParser from 'dicom-parser'
import { fetchFile, fetchUrl } from './FetchHelper'

export const urlInstanceRendered = async (
  instanceId: string,
  size?: number
) => {
  const res = await fetchUrl(
    `${process.env.REACT_APP_DICOM_ENDPOINT}/instances/${instanceId}/rendered/${size}`
  )
  return res
}

export const urlSeriesRendered = async (seriesId: string, size?: number) => {
  const res = fetchUrl(
    `${process.env.REACT_APP_DICOM_ENDPOINT}/series/${seriesId}/rendered/${size}`
  )
  return res
}

export const urlInstanceFile = async (instanceId: string) => {
  const res = await fetchUrl(
    `${process.env.REACT_APP_DICOM_ENDPOINT}/instances/${instanceId}/file`
  )
  return res
}

export const bufferInstanceFile = async (instanceId: string) => {
  const res = await fetchFile(
    `${process.env.REACT_APP_DICOM_ENDPOINT}/instances/${instanceId}/file`
  )
  if (res) {
    return res
  } else {
    throw new Error('File could not be fetched')
  }
}

export const parseDicomDate = (date?: string, time?: string): Date | null => {
  if (!date) {
    return null
  }
  if (time) {
    return new Date(
      `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(
        6,
        8
      )}T${time.substring(0, 2)}:${time.substring(2, 4)}:${time.substring(
        4,
        6
      )}`
    )
  } else {
    return new Date(
      `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`
    )
  }
}

export interface DicomInstanceInfo {
  files: {
    file: File
    index: number
  }[]
  instanceId: string
  patientName: string
  studyDate: string
  studyModality: string
  seriesDescription: string
}

export interface DicomSeriesFiles {
  files: {
    file: File
    instanceId: string
    index: number
  }[]
  patientName: string
  patientId: string
  seriesId: string
  seriesDate: Date | null
  studyId: string
  studyModality: string // CT
  studyDescription: string // Knie 2E
  seriesDescription: string // Knie lateral
}

const parseDicomFile = async (file: File) => {
  const byteArray = new Uint8Array(await file.arrayBuffer())
  // Allow raw files
  const options = { TransferSyntaxUID: '1.2.840.10008.1.2' }
  // Parse the byte array to get a DataSet object that has the parsed contents
  const dataSet = dicomParser.parseDicom(byteArray, options)

  const patientId = dataSet.string('x00100020') || ''
  const patientName = dataSet.string('x00100010') || ''
  const studyId = dataSet.string('x00200010') || ''
  const studyDate = dataSet.string('x00080020') || ''
  const studyModality = dataSet.string('x00080060') || ''
  const studyDescription = dataSet.string('x00081030') || ''
  const seriesDate = dataSet.string('x00080021')
  const seriesId = dataSet.string('x0020000e') || ''
  const seriesDescription = dataSet.string('x0008103e') || ''
  const instanceId = dataSet.string('x00080018') || ''
  const instanceIndex = dataSet.int16('x00200013') || 0

  return {
    patientId,
    patientName,
    studyId,
    studyDate,
    studyModality,
    studyDescription,
    seriesId,
    seriesDate,
    seriesDescription,
    instanceId,
    instanceIndex,
  }
}

export const parseDicomSeries = async (
  files: File[]
): Promise<DicomSeriesFiles[]> => {
  const series: DicomSeriesFiles[] = []

  for (const file of files) {
    const {
      patientId,
      patientName,
      studyId,
      studyDate,
      studyModality,
      studyDescription,
      seriesId,
      seriesDate,
      seriesDescription,
      instanceId,
      instanceIndex,
    } = await parseDicomFile(file)
    const existingSeries = series.find(
      (s) => s.seriesId === seriesId && s.studyId === studyId
    )
    const existingInstance = existingSeries?.files.some(
      (f) => f.instanceId === instanceId
    )
    if (existingInstance) {
      continue
    } else if (existingSeries) {
      existingSeries.files.push({
        file,
        instanceId: instanceId,
        index: instanceIndex,
      })
    } else {
      series.push({
        files: [
          {
            file,
            instanceId: instanceId,
            index: instanceIndex,
          },
        ],
        patientName: patientName,
        patientId: patientId,
        seriesId: seriesId,
        seriesDate: seriesDate
          ? parseDicomDate(seriesDate)
          : parseDicomDate(studyDate),
        studyId: studyId,
        studyModality: studyModality, // CT
        studyDescription: studyDescription, // Knie 2E
        seriesDescription: seriesDescription, // Knie lateral
      })
    }
  }
  return series
}
