import type { FC } from 'react'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../Components/Button'
import { ButtonLink } from '../Components/ButtonLink'
import type { DuplicateConversationTreeDataMutationVariables } from '../GraphQL/graphql'
import { IDENTIFIER_PATTERN } from '../Helper/IdentifierHelper'
import { useConversationTree } from '../Hooks/useConversationTree'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'
import type { AppShellComponent } from './AppShell'

type ConversationTreeDataDuplicateParams = {
  treeId: string
  treeDataId: string
}

export const ConversationTreeDataDuplicate: AppShellComponent = () => {
  const { treeId, treeDataId } =
    useParams<ConversationTreeDataDuplicateParams>()

  const navigate = useNavigate()

  const { tree } = useConversationTree(treeId)

  const {
    mutations: {
      duplicateConversationTreeData: [
        duplicateConversationTreeData,
        { loading },
      ],
    },
  } = useConversationTreeData()

  const defaultValues = useMemo(
    () => ({
      currentTreeDataId: treeDataId,
      newIdentifier: `v${(tree?.data.length ?? 0) + 1}`,
    }),
    [tree, treeDataId]
  )

  const { register, handleSubmit, reset } =
    useForm<DuplicateConversationTreeDataMutationVariables>({
      defaultValues,
    })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  const onSubmit = handleSubmit(async (userInput) => {
    const { data } = await duplicateConversationTreeData({
      variables: userInput,
    })

    const treeData = data?.duplicateConversationTreeData?.treeData

    if (!treeData) return

    navigate(`/conversation-trees/${treeId}/${treeData.id}`)
  })

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
      <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7">
            Conversation Tree Information
          </h2>
        </div>

        <form
          className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
          onSubmit={onSubmit}
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-full">
                <label
                  htmlFor="identifier"
                  className="block text-sm font-medium leading-6"
                >
                  New Identifier
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="identifier"
                    autoComplete="off"
                    placeholder="identifier.for.conversation.tree.data"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                    {...register('newIdentifier', {
                      required: true,
                      pattern: IDENTIFIER_PATTERN,
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-8">
            <ButtonLink to="../.." design="white">
              Cancel
            </ButtonLink>
            <Button type="submit" disabled={loading} design="secondary">
              Create Conversation Tree
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

ConversationTreeDataDuplicate.Header = (() => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
          {t('Create {{target}}', {
            replace: { target: t('Conversation Tree') },
          })}
        </h1>
      </div>
      <div className="flex mt-6 space-x-3 sm:ml-4 sm:mt-0">
        <ButtonLink to="../.." design="white">
          {t('Back to {{target}} dashboard', {
            replace: { target: t('Conversation Trees') },
          })}
        </ButtonLink>
      </div>
    </>
  )
}) satisfies FC
