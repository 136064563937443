import { BanknotesIcon } from '@heroicons/react/24/outline'
import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from '../Components/Button'
import type { CardProps } from '../Components/Card'
import { CardStyle } from '../Components/Card'
import { CardGrid } from '../Components/CardGrid'
import type { MenuItem } from '../Components/DotMenu'
import { DotMenu } from '../Components/DotMenu'
import { DownloadInvoice } from '../Components/DownloadInvoice'
import { Table } from '../Components/Table'
import type { OrderFragment } from '../GraphQL/graphql'
import { InvoiceState } from '../GraphQL/graphql'
import { datetimeToString, moneyToString } from '../Helper/TextHelper'
import { useOrders } from '../Hooks/useOrders'
import type { AppShellComponent } from './AppShell'
import { Loading } from './Loading'
import { LoadingError } from './LoadingError'

// const months = {
//   1: 'Januar',
//   2: 'Februar',
//   3: 'März',
//   4: 'April',
//   5: 'Mai',
//   6: 'Juni',
//   7: 'Juli',
//   8: 'August',
//   9: 'September',
//   10: 'Oktober',
//   11: 'November',
//   12: 'Dezember',
// }

// const years = {
//   2022: '2022',
//   2023: '2023',
//   2024: '2024',
// }

export const Orders: AppShellComponent = () => {
  const navigate = useNavigate()
  const { orders, summary, loading, error } = useOrders({
    invoiceState: [
      InvoiceState.Created,
      InvoiceState.Paid,
      InvoiceState.PartiallyPaid,
    ],
  })

  const cards = useMemo((): CardProps[] => {
    const cards: CardProps[] = []
    cards.push({
      name: 'Patients Total',
      Icon: BanknotesIcon,
      info: summary?.patient.values
        .map(({ total, currency }) => moneyToString(total, currency))
        .join(' | '),
      style: CardStyle.NEUTRAL,
    })
    cards.push({
      name: 'Orders',
      Icon: BanknotesIcon,
      info: orders.length,
      style: CardStyle.NEUTRAL,
    })
    return cards
  }, [orders, summary])

  const getMenuItems = useCallback((order: OrderFragment) => {
    const items: MenuItem[] = []
    items.push({
      label: 'Download Invoice',
      onClick: () => <DownloadInvoice orderId={order.id} />,
    })

    return items
  }, [])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <LoadingError />
  }

  return (
    <div className="space-y-8">
      {/* <SelectMenu items={months} />
      <SelectMenu items={years} /> */}
      <CardGrid cards={cards} />
      <Table className="relative">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="text-left">
              Order Number
            </Table.HeaderCell>
            <Table.HeaderCell className="text-left">Patient</Table.HeaderCell>
            <Table.HeaderCell className="text-left">Illness</Table.HeaderCell>
            <Table.HeaderCell className="text-left">
              Patient Payment State
            </Table.HeaderCell>
            <Table.HeaderCell className="text-left">
              Creation Date
            </Table.HeaderCell>
            <Table.HeaderCell className="text-left">
              Payment Date
            </Table.HeaderCell>
            <Table.HeaderCell className="text-left">
              Delivery Date
            </Table.HeaderCell>
            <Table.HeaderCell className="text-left">
              DocRobin Payment State
            </Table.HeaderCell>
            <Table.HeaderCell className="text-left">
              Report State
            </Table.HeaderCell>
            <Table.HeaderCell className="text-left">Total</Table.HeaderCell>
            <Table.HeaderCell className="text-left"></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {orders.map((order) => (
            <Table.Row
              key={order.id}
              onClick={() => navigate(`/orders/${order.id}`)}
              className="cursor-pointer group hover:bg-gray-50"
            >
              <Table.Cell className="text-gray-500">
                {order.orderNumber}
              </Table.Cell>
              <Table.Cell className="text-gray-500">
                {order.report.user.lastname}, {order.report.user.firstname}
              </Table.Cell>
              <Table.Cell className="text-gray-500">
                {order.report.illness.name}
              </Table.Cell>
              <Table.Cell className="text-gray-500">
                {order.summary.patient.state}
              </Table.Cell>
              <Table.Cell className="text-gray-500">
                {datetimeToString(order.createdAt)}
              </Table.Cell>
              <Table.Cell className="text-gray-500">
                {datetimeToString(order.summary.patient.paidAt)}
              </Table.Cell>
              <Table.Cell className="text-gray-500">
                {datetimeToString(order.report.releaseTimestamp)}
              </Table.Cell>
              <Table.Cell className="text-gray-500">
                {order.summary.docrobin.state}
              </Table.Cell>
              <Table.Cell className="text-gray-500">
                {order.report.released ? 'RELEASED' : 'UNRELEASED'}
              </Table.Cell>
              <Table.Cell className="text-gray-500">
                {order.summary.patient.values
                  .map(({ total, currency }) => moneyToString(total, currency))
                  .join(' | ')}
              </Table.Cell>
              <Table.Cell className="text-left text-gray-500">
                <DotMenu menuItems={getMenuItems(order)} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

Orders.Header = (() => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
          {t('Orders')}
        </h1>
      </div>
      <div className="flex mt-6 space-x-3 sm:ml-4 sm:mt-0">
        <Button design="primary" onClick={() => navigate('/orders/voided')}>
          Cancelled Orders
        </Button>
      </div>
    </>
  )
}) satisfies FC
