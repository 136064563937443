import type { FC, FormEventHandler } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../Components/Button'
import { ButtonLink } from '../Components/ButtonLink'
import { useConversationTree } from '../Hooks/useConversationTree'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'
import type { AppShellComponent } from './AppShell'

type ConversationTreeDataDeleteParams = {
  treeId: string
  treeDataId: string
}

export const ConversationTreeDataDelete: AppShellComponent = () => {
  const { treeId, treeDataId } = useParams<ConversationTreeDataDeleteParams>()

  const navigate = useNavigate()

  const [identifierConfirm, setIdentifierConfirm] = useState('')

  const { tree, treeLoading, treeError } = useConversationTree(treeId)
  const { treeData, treeDataLoading, treeDataError } =
    useConversationTreeData(treeDataId)

  const {
    mutations: {
      deleteConversationTreeData: [deleteConversationTreeData, { loading }],
    },
  } = useConversationTreeData()

  const onDelete: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()

      if (!treeDataId) return

      const { data } = await deleteConversationTreeData({
        variables: { treeDataId },
      })

      const success = data?.deleteConversationTreeData?.success

      if (!success) return

      navigate(`../..`)
    },
    [deleteConversationTreeData, navigate, treeDataId]
  )

  useEffect(() => {
    if (!treeError && !treeDataError) return

    navigate('../..')
  }, [navigate, treeDataError, treeError])

  if (treeLoading || !tree || treeDataLoading || !treeData) return null

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
      <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7">
            Delete Conversation Tree Version
          </h2>
        </div>

        <form
          onSubmit={onDelete}
          className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-full">
                Do you really want to delete{' '}
                <pre className="inline">{treeData.identifier}</pre> from{' '}
                <pre className="inline">{tree.identifier}</pre>?
              </div>
              <div className="sm:col-span-full">
                <label
                  htmlFor="identifier-confirm"
                  className="block text-sm font-medium leading-6"
                >
                  Type <pre className="inline">{treeData.identifier}</pre> into
                  the field below to confirm.
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="identifier-confirm"
                    name="identifier-confirm"
                    autoComplete="off"
                    placeholder={treeData.identifier}
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                    value={identifierConfirm}
                    onChange={({ currentTarget }) =>
                      setIdentifierConfirm(currentTarget.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-8">
            <ButtonLink to="../.." design="white">
              Cancel
            </ButtonLink>
            <Button
              type="submit"
              disabled={loading || treeData.identifier !== identifierConfirm}
              design="secondary"
            >
              Delete Conversation Tree
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

ConversationTreeDataDelete.Header = (() => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
          {t('Delete {{target}}', {
            replace: { target: t('Conversation Tree Version') },
          })}
        </h1>
      </div>
      <div className="flex mt-6 space-x-3 sm:ml-4 sm:mt-0">
        <ButtonLink to="../.." design="white">
          {t('Back to {{target}} dashboard', {
            replace: { target: t('Conversation Trees') },
          })}
        </ButtonLink>
      </div>
    </>
  )
}) satisfies FC
