import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import type { FC } from 'react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { ConversationTreeStatementInputType } from '../GraphQL/graphql'

export type StatementTypeSelectorHandler = (
  statementType: ConversationTreeStatementInputType
) => void

type Props = {
  statementType: ConversationTreeStatementInputType
  setStatementType: (statementType: ConversationTreeStatementInputType) => void
  isDisabled?: boolean
}

export const StatementTypeSelector: FC<Props> = ({
  isDisabled,
  statementType,
  setStatementType,
}) => {
  const { t } = useTranslation()

  return (
    <Listbox
      value={statementType}
      onChange={setStatementType}
      disabled={isDisabled}
    >
      {({ open, disabled }) => (
        <div className="relative">
          <Listbox.Button
            className={classNames(
              'relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-600 sm:text-sm sm:leading-6',
              disabled && 'opacity-50'
            )}
          >
            <span className="block truncate">
              {t(`ConversationTreeStatementInputType.${statementType}`)}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronUpDownIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {Object.values(ConversationTreeStatementInputType).map((type) => (
                <Listbox.Option
                  key={type}
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-primary-600 text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9'
                    )
                  }
                  value={type}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? 'font-semibold' : 'font-normal',
                          'block truncate'
                        )}
                      >
                        {t(`ConversationTreeStatementInputType.${type}`)}
                      </span>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-primary-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}
