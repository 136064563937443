import type { Types as CornerstoneTypes } from '@cornerstonejs/core'
import * as cornerstone3D from '@cornerstonejs/core'
import {
  getRenderingEngine,
  isCornerstoneInitialized,
  RenderingEngine,
} from '@cornerstonejs/core'
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader'
import {
  addTool,
  annotation,
  init as cornerstoneToolsInit,
  Enums,
  LengthTool,
  PanTool,
  StackScrollMouseWheelTool,
  StackScrollTool,
  WindowLevelTool,
  ZoomTool,
} from '@cornerstonejs/tools'
import type { Types } from '@cornerstonejs/tools'
import dicomParser from 'dicom-parser'
import type { ImageAnnotationFragment } from '../GraphQL/graphql'
import { AnnotationType } from '../GraphQL/graphql'
import { authorizationHeader, getAccessToken } from './AuthHelper'

const CORNERSTONE_RENDERING_ENGINE_ID = 'Cornerstone-RenderingEngine'

export const initializeCornerstone =
  async (): Promise<CornerstoneTypes.IRenderingEngine> => {
    if (!isCornerstoneInitialized()) {
      localStorage.setItem('debug', 'cornerstoneTools')

      await cornerstone3D.init()
      cornerstoneToolsInit({
        mouseEnabled: true,
        touchEnabled: true,
        globalToolSyncEnabled: false,
        showSVGCursors: true,
      })

      cornerstoneDICOMImageLoader.external.cornerstone = cornerstone3D
      cornerstoneDICOMImageLoader.external.dicomParser = dicomParser
      cornerstoneDICOMImageLoader.configure({
        useWebWorkers: true,
        decodeConfig: {
          convertFloatPixelDataToInt: false,
        },
        beforeSend: function (xhr: {
          setRequestHeader: (arg0: string, arg1: string | undefined) => void
        }) {
          // Add custom headers here (e.g. auth tokens)
          xhr.setRequestHeader(
            'authorization',
            authorizationHeader(getAccessToken())
          )
        },
      })

      cornerstoneDICOMImageLoader.webWorkerManager.initialize({
        webWorkerTaskPaths: [
          // TODO: move away from unpkg?!
          'https://unpkg.com/cornerstone-wado-image-loader@4.8.0/dist/610.bundle.min.worker.js',
          'https://unpkg.com/cornerstone-wado-image-loader@4.8.0/dist/888.bundle.min.worker.js',
        ],
        maxWebWorkers: navigator.hardwareConcurrency || 1,
        startWebWorkersOnDemand: true,
        taskConfiguration: {
          decodeTask: {
            loadCodecsOnStartup: false,
            initializeCodecsOnStartup: false,
            strict: false,
          },
        },
      })
    }

    const renderingEngine =
      getRenderingEngine(CORNERSTONE_RENDERING_ENGINE_ID) ??
      new RenderingEngine(CORNERSTONE_RENDERING_ENGINE_ID)

    window.onresize = () => renderingEngine.resize()

    return renderingEngine
  }

export enum ToolSet {
  PanZoom = 'Pan & Zoom',
  WindowLevel = 'WindowLevel',
  Length = 'Length',
}

// TODO: find a better type for `tool`
const toolConfig: { tool: { toolName: string }; options?: Types.ToolProps }[] =
  [
    { tool: WindowLevelTool },
    { tool: LengthTool },
    { tool: PanTool },
    { tool: StackScrollTool },
    { tool: StackScrollMouseWheelTool },
    {
      tool: ZoomTool,
      options: {
        configuration: {
          invert: false,
          preventZoomOutsideImage: false,
          minScale: 0.1,
          maxScale: 20.0,
        },
      },
    },
  ]

export const addAllTools = (toolGroup: Types.IToolGroup | undefined) => {
  toolConfig.forEach(({ tool, options }) => {
    addTool(tool)
    toolGroup?.addTool(tool.toolName, options)
  })
}

const setAllToolsPassive = (toolGroup: Types.IToolGroup | undefined) => {
  toolConfig.forEach(({ tool }) => {
    toolGroup?.setToolPassive(tool.toolName)
  })
}

const toolSetConfig: Record<
  ToolSet,
  { buttons: [Enums.MouseBindings, string][] }
> = {
  [ToolSet.Length]: {
    buttons: [[Enums.MouseBindings.Primary, LengthTool.toolName]],
  },
  [ToolSet.PanZoom]: {
    buttons: [
      [Enums.MouseBindings.Primary, PanTool.toolName],
      [Enums.MouseBindings.Secondary, ZoomTool.toolName],
    ],
  },
  [ToolSet.WindowLevel]: {
    buttons: [[Enums.MouseBindings.Primary, WindowLevelTool.toolName]],
  },
}

export const setTool = (
  tool: ToolSet,
  toolGroup: Types.IToolGroup | undefined
) => {
  setAllToolsPassive(toolGroup)

  toolGroup?.setToolActive(StackScrollMouseWheelTool.toolName)

  toolSetConfig[tool].buttons.forEach(([mouseButton, toolName]) =>
    toolGroup?.setToolActive(toolName, {
      bindings: [
        {
          mouseButton,
        },
      ],
    })
  )
}

export const imageUriToInstanceId = (imageUri: string): string | undefined => {
  // wadouri:http://localhost:4000/pacs/instances/e939381d-89ff3385-d6b58680-645dda51-fdaa47cd/file
  const uriSplit = imageUri.split('/')
  const i = uriSplit.findIndex((part) => part === 'instances')
  return i !== undefined ? uriSplit.at(i + 1) : undefined
}

// const isPoint3 = (points: number[]): points is CornerstoneTypes.Point3 => {
//   return points.length === 3
// }

const scaleAndConvertPoint = (
  point: number[],
  scaling: [number, number, number] = [1, 1, 1]
): CornerstoneTypes.Point3 => {
  const [spacingX, spacingY, spacingZ] = scaling
  return [
    (point[0] ?? 0) * spacingX,
    (point[1] ?? 0) * spacingY,
    (point[2] ?? 0) * spacingZ,
  ]
}

export const applyMeasurements = (
  imageURI: string,
  measurements: ImageAnnotationFragment[],
  element: HTMLDivElement,
  viewport: CornerstoneTypes.IStackViewport | CornerstoneTypes.IVolumeViewport
) => {
  if (!imageURI) return

  annotation.state.removeAllAnnotations()

  const scaling = viewport.getImageData()?.spacing

  measurements
    .filter(({ type }) => type === AnnotationType.Line)
    .filter(({ dicomInstanceId }) => imageURI.includes(dicomInstanceId))
    .map(
      ({ id, points, unit, value }): Types.Annotation => ({
        annotationUID: id,
        isLocked: true,
        data: {
          handles: {
            points: [
              scaleAndConvertPoint(points[0], scaling),
              scaleAndConvertPoint(points[1], scaling),
            ],
            textBox: {
              hasMoved: false,
              worldPosition: [0, 0, 0],
              worldBoundingBox: {
                topLeft: [0, 0, 0],
                topRight: [0, 0, 0],
                bottomLeft: [0, 0, 0],
                bottomRight: [0, 0, 0],
              },
            },
          },
          cachedStats: {
            [`imageId:${imageURI}`]: {
              length: value ? +value : 0,
              unit,
            },
          },
        },
        metadata: {
          FrameOfReferenceUID: undefined as never,
          referencedImageId: imageURI,
          toolName: LengthTool.toolName,
        },
      })
    )
    .forEach(async (annotationData) => {
      annotation.state.addAnnotation(
        {
          ...annotationData,
        },
        ''
      )
    })
}
