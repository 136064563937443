import type { TFunction } from 'i18next'
import { ProfileError } from '../GraphQL/graphql'

export const profileErrorToMessage = (
  error: ProfileError,
  t: TFunction
): string => {
  // TODO: translate ProfileErrors
  switch (error) {
    case ProfileError.InvalidBirthdateFormat:
      return 'Das Geburtsdatum hat kein gültiges Format.'
    case ProfileError.InvalidCityFormat:
      return 'Die Stadt der Adresse muss mindestens einen Buchstaben haben.'
    case ProfileError.InvalidCountryFormat:
      return 'Das Land der Adresse hat kein gültiges Format.'
    case ProfileError.InvalidEmailFormat:
      return 'Die E-Mail hat kein gültiges Format.'
    case ProfileError.InvalidFirstnameFormat:
      return 'Der Vorname muss mindestens einen Buchstaben haben.'
    case ProfileError.InvalidInstructionFormat:
      return 'Fehler bei den Lieferanweisungen der Adresse.'
    case ProfileError.InvalidLastnameFormat:
      return 'Der Nachname muss mindestens einen Buchstaben haben.'
    case ProfileError.InvalidPasswordFormat:
      return 'Das Passwort muss aus mindestens sechs Ziffern bestehen.'
    case ProfileError.InvalidPhoneFormat:
      return 'Die Telefonnummer hat kein gültiges Format.'
    case ProfileError.InvalidStreetFormat:
      return 'Die Straße der Adresse muss aus mindestens einem Buchstaben bestehen.'
    case ProfileError.InvalidTitleFormat:
      return 'Fehler beim Namenstitel.'
    case ProfileError.InvalidZipFormat:
      return 'Die Postleitzahl ist nicht gültig.'
    case ProfileError.UnsupportedCountry:
      return 'Dieses Land wird noch nicht unterstützt.'
  }
  return t('Error')
}
