import { Disclosure } from '@headlessui/react'
import { ArrowPathIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import _ from 'lodash'
import type { FC, MouseEventHandler } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { DeleteButton } from '../Components/DeleteButton'
import type { ValueEditableHandler } from '../Components/ValueEditable'
import { ValueEditable } from '../Components/ValueEditable'
import type { TreeCriterionObject } from '../GraphQL/graphql'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'

type Props = {
  treeDataId: string
  criterion: TreeCriterionObject
  activeId: string
}

export const ConversationTreeCriterion: FC<Props> = ({
  treeDataId,
  criterion: remoteCriterion,
  activeId,
}) => {
  const [criterion, setCriterion] = useState(remoteCriterion)
  const [isDeleting, setIsDeleting] = useState(false)

  useEffect(() => {
    setCriterion(remoteCriterion)
    setIsDeleting(false)
  }, [remoteCriterion])

  const {
    treeData,
    validation,
    mutations: {
      deleteConversationTreeCriterion: [deleteConversationTreeCriterion],
      upsertConversationTreeCriterion: [upsertConversationTreeCriterion],
    },
  } = useConversationTreeData(treeDataId)

  const onIdentifierChange: ValueEditableHandler = useCallback(
    (value) => {
      if (criterion.identifier === value) return

      const intermediateCriterion = _.cloneDeep(criterion)

      intermediateCriterion.identifier = value

      setCriterion(intermediateCriterion)

      upsertConversationTreeCriterion({
        variables: { input: intermediateCriterion, treeDataId },
      })
    },
    [criterion, treeDataId, upsertConversationTreeCriterion]
  )

  const onValueChange: ValueEditableHandler = useCallback(
    (value) => {
      if (criterion.value === value) return

      const intermediateCriterion = _.cloneDeep(criterion)

      intermediateCriterion.value = value

      setCriterion(intermediateCriterion)

      upsertConversationTreeCriterion({
        variables: { input: intermediateCriterion, treeDataId },
      })
    },
    [criterion, treeDataId, upsertConversationTreeCriterion]
  )

  const onValidatorChange: ValueEditableHandler = useCallback(
    (value) => {
      if (criterion.validator === value) return

      const intermediateCriterion = _.cloneDeep(criterion)

      intermediateCriterion.validator = value

      setCriterion(intermediateCriterion)

      upsertConversationTreeCriterion({
        variables: { input: intermediateCriterion, treeDataId },
      })
    },
    [criterion, treeDataId, upsertConversationTreeCriterion]
  )

  const onDelete: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setIsDeleting(true)

    deleteConversationTreeCriterion({
      variables: { treeDataId, criterionId: criterion.id },
    })
  }, [deleteConversationTreeCriterion, criterion.id, treeDataId])

  return (
    <Disclosure
      as="div"
      id={criterion.id}
      className={classNames('bg-white', isDeleting && 'animate-pulse')}
    >
      <div
        className={classNames(
          'flex items-center p-4 gap-x-4 sm:gap-x-6 sm:px-6',
          criterion.id === activeId && 'bg-primary-700/20',
          validation?.unusedCriteria.includes(criterion.id) && 'opacity-50'
        )}
      >
        <Disclosure.Button className="flex-none">
          {isDeleting || !treeData || !criterion ? (
            <ArrowPathIcon className="w-12 h-12 p-3 -m-3 animate-spin" />
          ) : (
            <ChevronRightIcon className="w-12 h-12 p-3 -m-3 transition ui-open:rotate-90" />
          )}
        </Disclosure.Button>

        <ValueEditable
          className="flex-auto"
          invalid={
            validation?.invalidElementIdentifiers.includes(criterion.id) ||
            validation?.duplicateElementIdentifiers.includes(criterion.id)
          }
          value={criterion.identifier}
          onEditEnd={onIdentifierChange}
          intermediatePattern={/^\S*$/g}
        />
      </div>

      {treeData && (
        <Disclosure.Panel className="flex flex-col gap-6 px-6 py-4 text-sm bg-gray-100 sm:px-6 whitespace-nowrap sm:flex-row">
          <div className="flex-auto">
            <div className="flex flex-col min-w-0 px-4 py-3 space-y-4 bg-white rounded-md shadow">
              <pre className="flex gap-x-2">
                <div className="flex-none text-sm text-gray-500">
                  const value =
                </div>
                <ValueEditable
                  className="flex-auto text-sm"
                  value={criterion.value}
                  onEditEnd={onValueChange}
                />
              </pre>
              <pre className="flex gap-x-2">
                <div className="flex-none text-sm text-gray-500">
                  const isCriterionValid = value
                </div>
                <ValueEditable
                  className="w-full text-sm"
                  value={criterion.validator}
                  onEditEnd={onValidatorChange}
                />
              </pre>
              <pre className="flex text-gray-500 gap-x-2">
                return isCriterionValid
              </pre>
            </div>
          </div>
          <div className="flex-shrink w-full space-y-4 sm:w-64 md:w-72">
            <div className="bg-white divide-y rounded-md shadow divide-primary-400">
              <div className="px-4 py-3 font-semibold text-primary-700">
                Criterion Options
              </div>
              <div className="relative flex justify-between px-4 py-3 gap-x-4">
                <div className="flex-auto max-w-full space-y-2">
                  <div className="flex items-center justify-between gap-x-4">
                    <div className="truncate">Delete Criterion</div>
                    <DeleteButton className="flex-none" onDelete={onDelete} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Disclosure.Panel>
      )}
    </Disclosure>
  )
}
