import classNames from 'classnames'
import _ from 'lodash'
import type {
  FocusEventHandler,
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  RefAttributes,
} from 'react'
import { forwardRef, useEffect, useState } from 'react'

export type ValueEditableHandler = (newValue: string) => void

type Props = InputHTMLAttributes<HTMLInputElement> &
  RefAttributes<HTMLInputElement> & {
    invalid?: boolean
    isAlwaysEditable?: boolean
    onEditEnd?: ValueEditableHandler
    intermediatePattern?: RegExp
  }

export const ValueEditable: ForwardRefExoticComponent<Props> = forwardRef(
  (
    {
      value: initialValue = '',
      invalid,
      isAlwaysEditable = false,
      onEditEnd = _.noop,
      className,
      intermediatePattern = /.*/i,
      ...args
    },
    ref
  ) => {
    const [isEditing, setIsEditing] = useState(isAlwaysEditable)
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
      setIsEditing(isAlwaysEditable)
      onEditEnd(e.currentTarget.value)
    }

    return (
      <input
        ref={ref}
        className={classNames(
          className,
          'pb-px bg-transparent border-0 border-b rounded border-secondary-700 focus-visible:outline-none read-only:border-transparent placeholder:italic',
          invalid && '!text-red-500'
        )}
        readOnly={!isEditing}
        onDoubleClick={() => !isEditing && setIsEditing(true)}
        value={value}
        onInput={({ currentTarget }) =>
          currentTarget.value?.match(intermediatePattern) &&
          setValue(currentTarget.value)
        }
        onBlur={onBlur}
        onKeyDown={(e) => {
          switch (e.code) {
            case 'Enter':
              e.currentTarget.blur()
              break

            case 'Escape':
              setValue(initialValue)
              e.currentTarget.value = '' + initialValue
              e.currentTarget.blur()
              break
          }
        }}
        {...args}
      />
    )
  }
)
