import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from '../Components/Button'
import { ButtonLink } from '../Components/ButtonLink'
import { Table } from '../Components/Table'
import { UserAvatar } from '../Components/UserAvatar'
import type {
  DeleteInactiveEmptyUsersMutation,
  DeleteInactiveEmptyUsersMutationVariables,
} from '../GraphQL/graphql'
import { DeleteInactiveEmptyUsersDocument } from '../GraphQL/graphql'
import { useRegisteredUsers } from '../Hooks/useRegisteredUsers'
import type { AppShellComponent } from './AppShell'

export const Users: AppShellComponent = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const { users, usersLoading } = useRegisteredUsers()

  if (usersLoading) {
    return null
  }

  return (
    <>
      <h2 className="mb-2 text-lg font-medium leading-6">
        {t('Recent registrations')}
      </h2>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="text-left">User</Table.HeaderCell>
            <Table.HeaderCell className="text-right">Date</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {users?.map((user) => {
            const lastActiveSession = user.conversationSessions?.reduce(
              (prev, curr) => {
                if (!prev) {
                  return curr
                }

                return prev.lastActiveTimestamp > curr.lastActiveTimestamp
                  ? prev
                  : curr
              },
              user.conversationSessions?.at(0)
            )

            const progress = (lastActiveSession?.progress ?? 0) * 100

            return (
              <Table.Row
                key={user.id}
                onClick={() =>
                  navigate(
                    `/${user.isResearch ? 'research-' : ''}users/${user.id}`
                  )
                }
                className="relative cursor-pointer group hover:bg-gray-50"
              >
                <Table.Cell className="w-full max-w-0">
                  <div className="flex">
                    <div
                      className={classNames(
                        'inline-flex space-x-2 text-sm truncate group',
                        !user.isActivated && 'grayscale'
                      )}
                    >
                      <UserAvatar size={5} user={user} />
                      <p
                        className={classNames(
                          'truncate group-hover:text-gray-900',
                          !user.isActivated && 'line-through'
                        )}
                      >
                        {user.isResearch ? (
                          <>
                            <span className="font-mono">
                              {user?.researchParticipantId}
                            </span>{' '}
                            <span className="font-medium text-gray-500">
                              Research User
                            </span>
                          </>
                        ) : (
                          <>
                            {user.lastname}{' '}
                            <span className="font-medium text-gray-500">
                              {user.firstname}
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                  </div>

                  {/* Progress Bar */}
                  <div
                    className="absolute left-0 h-0.5 bottom-px bg-primary-600/30 group-hover:bg-primary-600/60 transition duration-300"
                    style={{
                      width: `${progress}%`,
                    }}
                    title={`${Math.round(progress)}% of last session`}
                  />
                </Table.Cell>
                <Table.Cell className="text-right text-gray-500">
                  {user.registrationTimestamp && (
                    <time dateTime={user.registrationTimestamp}>
                      {DateTime.fromISO(
                        user.registrationTimestamp
                      ).toLocaleString(DateTime.DATE_MED, {
                        locale: i18n.language ?? 'en-GB',
                      })}
                    </time>
                  )}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Cell colSpan={9999}>
              <nav
                className="flex items-center justify-between"
                aria-label="Pagination"
              >
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">1</span> to{' '}
                  <span className="font-medium">10</span> of{' '}
                  <span className="font-medium">{users?.length ?? 0}</span>{' '}
                  users
                </p>
              </nav>
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  )
}

Users.Header = (() => {
  const { t } = useTranslation()

  const [deleteInactiveEmptyUsers] = useMutation<
    DeleteInactiveEmptyUsersMutation,
    DeleteInactiveEmptyUsersMutationVariables
  >(DeleteInactiveEmptyUsersDocument)

  return (
    <>
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
          {t('Users')}
        </h1>
      </div>
      <div className="flex mt-6 space-x-3 sm:ml-4 sm:mt-0">
        <Button design="white" onClick={() => deleteInactiveEmptyUsers()}>
          Delete inactive users
        </Button>
        {/* <ButtonLink design="secondary" to="/users/create">
          Add User
        </ButtonLink> */}
        <ButtonLink design="secondary" to="/research-users/create">
          Add Research User
        </ButtonLink>
      </div>
    </>
  )
}) satisfies FC
