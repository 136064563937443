import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import type {
  GetRegisteredUsersQuery,
  GetRegisteredUsersQueryVariables,
} from '../GraphQL/graphql'
import { GetRegisteredUsersDocument } from '../GraphQL/graphql'

type UserParams = {
  userId?: string
}

export const useParamUser = () => {
  const { userId = -1 } = useParams<UserParams>()

  const { data, loading } = useQuery<
    GetRegisteredUsersQuery,
    GetRegisteredUsersQueryVariables
  >(GetRegisteredUsersDocument)

  const users = useMemo(() => data?.getRegisteredUsers, [data])

  const user = useMemo(
    () => users?.find(({ id }) => id === userId),
    [users, userId]
  )

  return useMemo(() => ({ user, userLoading: loading }), [loading, user])
}
