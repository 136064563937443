import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../Components/Button'
import type {
  DicomSeriesFragment,
  ReportDetailsFragment,
  ReportUserFragment,
  SetDicomSeriesOfReportMutation,
  SetDicomSeriesOfReportMutationVariables,
} from '../GraphQL/graphql'
import { SetDicomSeriesOfReportDocument } from '../GraphQL/graphql'
import { ToolSet } from '../Helper/CornerstoneHelper'
import { useDicomPatient } from '../Hooks/useDicomPatient'
import { useInstanceAnnotations } from '../Hooks/useInstanceAnnotations'
import { useSeriesOperands } from '../Hooks/useSeriesOperands'
import { Loading } from '../Pages/Loading'
import { CockpitDicomPreview } from './CockpitDicomPreview'
import { CockpitFindingsReport } from './CockpitFindingsReport'
import { CockpitPatientInformation } from './CockpitPatientInformation'
import { CockpitXRayReport } from './CockpitXRayReport'
import { DicomViewer } from './DicomViewer'

type Props = {
  user: ReportUserFragment
  report: ReportDetailsFragment
}

const toolNames = Object.entries(ToolSet)

export const CockpitDetails: FC<Props> = ({ user, report }) => {
  const { t } = useTranslation()

  const [instanceId, setInstanceId] = useState<string>()

  const { dicomPatient, dicomPatientLoading } = useDicomPatient(user.id)

  const [setDicomSeriesOfReport, { loading: setDicomSeriesLoading }] =
    useMutation<
      SetDicomSeriesOfReportMutation,
      SetDicomSeriesOfReportMutationVariables
    >(SetDicomSeriesOfReportDocument, {
      fetchPolicy: 'network-only',
    })

  const [selectedDicomTool, setSelectedDicomTool] = useState(ToolSet.PanZoom)
  const [dicomSeries, setDicomSeries] = useState<DicomSeriesFragment>()

  const { seriesOperands, seriesOperandsLoading } = useSeriesOperands(
    dicomSeries?.id
  )
  const { instanceAnnotations } = useInstanceAnnotations(instanceId)

  const allDicomSeries = useMemo(
    () => dicomPatient?.studies.flatMap(({ series }) => series),
    [dicomPatient?.studies]
  )

  // TODO: add proper annotations for instance
  // const { imageAnnotations, imageAnnotationsLoading } = useImageAnnotations()
  // const [saveImageAnnotation] = useMutation<
  //   SaveImageAnnotationMutation,
  //   SaveImageAnnotationMutationVariables
  // >(SaveImageAnnotationDocument)

  // const input: ImageAnnotationInput = {
  //   id: '133',
  //   dicomInstanceId: '123',
  //   points: [
  //     [10, 20, 0],
  //     [30, 40, 0],
  //   ],
  //   type: AnnotationType.Line,
  //   unit: 'mm',
  //   value: '2',
  // }

  // saveImageAnnotation({
  //   variables: {
  //     input,
  //   },
  // })

  const generateReport = useCallback(async () => {
    if (!dicomSeries) return
    try {
      await setDicomSeriesOfReport({
        variables: {
          reportId: report.id,
          dicomSeriesIds: [dicomSeries.id],
          regenerate: true,
        },
      })
    } catch (error) {
      alert(error)
    }
  }, [dicomSeries, setDicomSeriesOfReport, report])

  if (dicomPatientLoading) {
    return <Loading />
  }

  return (
    <div className="grid grid-cols-5 gap-4">
      <div className="col-span-5 space-y-4 lg:col-span-3">
        <div className="px-4 sm:px-0">
          <div className="flex divide-x divide-gray-200 rounded-lg shadow isolate">
            {toolNames.map(([, tool], tabIdx) => (
              <button
                key={tool}
                className={classNames(
                  tool === selectedDicomTool
                    ? 'text-gray-900'
                    : 'text-gray-500 hover:text-gray-700',
                  tabIdx === 0 && 'rounded-l-lg',
                  tabIdx === toolNames.length - 1 && 'rounded-r-lg',
                  'group relative min-w-0 flex-1 overflow-hidden bg-white p-2 sm:py-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                )}
                onClick={() => {
                  setSelectedDicomTool(tool)
                }}
              >
                <div className="truncate">{tool}</div>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tool === selectedDicomTool
                      ? 'bg-green-500'
                      : 'bg-transparent',
                    'absolute inset-x-0 bottom-0 h-0.5'
                  )}
                />
              </button>
            ))}
          </div>
        </div>
        <div className="max-w-full max-h-[75vh] mx-auto aspect-square sm:rounded-lg shadow isolate overflow-hidden bg-white">
          {dicomSeries && (
            <DicomViewer
              measurements={instanceAnnotations}
              dicomSeries={dicomSeries}
              tool={selectedDicomTool}
              onInstanceChange={setInstanceId}
            />
          )}
        </div>
        {allDicomSeries && (
          <div className="bg-white shadow sm:rounded-lg isolate">
            <CockpitDicomPreview
              reportId={report.id}
              allDicomSeries={allDicomSeries}
              setDicomSeries={setDicomSeries}
            />
          </div>
        )}
      </div>
      <div className="col-span-5 lg:col-span-2">
        <div className="sticky space-y-4 top-4">
          <section>
            <div className="bg-white shadow sm:rounded-lg">
              <CockpitPatientInformation title="Patient" user={user} />
            </div>
          </section>
          <section>
            <div className="bg-white shadow sm:rounded-lg">
              <CockpitXRayReport
                imageOperands={seriesOperands}
                dicomSeries={dicomSeries}
                isLoading={seriesOperandsLoading}
              />
            </div>
          </section>
          {!!seriesOperands.length && (
            <section>
              <div className="flex flex-wrap justify-end gap-4 mx-4 sm:mx-0">
                <Button
                  onClick={generateReport}
                  disabled={setDicomSeriesLoading}
                >
                  {t('Link DICOM and regenerate')}
                </Button>
              </div>
            </section>
          )}
        </div>
      </div>
      {report && (
        <div className="col-span-5 space-y-4">
          <CockpitFindingsReport
            report={report}
            isLoading={setDicomSeriesLoading}
          />
        </div>
      )}
    </div>
  )
}
