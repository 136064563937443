import {
  AcademicCapIcon,
  ArrowLeftOnRectangleIcon,
  Bars3CenterLeftIcon,
  BeakerIcon,
  BuildingLibraryIcon,
  HomeIcon,
  PencilSquareIcon,
  ShoppingBagIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { t } from 'i18next'
import _ from 'lodash'
import type { ComponentType, FC } from 'react'
import { useMemo, useState } from 'react'
import type { NavigationItemType } from '../Components/NavigationItem'
import { UserAvatar } from '../Components/UserAvatar'
import { AdminApplication } from '../GraphQL/graphql'
import { useProfile } from '../Hooks/useProfile'
import { NavigationDesktop } from '../Modules/NavigationDesktop'
import { NavigationMobile } from '../Modules/NavigationMobile'

export type AppShellComponentConfig = {
  fullwidth: boolean
}

export type AppShellComponent = ComponentType & {
  Header?: ComponentType
  config?: Partial<AppShellComponentConfig>
}

const secondaryNavigation = [
  { name: 'Logout', href: '/logout', Icon: ArrowLeftOnRectangleIcon },
] satisfies NavigationItemType[]

type Props = {
  Child: AppShellComponent
}

export const AppShell: FC<Props> = ({ Child }) => {
  const childConfig: AppShellComponentConfig = _.merge(
    {
      fullwidth: false,
    } satisfies AppShellComponentConfig,
    Child.config
  )

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const { profile } = useProfile()

  const navigation = useMemo(() => {
    const navigation: NavigationItemType[] = [
      { name: 'Dashboard', href: '/dashboard', Icon: HomeIcon },
    ]

    profile?.rights.adminApplications.includes(AdminApplication.Users) &&
      navigation.push({ name: 'Users', href: '/users', Icon: UserGroupIcon })

    profile?.rights.adminApplications.includes(
      AdminApplication.ConversationTrees
    ) &&
      navigation.push({
        name: 'Conversation Trees',
        href: '/conversation-trees',
        Icon: PencilSquareIcon,
      })

    profile?.rights.adminApplications.includes(AdminApplication.ReportEditor) &&
      navigation.push({
        name: t('Report Editor'),
        href: '/reporteditor',
        Icon: AcademicCapIcon,
      })

    profile?.rights.adminApplications.includes(AdminApplication.Reports) &&
      navigation.push({
        name: t('Reports'),
        href: '/reports',
        Icon: BuildingLibraryIcon,
      })

    profile?.rights.adminApplications.includes(AdminApplication.Orders) &&
      navigation.push({
        name: t('Products'),
        href: '/products',
        Icon: BeakerIcon,
      })

    // profile?.rights.adminApplications.includes(
    //   AdminApplication.OrdersReviewer
    // ) &&
    //   navigation.push({
    //     name: 'Bestellungen Arzt',
    //     href: '/orders-reviewer',
    //     Icon: ShoppingBagIcon,
    //   })

    profile?.rights.adminApplications.includes(AdminApplication.Products) &&
      navigation.push({
        name: 'Bestellungen',
        href: '/orders',
        Icon: ShoppingBagIcon,
      })
    return navigation
  }, [profile])

  return (
    <div className="h-full min-h-full">
      <NavigationMobile
        navigation={navigation}
        secondaryNavigation={secondaryNavigation}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      <NavigationDesktop
        navigation={navigation}
        secondaryNavigation={secondaryNavigation}
      />

      <div className="flex flex-col h-full min-h-full lg:pl-64">
        <div
          className={classNames(
            !Child.Header && 'shadow',
            'flex flex-shrink-0 h-16 bg-white border-b border-gray-200 lg:border-none'
          )}
        >
          <button
            type="button"
            className="px-4 text-gray-400 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3CenterLeftIcon className="w-6 h-6" aria-hidden="true" />
          </button>

          <div className="flex justify-between flex-1 px-4 sm:px-6 lg:mx-auto lg:max-w-7xl lg:px-8">
            <div className="flex flex-1" />
            <div className="flex items-center ml-4 md:ml-6">
              <div className="flex items-center max-w-xs text-sm bg-white lg:rounded-md lg:p-2">
                <UserAvatar size={6} user={profile} />
                <span className="hidden ml-3 text-sm font-medium text-gray-700 lg:block">
                  {profile?.firstname} {profile?.lastname}
                </span>
              </div>
            </div>
          </div>
        </div>
        <main className="flex flex-col flex-grow pb-8">
          {Child.Header && (
            <div className="sticky z-30 flex-shrink-0 bg-white shadow -top-1">
              <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-7xl lg:px-8">
                <div className="py-6 sm:flex sm:items-center sm:justify-between lg:border-t lg:border-gray-200">
                  <Child.Header />
                </div>
              </div>
            </div>
          )}
          <div
            className={classNames(
              !childConfig.fullwidth && 'max-w-7xl mx-auto',
              'flex-grow w-full p-4 sm:p-6 lg:p-8'
            )}
          >
            <Child />
          </div>
        </main>
      </div>
    </div>
  )
}
