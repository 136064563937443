import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  GetReportQuery,
  GetReportQueryVariables,
} from '../GraphQL/graphql'
import { GetReportDocument, GetReportUpdatesDocument } from '../GraphQL/graphql'

export const useReport = (reportId?: string) => {
  const [getReport, { data, loading, subscribeToMore }] = useLazyQuery<
    GetReportQuery,
    GetReportQueryVariables
  >(GetReportDocument)

  useEffect(() => {
    reportId && getReport({ variables: { reportId } })
  }, [getReport, reportId])

  useEffect(() => {
    if (!reportId) {
      return
    }
    const unsubscribe = subscribeToMore({
      document: GetReportUpdatesDocument,
      variables: { reportId },
      updateQuery: (_, options) => {
        return options.subscriptionData.data
      },
    })
    return () => unsubscribe()
  }, [reportId, subscribeToMore])

  return useMemo(
    () => ({
      report:
        reportId && data?.getReport?.report
          ? data?.getReport?.report
          : undefined,
      reportLoading: loading,
    }),
    [data, loading, reportId]
  )
}
