import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  GetImageOperandsQuery,
  GetImageOperandsQueryVariables,
  ImageOperandFragment,
} from '../GraphQL/graphql'
import {
  GetImageOperandsDocument,
  GetImageOperandsUpdatesDocument,
} from '../GraphQL/graphql'

export type UseSeriesOperandsResponse = {
  seriesOperands: ImageOperandFragment[]
  seriesOperandsLoading: boolean
}

export const useSeriesOperands = (
  seriesId?: string
): UseSeriesOperandsResponse => {
  const [getImageOperands, { data, loading, subscribeToMore }] = useLazyQuery<
    GetImageOperandsQuery,
    GetImageOperandsQueryVariables
  >(GetImageOperandsDocument)

  useEffect(() => {
    seriesId && getImageOperands({ variables: { seriesId } })
  }, [getImageOperands, seriesId])

  useEffect(() => {
    if (!seriesId) return
    const unsubscribe = subscribeToMore({
      document: GetImageOperandsUpdatesDocument,
      variables: { seriesId },
      updateQuery: (_, { subscriptionData }) => subscriptionData.data,
    })
    return () => unsubscribe()
  }, [seriesId, subscribeToMore])

  return useMemo(
    () => ({
      seriesOperands:
        seriesId && data?.getImageOperands?.operands
          ? data?.getImageOperands?.operands
          : [],
      seriesOperandsLoading: loading,
    }),
    [data?.getImageOperands?.operands, loading, seriesId]
  )
}
