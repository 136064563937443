import { WifiIcon } from '@heroicons/react/24/outline'
import type { AppShellComponent } from './AppShell'

export const Loading: AppShellComponent = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full gap-8 text-2xl font-semibold text-gray-900/50 animate-pulse">
      <WifiIcon className="max-w-[50vmin] max-h-[50vmin] w-52 h-w-52" />
      Loading
    </div>
  )
}
