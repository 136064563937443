import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  ActivateConversationTreeDataMutation,
  ActivateConversationTreeDataMutationVariables,
  DeleteConversationTreeCriterionMutation,
  DeleteConversationTreeCriterionMutationVariables,
  DeleteConversationTreeDataMutation,
  DeleteConversationTreeDataMutationVariables,
  DeleteConversationTreeElementMutation,
  DeleteConversationTreeElementMutationVariables,
  DuplicateConversationTreeDataMutation,
  DuplicateConversationTreeDataMutationVariables,
  GetConversationTreeDataQuery,
  GetConversationTreeDataQueryVariables,
  SetConversationTreeDataSettingsMutation,
  SetConversationTreeDataSettingsMutationVariables,
  UpsertConversationTreeActionMutation,
  UpsertConversationTreeActionMutationVariables,
  UpsertConversationTreeCheckMutation,
  UpsertConversationTreeCheckMutationVariables,
  UpsertConversationTreeCriterionMutation,
  UpsertConversationTreeCriterionMutationVariables,
  UpsertConversationTreeDataMutation,
  UpsertConversationTreeDataMutationVariables,
  UpsertConversationTreeStatementMutation,
  UpsertConversationTreeStatementMutationVariables,
} from '../GraphQL/graphql'
import {
  ActivateConversationTreeDataDocument,
  DeleteConversationTreeCriterionDocument,
  DeleteConversationTreeDataDocument,
  DeleteConversationTreeElementDocument,
  DuplicateConversationTreeDataDocument,
  GetConversationTreeDataDocument,
  GetConversationTreeDataUpdatesDocument,
  SetConversationTreeDataSettingsDocument,
  UpsertConversationTreeActionDocument,
  UpsertConversationTreeCheckDocument,
  UpsertConversationTreeCriterionDocument,
  UpsertConversationTreeDataDocument,
  UpsertConversationTreeStatementDocument,
} from '../GraphQL/graphql'

export const useConversationTreeData = (treeDataId?: string) => {
  const [getConversationTreeData, { data, error, loading, subscribeToMore }] =
    useLazyQuery<
      GetConversationTreeDataQuery,
      GetConversationTreeDataQueryVariables
    >(GetConversationTreeDataDocument)

  useEffect(() => {
    treeDataId &&
      getConversationTreeData({
        variables: {
          treeDataId,
        },
      })
  }, [getConversationTreeData, treeDataId])

  useEffect(() => {
    if (!treeDataId) return
    const unsubscribe = subscribeToMore({
      document: GetConversationTreeDataUpdatesDocument,
      variables: {
        treeDataId,
      } satisfies GetConversationTreeDataQueryVariables,
      updateQuery: (_, { subscriptionData }) => subscriptionData.data,
    })
    return () => unsubscribe()
  }, [subscribeToMore, treeDataId])

  const upsertConversationTreeData = useMutation<
    UpsertConversationTreeDataMutation,
    UpsertConversationTreeDataMutationVariables
  >(UpsertConversationTreeDataDocument)

  const duplicateConversationTreeData = useMutation<
    DuplicateConversationTreeDataMutation,
    DuplicateConversationTreeDataMutationVariables
  >(DuplicateConversationTreeDataDocument)

  const deleteConversationTreeData = useMutation<
    DeleteConversationTreeDataMutation,
    DeleteConversationTreeDataMutationVariables
  >(DeleteConversationTreeDataDocument)

  const activateConversationTreeData = useMutation<
    ActivateConversationTreeDataMutation,
    ActivateConversationTreeDataMutationVariables
  >(ActivateConversationTreeDataDocument)

  const deleteConversationTreeCriterion = useMutation<
    DeleteConversationTreeCriterionMutation,
    DeleteConversationTreeCriterionMutationVariables
  >(DeleteConversationTreeCriterionDocument)

  const deleteConversationTreeElement = useMutation<
    DeleteConversationTreeElementMutation,
    DeleteConversationTreeElementMutationVariables
  >(DeleteConversationTreeElementDocument)

  const setConversationTreeDataSettings = useMutation<
    SetConversationTreeDataSettingsMutation,
    SetConversationTreeDataSettingsMutationVariables
  >(SetConversationTreeDataSettingsDocument)

  const upsertConversationTreeAction = useMutation<
    UpsertConversationTreeActionMutation,
    UpsertConversationTreeActionMutationVariables
  >(UpsertConversationTreeActionDocument)

  const upsertConversationTreeCheck = useMutation<
    UpsertConversationTreeCheckMutation,
    UpsertConversationTreeCheckMutationVariables
  >(UpsertConversationTreeCheckDocument)

  const upsertConversationTreeCriterion = useMutation<
    UpsertConversationTreeCriterionMutation,
    UpsertConversationTreeCriterionMutationVariables
  >(UpsertConversationTreeCriterionDocument)

  const upsertConversationTreeStatement = useMutation<
    UpsertConversationTreeStatementMutation,
    UpsertConversationTreeStatementMutationVariables
  >(UpsertConversationTreeStatementDocument)

  const mutations = useMemo(
    () => ({
      upsertConversationTreeData,
      duplicateConversationTreeData,
      activateConversationTreeData,
      deleteConversationTreeData,
      deleteConversationTreeCriterion,
      deleteConversationTreeElement,
      setConversationTreeDataSettings,
      upsertConversationTreeAction,
      upsertConversationTreeCheck,
      upsertConversationTreeCriterion,
      upsertConversationTreeStatement,
    }),
    [
      upsertConversationTreeData,
      duplicateConversationTreeData,
      activateConversationTreeData,
      deleteConversationTreeData,
      deleteConversationTreeCriterion,
      deleteConversationTreeElement,
      setConversationTreeDataSettings,
      upsertConversationTreeAction,
      upsertConversationTreeCheck,
      upsertConversationTreeCriterion,
      upsertConversationTreeStatement,
    ]
  )

  const queries = useMemo(
    () => ({
      getConversationTreeData,
    }),
    [getConversationTreeData]
  )

  return useMemo(
    () => ({
      treeData:
        treeDataId && data?.getConversationTreeData.treeData
          ? data?.getConversationTreeData.treeData
          : undefined,
      validation:
        treeDataId && data?.getConversationTreeData.validation
          ? data?.getConversationTreeData.validation
          : undefined,
      treeDataLoading: loading,
      treeDataError: error ?? data?.getConversationTreeData.error,
      queries,
      mutations,
    }),
    [data, error, loading, mutations, queries, treeDataId]
  )
}
