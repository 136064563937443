import classNames from 'classnames'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useConversationTree } from '../Hooks/useConversationTree'

interface ConversationTreeSettingsProps {
  className?: string
  treeId: string
}

export const ConversationTreeSettings: FC<ConversationTreeSettingsProps> = ({
  className,
  treeId,
}) => {
  const { t } = useTranslation()
  const {
    tree,
    mutations: { upsertConversationTree },
  } = useConversationTree(treeId)
  const [videoPath, setVideoPath] = useState('')
  useEffect(() => {
    if (tree) {
      setVideoPath(tree.videoPath)
    }
  }, [tree])
  const [mutate, { loading }] = upsertConversationTree

  const handleSave = () => {
    if (tree) {
      mutate({
        variables: {
          id: tree.id,
          videoPath,
          identifier: tree.identifier,
          illnessId: tree.illness.id,
        },
      })
    }
  }

  return (
    <div
      className={classNames(
        'min-w-full overflow-hidden overflow-x-auto divide-y divide-gray-200 rounded-lg shadow',
        className
      )}
    >
      <div className="flex items-center justify-between p-4 gap-x-4 sm:gap-x-6 sm:p-6 text-primary-100 bg-primary-700">
        <div className="font-medium text-left">{t('Settings')}</div>
      </div>

      <div className="p-4 bg-white">
        <div>
          <label htmlFor="email-address" className="sr-only">
            {t('Video path')}
          </label>
          <div className="flex">
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="relative block basis-auto w-full rounded-s-md border-0 py-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 disabled:bg-gray-200"
              placeholder={t('Video path')}
              value={videoPath}
              onChange={(e) => setVideoPath(e.target.value)}
              disabled={loading}
            />
            <button
              type="submit"
              onClick={handleSave}
              disabled={loading}
              className="relative basis-36 justify-center px-3 py-2 text-sm font-semibold text-white rounded-e-md bg-primary-600 group hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              {t('Save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
