import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import type { Dispatch, FC, SetStateAction } from 'react'
import { Fragment } from 'react'
import type { NavigationItemType } from '../Components/NavigationItem'
import {
  NavigationItem,
  NavigationItemStyle,
} from '../Components/NavigationItem'

type Props = {
  navigation: NavigationItemType[]
  secondaryNavigation: NavigationItemType[]
  sidebarOpen: boolean
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
}

const sidebarTransition = 'transition ease-in-out duration-300 transform'

export const NavigationMobile: FC<Props> = ({
  navigation,
  secondaryNavigation,
  sidebarOpen,
  setSidebarOpen,
}) => {
  return (
    <Transition.Root show={sidebarOpen} as={'div'}>
      <Dialog className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter={sidebarTransition}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave={sidebarTransition}
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex">
          <Transition.Child
            as={Fragment}
            enter={sidebarTransition}
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave={sidebarTransition}
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs pt-2 pb-4 bg-primary-700">
              <div className="flex items-center flex-shrink-0 px-2">
                <button
                  type="button"
                  className="flex items-center justify-center w-10 h-10 rounded-full focus:outline-none focus:border focus:border-inset focus:border-primary-200"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    className="w-6 h-6 text-primary-200"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <nav
                className="flex-shrink-0 h-full px-2 mt-5 overflow-y-auto divide-y-2 divide-primary-100/20"
                aria-label="Sidebar"
              >
                <div className="space-y-1">
                  {navigation.map((item) => (
                    <NavigationItem
                      key={item.name}
                      {...item}
                      style={NavigationItemStyle.Primary}
                    />
                  ))}
                </div>
                <div className="pt-6 mt-6">
                  <div className="space-y-1">
                    {secondaryNavigation.map((item) => (
                      <NavigationItem
                        key={item.name}
                        {...item}
                        style={NavigationItemStyle.Secondary}
                      />
                    ))}
                  </div>
                </div>
              </nav>
            </Dialog.Panel>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink */}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
