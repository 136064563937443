import { ChevronRightIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import _ from 'lodash'
import type { ComponentType, FC } from 'react'
import { useMemo } from 'react'
import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import type { ComboboxOption, ComboboxOptionHandler } from './Combobox'
import { ValueSelectable } from './ValueSelectable'

export enum CardStyle {
  NEUTRAL,
  SUCCESS,
  INFO,
  DANGER,
}

export type CardProps = {
  name: string
  link?: LinkProps['to']
  linkText?: string
  Icon?: ComponentType<{ className?: string }>
  info?: string | number
  infoOptions?: ComboboxOption[]
  infoPlaceholder?: string
  style?: CardStyle
  onInfoChange?: ComboboxOptionHandler
}

export const Card: FC<CardProps> = ({
  name,
  link,
  linkText,
  Icon,
  info,
  infoOptions,
  infoPlaceholder,
  style = CardStyle.NEUTRAL,
  onInfoChange = _.noop,
}) => {
  const selectedInfoOption = useMemo(
    () => infoOptions?.find((option) => option.value === info),
    [info, infoOptions]
  )

  return (
    <div
      className={classNames('rounded-lg shadow flex flex-col', {
        'bg-white': style === CardStyle.NEUTRAL,
        'bg-green-100': style === CardStyle.SUCCESS,
        'bg-orange-100': style === CardStyle.INFO,
        'bg-red-100': style === CardStyle.DANGER,
      })}
    >
      <div className="relative flex-1 p-5">
        {Icon && (
          <div className="absolute top-0 bottom-0 right-0 overflow-hidden">
            <Icon
              className="relative w-28 h-28 -right-4 -top-5 text-gray-900/5"
              aria-hidden="true"
            />
          </div>
        )}
        <div className="flex items-center">
          <div className="flex-1 w-0">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">
                {name}
              </dt>
              <dd className="text-lg font-medium">
                {infoOptions ? (
                  <ValueSelectable
                    value={selectedInfoOption}
                    options={infoOptions ?? []}
                    placeholder={infoPlaceholder}
                    onEditEnd={onInfoChange}
                  />
                ) : (
                  info
                )}
              </dd>
            </dl>
          </div>
        </div>
      </div>
      {link && (
        <Link
          to={link}
          className="block px-5 py-3 text-sm font-medium rounded-b-lg text-gray-900/50 bg-gray-900/10 hover:bg-gray-900/20 hover:text-gray-900/70"
        >
          {linkText || <ChevronRightIcon className="w-5 h-5" />}
        </Link>
      )}
    </div>
  )
}
