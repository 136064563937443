import { useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  ConversationTreeError,
  ConversationTreeFragment,
  GetConversationTreesQuery,
  GetConversationTreesQueryVariables,
} from '../GraphQL/graphql'
import {
  GetConversationTreesDocument,
  GetConversationTreesUpdatesDocument,
} from '../GraphQL/graphql'

export type UseConversationTreesResponse = {
  trees: ConversationTreeFragment[]
  treesLoading: boolean
  treesError?: ConversationTreeError
}

export const useConversationTrees = (): UseConversationTreesResponse => {
  const { data, loading, subscribeToMore } = useQuery<
    GetConversationTreesQuery,
    GetConversationTreesQueryVariables
  >(GetConversationTreesDocument, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: GetConversationTreesUpdatesDocument,
      updateQuery: (_, options) => options.subscriptionData.data,
    })
    return () => unsubscribe()
  }, [subscribeToMore])

  return useMemo(
    () => ({
      trees: data?.getConversationTrees.trees ?? [],
      treesLoading: loading,
      treesError: data?.getConversationTrees.error ?? undefined,
    }),
    [data, loading]
  )
}
