import { ChatBubbleLeftRightIcon, PhotoIcon } from '@heroicons/react/20/solid'
import { AcademicCapIcon, BanknotesIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from '../Components/Button'
import type { CardProps } from '../Components/Card'
import { CardStyle } from '../Components/Card'
import { CardGrid } from '../Components/CardGrid'
import { Table } from '../Components/Table'
import { InvoiceState, ReportType } from '../GraphQL/graphql'
import { datetimeToString } from '../Helper/TextHelper'
import { useProfile } from '../Hooks/useProfile'
import { useReports } from '../Hooks/useReports'
import type { AppShellComponent } from './AppShell'
import { Loading } from './Loading'
import { LoadingError } from './LoadingError'

export const Reports: AppShellComponent = () => {
  const navigate = useNavigate()
  const { reports, loading, error } = useReports({
    invoiceState: [
      InvoiceState.Created,
      InvoiceState.Paid,
      InvoiceState.PartiallyPaid,
    ],
  })
  const { profile, profileLoading } = useProfile()

  const openReports = useMemo(
    () => reports.filter(({ releaseTimestamp }) => !releaseTimestamp),
    [reports]
  )

  const cards = useMemo((): CardProps[] => {
    const cards: CardProps[] = []
    cards.push({
      name: 'Open Reports',
      Icon: AcademicCapIcon,
      info: openReports.length,
      style: openReports.length ? CardStyle.DANGER : CardStyle.SUCCESS,
    })
    cards.push({
      name: 'All Reports',
      Icon: BanknotesIcon,
      info: reports.length,
      style: CardStyle.NEUTRAL,
    })
    return cards
  }, [reports, openReports])

  if (loading || profileLoading) {
    return <Loading />
  }

  if (error) {
    return <LoadingError />
  }

  return (
    <div className="space-y-8">
      {/* <SelectMenu items={months} />
      <SelectMenu items={years} /> */}
      <CardGrid cards={cards} />
      <Table className="relative">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="text-left"></Table.HeaderCell>
            <Table.HeaderCell className="text-left">
              Request Date
            </Table.HeaderCell>
            <Table.HeaderCell className="text-left">Patient</Table.HeaderCell>
            <Table.HeaderCell className="text-left">Doctor</Table.HeaderCell>
            <Table.HeaderCell className="text-left">Illness</Table.HeaderCell>
            <Table.HeaderCell className="text-left">
              Delivery Date
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {reports.map((report) => (
            <Table.Row
              key={report.id}
              onClick={() => {
                report.reviewer?.id === profile?.id && !report.released
                  ? navigate(`/reporteditor/${report.id}`)
                  : navigate(`/reports/${report.id}`)
              }}
              className={classNames(
                'cursor-pointer group hover:bg-gray-50',
                !report.released && 'bg-red-100 hover:bg-red-200'
              )}
            >
              <Table.Cell className="text-gray-500">
                <div className="w-5">
                  {report.reportType === ReportType.EvaluationReport ? (
                    <PhotoIcon />
                  ) : (
                    <ChatBubbleLeftRightIcon />
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className="text-gray-500">
                {datetimeToString(report.requestTimestamp)}
              </Table.Cell>
              <Table.Cell className="text-gray-500">
                {report.user.lastname}, {report.user.firstname}
              </Table.Cell>
              <Table.Cell className="text-gray-500">
                {report.reviewer?.lastname}, {report.reviewer?.firstname}
              </Table.Cell>
              <Table.Cell className="text-gray-500">
                {report.illness.name}
              </Table.Cell>
              <Table.Cell className="text-gray-500">
                {datetimeToString(report.releaseTimestamp)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

Reports.Header = (() => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
          {t('Reports')}
        </h1>
      </div>
      <div className="flex mt-6 space-x-3 sm:ml-4 sm:mt-0">
        <Button design="primary" onClick={() => navigate('/reports/voided')}>
          Cancelled reports
        </Button>
      </div>
    </>
  )
}) satisfies FC
