import classNames from 'classnames'
import type { FC, PropsWithChildren } from 'react'
import React, { Children } from 'react'

type Props = PropsWithChildren & {
  title: string
  subtitle?: string
  noPaddings?: boolean
}

export const DetailViewCard: FC<Props> = ({
  title,
  subtitle,
  children,
  noPaddings,
}) => {
  return (
    <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
      <div className="px-4 sm:px-0">
        <h2 className="text-base font-semibold leading-7">{title}</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">{subtitle}</p>
      </div>

      <div className="space-y-6 md:col-span-2">
        {Children.toArray(children)
          .filter((child) => !!child)
          .map((child) => (
            <div
              className={classNames(
                'overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl',
                !noPaddings && 'px-4 py-6 sm:p-8'
              )}
            >
              {child}
            </div>
          ))}
      </div>
    </div>
  )
}
