import type { Types } from '@cornerstonejs/core'
import { Enums } from '@cornerstonejs/core'
import { ToolGroupManager } from '@cornerstonejs/tools'
import classNames from 'classnames'
import _ from 'lodash'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import type {
  DicomSeriesFragment,
  ImageAnnotationObject,
} from '../GraphQL/graphql'
import {
  addAllTools,
  applyMeasurements,
  imageUriToInstanceId,
  initializeCornerstone,
  setTool,
  ToolSet,
} from '../Helper/CornerstoneHelper'

interface Props {
  dicomSeries: DicomSeriesFragment
  measurements?: ImageAnnotationObject[]
  tool?: ToolSet
  onInstanceChange?: (instanceId: string | undefined) => void
}

const CORNERSTONE_TOOL_GROUP_ID = 'DicomViewer-ToolGroup'
const CORNERSTONE_VIEWPORT_ID = 'DicomViewer-Viewport'

const renderingEngine = initializeCornerstone()

const toolGroup = ToolGroupManager.createToolGroup(CORNERSTONE_TOOL_GROUP_ID)

addAllTools(toolGroup)

export const DicomViewer: FC<Props> = ({
  dicomSeries,
  measurements,
  tool = ToolSet.PanZoom,
  onInstanceChange = _.noop,
}) => {
  const viewportRef = useRef<HTMLDivElement>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [engineLoading, setEngineLoadeding] = useState(true)

  useEffect(() => {
    setEngineLoadeding(true)
    renderingEngine.then((engine) => {
      if (!viewportRef.current) return
      engine.enableElement({
        viewportId: CORNERSTONE_VIEWPORT_ID,
        type: Enums.ViewportType.STACK,
        element: viewportRef.current,
        defaultOptions: {
          orientation: Enums.OrientationAxis.AXIAL,
        },
      })
      toolGroup?.addViewport(CORNERSTONE_VIEWPORT_ID)
      viewportRef.current.oncontextmenu = (e) => e.preventDefault()
      setEngineLoadeding(false)
    })

    return () => {
      renderingEngine.then((engine) =>
        engine.disableElement(CORNERSTONE_VIEWPORT_ID)
      )
    }
  }, [viewportRef])

  // useEffect(() => {
  //   annotation.state.removeAllAnnotations()
  // }, [dicomSeries])

  useEffect(() => {
    if (!dicomSeries.instances.length || engineLoading) return
    setIsLoading(true)

    const imageIds = dicomSeries.instances.map(
      ({ id }) =>
        `wadouri:${process.env.REACT_APP_DICOM_ENDPOINT}/instances/${id}/file`
    )

    renderingEngine.then((engine) => {
      const viewport = engine.getViewport(
        CORNERSTONE_VIEWPORT_ID
      ) as Types.IStackViewport
      viewport.setStack(imageIds).then(() => setIsLoading(false))
    })
  }, [dicomSeries, engineLoading])

  useEffect(() => {
    if (engineLoading) return
    renderingEngine.then((engine) => {
      if (!measurements?.length || !viewportRef.current) return
      const viewport = engine.getViewport(
        CORNERSTONE_VIEWPORT_ID
      ) as Types.IStackViewport
      const imageURI = viewport.getCurrentImageId()
      applyMeasurements(imageURI, measurements, viewportRef.current, viewport)
      viewport.render()
    })
  }, [measurements, engineLoading])

  useEffect(() => {
    setTool(tool, toolGroup)
  }, [tool])

  const isStackNewImageEvent = (
    event: Event
  ): event is Types.EventTypes.StackNewImageEvent =>
    event &&
    typeof event === 'object' &&
    'detail' in event &&
    !!event.detail &&
    typeof event.detail === 'object' &&
    'imageId' in event.detail

  useEffect(() => {
    const element = viewportRef.current
    const newImageHandler: EventListener = (evt) => {
      if (isStackNewImageEvent(evt)) {
        onInstanceChange(imageUriToInstanceId(evt.detail.imageId))
      }
    }
    element?.addEventListener(Enums.Events.STACK_NEW_IMAGE, newImageHandler)

    return () => {
      element?.removeEventListener(
        Enums.Events.STACK_NEW_IMAGE,
        newImageHandler
      )
    }
  }, [onInstanceChange])

  return (
    <>
      <div
        ref={viewportRef}
        className={classNames('w-full h-full', isLoading && 'hidden')}
      />
      <div
        className={classNames(
          'w-full h-full bg-black animate-pulse',
          isLoading && 'block'
        )}
      />
    </>
  )
}
