import classNames from 'classnames'
import type { ButtonHTMLAttributes, FC, ReactNode } from 'react'

export type ButtonDesign = 'primary' | 'secondary' | 'white'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  design?: ButtonDesign
  children?: ReactNode
}

export const designClasses: Record<ButtonDesign, string> = {
  primary:
    'text-white bg-primary-600 hover:bg-primary-500 focus-visible:outline-primary-600 ',
  secondary:
    'text-white bg-secondary-600 hover:bg-secondary-500 focus-visible:outline-secondary-600 disabled:bg-gray-400',
  white:
    'text-gray-900 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
}

export const Button: FC<Props> = ({
  children,
  design = 'primary',
  className,
  ...args
}) => {
  return (
    <button
      className={classNames(
        className,
        designClasses[design],
        'disabled:grayscale',
        design === 'white' && 'disabled:bg-gray-200 disabled:text-gray-500',
        'inline-flex items-center px-3 py-2 text-sm font-semibold rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
      )}
      {...args}
    >
      {children}
    </button>
  )
}
