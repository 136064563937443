import { useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  GetCurrentUserChangesSubscription,
  GetCurrentUserChangesSubscriptionVariables,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
} from '../GraphQL/graphql'
import {
  GetCurrentUserChangesDocument,
  GetCurrentUserDocument,
} from '../GraphQL/graphql'

export const useProfile = () => {
  const { data, loading, subscribeToMore } = useQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >(GetCurrentUserDocument)

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      GetCurrentUserChangesSubscription,
      GetCurrentUserChangesSubscriptionVariables
    >({
      document: GetCurrentUserChangesDocument,
      updateQuery: (prev, { subscriptionData: { data } }) => ({
        ...prev,
        ...data,
        __typename: prev.__typename,
      }),
    })
    return () => unsubscribe()
  }, [subscribeToMore])

  return useMemo(
    () => ({
      profile: data?.getCurrentUser ?? undefined,
      profileLoading: loading,
    }),
    [data, loading]
  )
}
