import { Switch } from '@headlessui/react'
import type { FC } from 'react'
import { Fragment, useCallback } from 'react'
import type { UserObject } from '../GraphQL/graphql'
import { useUser } from '../Hooks/useUser'

type Props = {
  user: Pick<UserObject, 'id' | 'isActivated'>
}

export const UserStateActivated: FC<Props> = ({ user }) => {
  const {
    mutations: {
      updateUserActiveState: [updateUserActiveState],
    },
  } = useUser()

  const onChange = useCallback(
    async (enabled: boolean) => {
      await updateUserActiveState({
        variables: {
          userId: user.id,
          isActivated: enabled,
        },
      })
    },
    [updateUserActiveState, user.id]
  )

  return (
    <div className="flex items-center space-x-3">
      <Switch checked={user.isActivated} onChange={onChange} as={Fragment}>
        {({ checked }) => (
          <button
            className={`${
              checked ? 'bg-primary-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">toggle user's "activated" state</span>
            <span
              className={`${
                checked ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </button>
        )}
      </Switch>
    </div>
  )
}
