import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type {
  ComboboxOption,
  ComboboxOptionHandler,
} from '../Components/Combobox'
import { Combobox } from '../Components/Combobox'
import type {
  ConversationTreeDataFragment,
  ConversationTreeFragment,
  Maybe,
  TreeNextObject,
} from '../GraphQL/graphql'
import {
  ConversationTreeStatementInputType,
  NextObjectType,
} from '../GraphQL/graphql'
import type { ElementTypes } from '../Helper/ConversationTreeElementHelper'
import {
  isTreeActionObject,
  isTreeCheckObject,
  isTreeStatementObject,
} from '../Helper/ConversationTreeElementHelper'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'
import { useConversationTrees } from '../Hooks/useConversationTrees'
import type { KeysOfType } from '../Utils/TypeUtils'

type NextValueType = Maybe<TreeNextObject> | undefined

type Props<T extends ElementTypes> = {
  treeData: ConversationTreeDataFragment
  element: T
  nextKey: NonNullable<KeysOfType<T, NextValueType>>
  statementType?: ConversationTreeStatementInputType
  label?: string
}

export const ConversationTreeElementNext = <T extends ElementTypes>({
  treeData,
  element: remoteElement,
  nextKey,
  statementType = ConversationTreeStatementInputType.None,
  label = 'Next Element',
}: Props<T>) => {
  const [element, setElement] = useState(remoteElement)
  const [nextId, setNextId] = useState(
    (remoteElement[nextKey] as NextValueType)?.nextId ?? ''
  )
  const [nextType, setNextType] = useState(
    (remoteElement[nextKey] as NextValueType)?.type ?? null
  )
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setElement(remoteElement)
  }, [remoteElement])

  const {
    mutations: {
      upsertConversationTreeAction: [upsertConversationTreeAction],
      upsertConversationTreeCheck: [upsertConversationTreeCheck],
      upsertConversationTreeStatement: [upsertConversationTreeStatement],
    },
  } = useConversationTreeData()

  const { trees } = useConversationTrees()

  const getIdsByType = useCallback(
    (
      type: NextObjectType
    ): Pick<ConversationTreeFragment, 'id' | 'identifier'>[] => {
      switch (type) {
        case NextObjectType.Action:
          return treeData.actions
        case NextObjectType.Check:
          return treeData.checks
        case NextObjectType.Statement:
          return treeData.statements
        case NextObjectType.Tree:
          return trees
      }
      return []
    },
    [treeData, trees]
  )

  const nextTypeOptions: ComboboxOption[] = useMemo(
    () =>
      Object.entries(NextObjectType).map(([value, key]) => ({
        key,
        value,
      })),
    []
  )

  const nextTypeOption: ComboboxOption | undefined = useMemo(
    () => nextTypeOptions.find(({ key }) => key === nextType),
    [nextType, nextTypeOptions]
  )

  const nextIdOptions: ComboboxOption[] = useMemo(
    () =>
      nextType
        ? _.sortBy(getIdsByType(nextType), ({ identifier }) =>
            identifier.toLowerCase()
          ).map(({ id, identifier }) => ({
            value: identifier,
            key: id,
          }))
        : [],
    [nextType, getIdsByType]
  )

  const nextIdOption: ComboboxOption | undefined = useMemo(
    () => nextIdOptions.find(({ key }) => key === nextId),
    [nextId, nextIdOptions]
  )

  const onNextChange: ComboboxOptionHandler = useCallback(
    (value) => {
      if (!nextType) return

      const intermediateElement = _.cloneDeep(element)

      if (value) {
        ;(intermediateElement[nextKey] as NextValueType) = {
          nextId: value.key,
          type: nextType,
        }

        setIsEditing(false)
        setNextId(value.key)
      } else {
        ;(intermediateElement[nextKey] as NextValueType) = null
        setNextId('')
      }

      setElement(intermediateElement)

      if (isTreeCheckObject(intermediateElement)) {
        upsertConversationTreeCheck({
          variables: { input: intermediateElement, treeDataId: treeData.id },
        })
      } else if (isTreeStatementObject(intermediateElement)) {
        intermediateElement.inputType = statementType

        upsertConversationTreeStatement({
          variables: { input: intermediateElement, treeDataId: treeData.id },
        })
      } else if (isTreeActionObject(intermediateElement)) {
        upsertConversationTreeAction({
          variables: { input: intermediateElement, treeDataId: treeData.id },
        })
      }
    },
    [
      nextType,
      element,
      nextKey,
      upsertConversationTreeCheck,
      treeData.id,
      statementType,
      upsertConversationTreeStatement,
      upsertConversationTreeAction,
    ]
  )

  return (
    <div className="bg-white divide-y divide-gray-100 rounded-md shadow">
      <div className="px-4 py-3 font-semibold text-primary-700">{label}</div>
      <div className="px-4 py-3 space-y-2">
        <Combobox
          inputClassName="text-sm text-gray-500"
          options={nextTypeOptions}
          value={nextTypeOption}
          onChange={(option) =>
            option && setNextType(option.key as NextObjectType)
          }
          placeholder="No Next Type"
          isDisabled={!isEditing}
          onDoubleClick={() => setIsEditing(true)}
        />
        <Combobox
          inputClassName="text-sm"
          options={nextIdOptions}
          value={nextIdOption}
          onChange={onNextChange}
          onBlur={() => setIsEditing(false)}
          placeholder="No Next"
          isDisabled={!isEditing}
          onDoubleClick={() => setIsEditing(true)}
        />
      </div>
    </div>
  )
}
