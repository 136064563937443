import classNames from 'classnames'
import type { FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import type { TreeReportTemplatesObject } from '../GraphQL/graphql'
import { ConversationTreeReport } from './ConversationTreeReport'

type Props = HTMLAttributes<HTMLDivElement> & {
  treeDataId: string
  reportTemplates: TreeReportTemplatesObject
  activeId: string
}

export const ConversationTreeReports: FC<Props> = ({
  treeDataId,
  reportTemplates,
  activeId,
  className,
  ...args
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={classNames(
        'min-w-full overflow-hidden overflow-x-auto divide-y divide-gray-200 rounded-lg shadow',
        className
      )}
      {...args}
    >
      <div className="flex items-center justify-between p-4 gap-x-4 sm:gap-x-6 sm:p-6 text-primary-100 bg-primary-700">
        <div className="font-medium text-left">Report</div>
      </div>

      <ConversationTreeReport
        reportName={t('Anamnesis Report')}
        reportTemplate={reportTemplates.anamnesisReport}
        activeId={activeId}
        treeDataId={treeDataId}
      />

      <ConversationTreeReport
        reportName={t('Medical Report')}
        reportTemplate={reportTemplates.medicalReport}
        activeId={activeId}
        treeDataId={treeDataId}
      />
    </div>
  )
}
