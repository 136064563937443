import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import type {
  GetOrdersQuery,
  GetOrdersQueryVariables,
} from '../GraphQL/graphql'
import { GetOrdersDocument } from '../GraphQL/graphql'

type OrderParams = {
  orderId?: string
}

export const useParamOrder = () => {
  const { orderId = -1 } = useParams<OrderParams>()

  const { data, loading } = useQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GetOrdersDocument
  )

  const orders = useMemo(() => data?.getOrders.orders, [data])

  const order = useMemo(
    () => orders?.find(({ id }) => id === orderId),
    [orderId, orders]
  )

  return useMemo(() => ({ order, orderLoading: loading }), [loading, order])
}
