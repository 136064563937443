import Cookies from 'js-cookie'

export const LANGUAGE_COOKIE_NAME = 'dro_editor_lang'
export const DEFAULT_LANGUAGE_CODE = 'en'

export const setLanguageCode = (code: string): void => {
  if (code === '') {
    Cookies.remove(LANGUAGE_COOKIE_NAME)
  } else {
    Cookies.set(LANGUAGE_COOKIE_NAME, code, {
      expires: 360,
      sameSite: 'Strict',
    })
  }
}

export const getLanguageCode = (): string => {
  try {
    return Cookies.get(LANGUAGE_COOKIE_NAME) || DEFAULT_LANGUAGE_CODE
  } catch {
    return DEFAULT_LANGUAGE_CODE
  }
}
