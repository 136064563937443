import { PlusIcon } from '@heroicons/react/24/outline'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import QRCode from 'react-qr-code'
import { Button } from '../Components/Button'
import { DetailViewCard } from '../Components/DetailViewCard'
import type { UserObject } from '../GraphQL/graphql'
import { useUser } from '../Hooks/useUser'

type Props = {
  user: Pick<UserObject, 'id'>
}

export const UserDetailsLoginLink: FC<Props> = ({ user }) => {
  const [loginLink, setLoginLink] = useState('')

  const {
    mutations: {
      createLoginLink: [createLoginLink],
    },
  } = useUser()

  const onCreateLoginLink = useCallback(async () => {
    const { data } = await createLoginLink({
      variables: {
        userId: user.id,
      },
    })

    if (data?.createLoginLink?.link) {
      setLoginLink(data.createLoginLink.link)
      await navigator.clipboard.writeText(data.createLoginLink.link)
    }
  }, [createLoginLink, user.id])

  return (
    <DetailViewCard
      title="Magic Login Link 🧙🏼‍♂️"
      subtitle="Magic Link to login for the user"
    >
      <div className="space-y-2">
        <label
          htmlFor="magic-link"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Create Magic Link
        </label>
        <div className="flex rounded-md shadow-sm">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <input
              className="block w-full rounded-none rounded-l-md border-0 px-3 py-1.5 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:leading-6 sm:text-sm read-only:bg-gray-50"
              id="magic-link"
              value={loginLink}
              readOnly
              placeholder="the magic link can only be seen once"
            />
          </div>
          <Button
            design="secondary"
            className="px-3 py-2 gap-x-1.5 -ml-px rounded-l-none hover:bg-gray-50"
            onClick={onCreateLoginLink}
          >
            <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Create
          </Button>
        </div>
      </div>
      {loginLink && (
        <QRCode
          size={256}
          className="w-64 max-w-full mx-auto"
          value={loginLink}
        />
      )}

      {/* TODO: Add "deactivate Link" section */}
    </DetailViewCard>
  )
}
