import { Combobox as HeadlessCombobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import type { FC, HTMLAttributes, KeyboardEvent } from 'react'
import { Fragment, useMemo, useState } from 'react'

export type ComboboxOption = {
  value: string
  key: string
}

export type ComboboxOptionHandler = (newValue: ComboboxOption | null) => void

type Props = Omit<
  HTMLAttributes<HTMLDivElement>,
  'defaultValue' | 'onChange' | 'onKeyDown'
> & {
  options?: ComboboxOption[]
  value?: ComboboxOption
  onChange: ComboboxOptionHandler
  isDisabled?: boolean
  placeholder?: string
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  inputClassName?: string
}

export const Combobox: FC<Props> = ({
  options = [],
  value,
  onChange,
  isDisabled,
  placeholder,
  className,
  inputClassName,
  onKeyDown,
  ...args
}) => {
  const [query, setQuery] = useState('')

  const filteredOptions = useMemo(
    () =>
      options.filter(({ value }) =>
        value.toLowerCase().includes(query.toLowerCase())
      ),
    [options, query]
  )

  return (
    <HeadlessCombobox
      as="div"
      value={value}
      onChange={onChange}
      disabled={isDisabled}
      nullable
      className={classNames(className, 'relative flex-auto')}
      {...args}
    >
      {({ open, disabled }) => (
        <>
          <div
            className={classNames(
              'relative w-full overflow-hidden text-left border-b rounded focus:outline-none',
              disabled
                ? 'pointer-events-none border-transparent cursor-text'
                : 'border-secondary-700'
            )}
          >
            <HeadlessCombobox.Input
              className={classNames(
                inputClassName,
                'w-full p-0 pb-px pr-6 bg-transparent border-none focus:ring-0 placeholder:italic'
              )}
              defaultValue={value}
              displayValue={(option) => option?.value ?? ''}
              placeholder={placeholder}
              onChange={(event) => {
                setQuery(event.target.value)
              }}
              autoComplete="off"
              onKeyDown={onKeyDown}
            />
            {!isDisabled && (
              <HeadlessCombobox.Button className="absolute inset-y-0 right-0 flex items-center">
                <ChevronUpDownIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </HeadlessCombobox.Button>
            )}
          </div>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessCombobox.Options
              className={classNames(
                'absolute z-10 w-full mt-px overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                disabled && 'hidden'
              )}
            >
              {filteredOptions.map((option) => (
                <HeadlessCombobox.Option
                  key={option.key}
                  value={option}
                  className="relative py-2 pl-8 pr-3 text-gray-900 cursor-default select-none ui-active:bg-primary-600 ui-active:text-white"
                >
                  <span className="block font-normal truncate ui-selected:font-medium">
                    {option.value}
                  </span>
                  <span className="absolute inset-y-0 left-0 items-center hidden pl-2 text-primary-600 ui-selected:flex ui-active:text-white">
                    <CheckIcon className="w-4 h-4" aria-hidden="true" />
                  </span>
                </HeadlessCombobox.Option>
              ))}
            </HeadlessCombobox.Options>
          </Transition>
        </>
      )}
    </HeadlessCombobox>
  )
}
