import { MinusCircleIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import type { ButtonHTMLAttributes, FC, MouseEventHandler } from 'react'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  onDelete: MouseEventHandler<HTMLButtonElement>
}

const iconClassName = 'inline-block w-6 h-6 text-red-600 hover:text-red-800'

export const DeleteButton: FC<Props> = ({ onDelete, className, ...args }) => {
  return (
    <button
      className={classNames(className, 'group outline-none')}
      onDoubleClick={onDelete}
      {...args}
    >
      <MinusCircleIcon className={iconClassName} aria-hidden="true" />
      <MinusCircleIcon
        className={classNames(
          iconClassName,
          '-ml-6 transition group-focus:animate-ping'
        )}
        aria-hidden="true"
      />
    </button>
  )
}
