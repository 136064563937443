import { authorizationHeader, getAccessToken } from './AuthHelper'

export const fetchBuffer = async (url: string): Promise<ArrayBuffer> => {
  const resp = new Promise((accept) => {
    const req = new XMLHttpRequest()
    req.open('GET', url, true)
    req.responseType = 'arraybuffer'
    req.setRequestHeader(
      'Authorization',
      authorizationHeader(getAccessToken()) ?? ''
    )

    req.onload = function () {
      const resp = req.response
      if (resp) {
        accept(resp)
      }
    }

    req.send(null)
  })

  return resp as Promise<ArrayBuffer>
}

export const fetchFile = async (url: string): Promise<File | null> => {
  const res = await fetchBuffer(url)
  if (res) {
    const imageBlob = new Blob([new Uint8Array(res)])
    const file = new File([imageBlob], 'name')
    return file
  } else {
    return null
  }
}

export const fetchUrl = async (url: string): Promise<string> => {
  return fetchBuffer(url)
    .then((res) => {
      if (res) {
        const imageBlob = new Blob([new Uint8Array(res)])
        const imageObjectURL = URL.createObjectURL(imageBlob)
        return imageObjectURL
      }
      return ''
    })
    .catch(() => {
      return ''
    })
}
