import { ArrowPathIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import _ from 'lodash'
import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'
import type { OnDragEndResponder } from 'react-beautiful-dnd'
import { DragDropContext } from 'react-beautiful-dnd'
import { StrictModeDroppable } from '../Components/StrictModeDroppable'
import type {
  ConversationTreeDataFragment,
  TreeStatementInput,
  TreeStatementObject,
} from '../GraphQL/graphql'
import {
  ConversationTreeStatementInputType,
  LanguageCode,
} from '../GraphQL/graphql'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'
import { ConversationTreeStatementSingleOption } from './ConversationTreeStatementSingleOption'

type Props = {
  treeData: ConversationTreeDataFragment
  statement: TreeStatementObject
}

export const ConversationTreeStatementSingleOptions: FC<Props> = ({
  treeData,
  statement: remoteStatement,
}) => {
  const [statement, setStatement] = useState(remoteStatement)
  const [isNewOptionLoading, setIsNewOptionLoading] = useState(false)

  useEffect(() => {
    setStatement(remoteStatement)
  }, [remoteStatement])

  const {
    mutations: {
      upsertConversationTreeStatement: [upsertConversationTreeStatement],
    },
  } = useConversationTreeData()

  const onDragEndSingle: OnDragEndResponder = useCallback(
    ({ destination, source }) => {
      if (!destination) return

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return
      }

      const intermediateStatement = _.cloneDeep(statement)

      if (!intermediateStatement?.singleOptions?.length) return

      const options = intermediateStatement.singleOptions

      const [removedOption] = options.splice(source.index, 1)
      options.splice(destination.index, 0, removedOption)

      setStatement(intermediateStatement)

      upsertConversationTreeStatement({
        variables: { input: intermediateStatement, treeDataId: treeData.id },
      })
    },
    [statement, treeData, upsertConversationTreeStatement]
  )

  const addNewOption = useCallback(async () => {
    const intermediateStatement: TreeStatementInput = _.cloneDeep(statement)

    if (!intermediateStatement) return

    intermediateStatement.singleOptions =
      intermediateStatement.singleOptions ?? []

    intermediateStatement.inputType = ConversationTreeStatementInputType.Options

    setIsNewOptionLoading(true)

    intermediateStatement.singleOptions.push({
      text: [{ languageCode: LanguageCode.DeDe, text: '' }],
    })

    await upsertConversationTreeStatement({
      variables: { input: intermediateStatement, treeDataId: treeData.id },
    })
    setIsNewOptionLoading(false)
  }, [statement, treeData, upsertConversationTreeStatement])

  return (
    <DragDropContext onDragEnd={onDragEndSingle}>
      <div className="bg-white divide-y divide-gray-100 rounded-md shadow">
        <div className="px-4 py-3 font-semibold text-primary-700">
          Single Options
        </div>
        <StrictModeDroppable droppableId={statement.id}>
          {(provided) => (
            <div
              className="divide-y divide-gray-100"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {statement.singleOptions?.map((option, index) => (
                <ConversationTreeStatementSingleOption
                  key={option.id}
                  index={index}
                  option={option}
                  statement={statement}
                  treeData={treeData}
                />
              )) ?? (
                <div className="px-4 py-3 italic text-gray-500">
                  none available
                </div>
              )}
              {provided.placeholder}
              <button
                className={classNames(
                  'flex w-full items-center px-4 py-3 gap-x-4 hover:bg-gray-900/5 text-gray-500 italic',
                  isNewOptionLoading && 'animate-pulse'
                )}
                onClick={addNewOption}
              >
                {isNewOptionLoading ? (
                  <ArrowPathIcon className="w-6 h-6 animate-spin text-primary-700" />
                ) : (
                  <PlusCircleIcon
                    className="inline w-6 h-6 text-primary-700"
                    title="add empty option"
                    aria-hidden="true"
                  />
                )}
                New option
              </button>
            </div>
          )}
        </StrictModeDroppable>
      </div>
    </DragDropContext>
  )
}
