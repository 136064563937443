// Credits to https://github.com/GiovanniACamacho and https://github.com/Meligy for the TypeScript version
// Original post: https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import type { DroppableProps } from 'react-beautiful-dnd'
import { Droppable } from 'react-beautiful-dnd'

/**
 * This is a fix for `react-beautiful-dnd`'s `Droppable` component as it makes issues with strict mode enabled
 *
 * @see https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
 */
export const StrictModeDroppable: FC<DroppableProps> = ({
  children,
  ...props
}) => {
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))
    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])

  if (!enabled) {
    return null
  }

  return <Droppable {...props}>{children}</Droppable>
}
