import { PlusIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import type { FC, HTMLAttributes } from 'react'
import { useRef } from 'react'
import type { TreeStatementObject } from '../GraphQL/graphql'
import { ConversationTreeStatement } from './ConversationTreeStatement'
import { ConversationTreeStatementCreate } from './ConversationTreeStatementCreate'

type Props = HTMLAttributes<HTMLDivElement> & {
  treeDataId: string
  statements: TreeStatementObject[]
  activeId: string
  videoPath: string
}

export const ConversationTreeStatements: FC<Props> = ({
  treeDataId,
  statements,
  activeId,
  className,
  videoPath,
  ...args
}) => {
  const newStatementIdentifierInput = useRef<HTMLInputElement>(null)

  return (
    <div
      className={classNames(
        'min-w-full overflow-hidden overflow-x-auto divide-y divide-gray-200 rounded-lg shadow',
        className
      )}
      {...args}
    >
      <div className="flex items-center justify-between p-4 gap-x-4 sm:gap-x-6 sm:p-6 text-primary-100 bg-primary-700">
        <div className="font-medium text-left">Statement</div>

        <button
          className="block p-3 -m-3 rounded hover:bg-white/10"
          title="Create new Criterion"
          onClick={() => newStatementIdentifierInput.current?.focus()}
        >
          <PlusIcon className="w-6 h-6 text-white" aria-hidden="true" />
        </button>
      </div>

      {statements.map((statement) => (
        <ConversationTreeStatement
          key={statement.id}
          statement={statement}
          activeId={activeId}
          treeDataId={treeDataId}
          videoPath={videoPath}
        />
      ))}
      <ConversationTreeStatementCreate
        treeDataId={treeDataId}
        ref={newStatementIdentifierInput}
      />
    </div>
  )
}
