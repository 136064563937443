import _ from 'lodash'
import { DateTime } from 'luxon'
import type { CountryCode, Currency } from '../GraphQL/graphql'

export function moneyToString(
  nr?: number | null,
  currency?: Currency | null,
  country?: CountryCode | null
) {
  if (!nr || !currency) return undefined
  return Number(_.ceil(_.divide(nr, 100), 2)).toLocaleString(
    country ? country.toLowerCase() : 'de',
    {
      currency: currency.toUpperCase(),
      style: 'currency',
    }
  )
}

export function dateToString(date?: Date | string, country?: CountryCode) {
  if (!date) return undefined
  return DateTime.fromJSDate(new Date(date))
    .setLocale(country ? country.toLowerCase() : 'de')
    .toLocaleString(DateTime.DATE_SHORT)
}

export function datetimeToString(date?: Date | string, country?: CountryCode) {
  if (!date) return undefined
  return DateTime.fromJSDate(new Date(date))
    .setLocale(country ? country.toLowerCase() : 'de')
    .toLocaleString(DateTime.DATETIME_SHORT)
}
