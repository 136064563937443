import { ChatBubbleLeftRightIcon, PhotoIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { ButtonLink } from '../Components/ButtonLink'
import { ReportType } from '../GraphQL/graphql'
import { useParamReport } from '../Hooks/useParamReport'
import { useReport } from '../Hooks/useReport'
import { useReportsQueue } from '../Hooks/useReportsQueue'
import { CockpitDetails } from '../Modules/CockpitDetails'
import type { AppShellComponent } from './AppShell'

type CockpitParams = {
  reportId?: string
}

export const ReportEditor: AppShellComponent = () => {
  const { reportId } = useParams<CockpitParams>()
  const { i18n } = useTranslation()
  const navigate = useNavigate()

  const { reportsQueue } = useReportsQueue()
  const { report } = useReport(reportId)

  useEffect(() => {
    if (report?.frozen) {
      navigate('release')
    }
  }, [navigate, report])

  return (
    <div className="min-h-full">
      <div className="max-w-full mx-auto lg:grid lg:grid-cols-12 lg:gap-4">
        <div
          className={classNames(
            'xl:col-span-2',
            report?.user ? 'hidden xl:block' : 'lg:col-span-3'
          )}
        >
          <nav
            aria-label="Sidebar"
            className="sticky divide-y divide-gray-300 top-4"
          >
            <div className="space-y-1">
              {reportsQueue.map((queue) => {
                const Icon =
                  queue.reportType === ReportType.EvaluationReport
                    ? PhotoIcon
                    : ChatBubbleLeftRightIcon
                return (
                  <NavLink
                    key={queue.id}
                    to={`/reporteditor/${queue.id}`}
                    className="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-700 rounded-md group-active:bg-gray-200 group-active:text-gray-900 hover:bg-gray-50 group"
                  >
                    <Icon
                      className="flex-shrink-0 w-6 h-6 mr-4 -ml-1 text-gray-400 group-active:text-gray-500 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <div className="overflow-hidden">
                      <div className="text-xs text-gray-500 truncate">
                        {DateTime.fromISO(
                          queue.requestTimestamp
                        ).toLocaleString(DateTime.DATE_FULL, {
                          locale: i18n.language,
                        })}
                      </div>
                      <div className="truncate">
                        {queue.user?.lastname}, {queue.user?.firstname}
                      </div>
                    </div>
                  </NavLink>
                )
              })}
            </div>
          </nav>
        </div>
        {report?.user && (
          <main className="lg:col-span-12 xl:col-span-10">
            <CockpitDetails user={report.user} report={report} />
          </main>
        )}
      </div>
    </div>
  )
}

ReportEditor.Header = (() => {
  const { t } = useTranslation()
  const { report } = useParamReport()
  return (
    <>
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
          {t('Report Editor')}
        </h1>
      </div>
      {report?.conversationSession && (
        <div className="flex mt-6 space-x-3 sm:ml-4 sm:mt-0">
          <ButtonLink
            design="white"
            target="_blank"
            rel="noopener noreferrer"
            to={`${process.env.REACT_APP_WEB_URI}/anamnesis/${report.conversationSession.id}`}
          >
            Anamnesis
          </ButtonLink>
          <ButtonLink
            design="white"
            target="_blank"
            rel="noopener noreferrer"
            to={`${process.env.REACT_APP_WEB_URI}/chat/archive/${report.id}`}
          >
            Chat
          </ButtonLink>
        </div>
      )}
    </>
  )
}) satisfies FC
