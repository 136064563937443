import classNames from 'classnames'
import type {
  FC,
  ForwardRefExoticComponent,
  HTMLAttributes,
  PropsWithChildren,
  RefAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
} from 'react'
import { forwardRef } from 'react'

type TableProps = PropsWithChildren & {
  className?: string
}

type TablePartComponent<T, P = HTMLAttributes<T>> = ForwardRefExoticComponent<
  P & PropsWithChildren & RefAttributes<T>
>

type TableSectionComponent = TablePartComponent<HTMLTableSectionElement>

type TableComponent = FC<TableProps> & {
  Header: TableSectionComponent
  Body: TableSectionComponent
  Footer: TableSectionComponent
  Row: TablePartComponent<HTMLTableRowElement>
  Cell: TablePartComponent<
    HTMLTableCellElement,
    TdHTMLAttributes<HTMLTableCellElement>
  >
  HeaderCell: TablePartComponent<
    HTMLTableCellElement,
    ThHTMLAttributes<HTMLTableCellElement>
  >
}

export const Table: TableComponent = ({ className, children }) => (
  <div className={classNames('flex flex-col', className)}>
    <div className="min-w-full overflow-hidden overflow-x-auto align-middle rounded-lg shadow">
      <table className="min-w-full divide-y divide-gray-200">{children}</table>
    </div>
  </div>
)

const Section = (
  Type: keyof Pick<JSX.IntrinsicElements, 'tbody' | 'thead' | 'tfoot'>
): TableSectionComponent =>
  forwardRef(({ children, className, ...args }, ref) => (
    <Type
      ref={ref}
      className={classNames(className, 'bg-white divide-y divide-gray-200')}
      {...args}
    >
      {children}
    </Type>
  ))

Table.Header = Section('thead')
Table.Body = Section('tbody')
Table.Footer = Section('tfoot')

Table.Row = forwardRef(({ children, ...args }, ref) => (
  <tr ref={ref} {...args}>
    {children}
  </tr>
))

Table.Cell = forwardRef(({ children, className, ...args }, ref) => (
  <td
    ref={ref}
    className={classNames(
      className,
      'px-6 py-4 text-sm sm:px-6 whitespace-nowrap'
    )}
    {...args}
  >
    {children}
  </td>
))

Table.HeaderCell = forwardRef(({ children, className, ...args }, ref) => (
  <th
    ref={ref}
    className={classNames(
      className,
      'px-6 py-3 sm:px-6 font-medium text-left text-primary-100 bg-primary-700'
    )}
    {...args}
  >
    {children}
  </th>
))
