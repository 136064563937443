import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import _ from 'lodash'
import { DateTime } from 'luxon'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import type { ConversationSessionFragment } from '../GraphQL/graphql'

type Props = {
  session: ConversationSessionFragment
}

export const UserDetailsSession: FC<Props> = ({ session }) => {
  const { i18n } = useTranslation()

  const progress = (session.progress ?? 0) * 100

  const sortedCriteria = _.clone(session.criteria).sort((a, b) =>
    a.identifier > b.identifier ? 1 : -1
  )

  return (
    <Disclosure
      as="div"
      className="relative flex flex-col justify-between px-4 py-4 space-y-4 group"
    >
      <div className="flex items-center gap-x-3">
        <Disclosure.Button className="flex-none">
          <ChevronRightIcon className="w-12 h-12 p-3 -m-3 transition ui-open:rotate-90" />
        </Disclosure.Button>
        <div className="flex-grow">
          <div className="text-sm">{session.id}</div>
        </div>
        <div className="flex-none">
          <div className="text-sm text-gray-500">
            active{' '}
            {DateTime.fromISO(session.lastActiveTimestamp).toRelative({
              locale: i18n.language,
              style: 'narrow',
            })}
          </div>
        </div>
      </div>

      <Disclosure.Panel className="flex flex-col space-y-px">
        {!sortedCriteria.length ? (
          <div className="flex items-center justify-center h-32 text-gray-500">
            No criteria yet
          </div>
        ) : (
          sortedCriteria.map((criteria) => (
            <div
              className={classNames(
                'flex items-center px-2 py-1',
                criteria.positive ? 'bg-green-600/10' : 'bg-red-600/10'
              )}
            >
              <div className="flex-grow text-sm">{criteria.identifier}</div>
              <div className="flex-none text-sm text-gray-500">
                {criteria.operand}
              </div>
            </div>
          ))
        )}
      </Disclosure.Panel>

      {/* Progress Bar */}
      <div
        className="absolute bottom-0 left-0 h-1 transition duration-300 bg-primary-600/30 group-hover:bg-primary-600/60"
        style={{
          width: `${progress}%`,
        }}
        title={`${Math.round(progress)}% of last session`}
      />
    </Disclosure>
  )
}
