import { useMutation } from '@apollo/client'
import type { FC } from 'react'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../Contexts/AuthContext'
import type {
  LogoutUserMutation,
  LogoutUserMutationVariables,
} from '../GraphQL/graphql'
import { LogoutUserDocument } from '../GraphQL/graphql'

export const Logout: FC = () => {
  const navigate = useNavigate()
  const { updateAuth } = useContext(AuthContext)

  const [logoutUser] = useMutation<
    LogoutUserMutation,
    LogoutUserMutationVariables
  >(LogoutUserDocument)

  useEffect(() => {
    const handle = async () => {
      try {
        const { data, errors } = await logoutUser()
        if (errors || !data?.logoutUser) {
          // TODO: catch unsuccessful logout and inform user
        } else {
          await updateAuth(null)
          navigate('/')
        }
      } catch (error) {
        // TODO: catch unsuccessful logout and inform user
      }
    }
    handle()
    // should be executed once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
