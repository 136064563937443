import { Observable } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'
import type { Client, ClientOptions, SubscribePayload } from 'graphql-ws'
import { createClient } from 'graphql-ws'
import { ReauthenticateSocketDocument } from '../GraphQL/graphql'
import { sleep } from '../Utils/TimingUtils'

export interface AuthClient extends Client {
  sendAccessToken(accessToken: string): Promise<boolean>
}

export function createSubscriptionClient(options: ClientOptions): AuthClient {
  const client = createClient({
    ...options,
    retryAttempts: 5,
    retryWait: (retries) => sleep(retries * 100),
  })

  function toObservable(operation: SubscribePayload) {
    return new Observable((observer) =>
      client.subscribe(operation, {
        next: (data) => observer.next(data),
        error: (err) => observer.error(err),
        complete: () => observer.complete(),
      })
    )
  }

  const sendAccessToken = (accessToken: string) =>
    new Promise<boolean>((resolve, reject) => {
      if (!ReauthenticateSocketDocument?.loc) {
        reject(false)
        return
      }
      const observable = toObservable({
        query: ReauthenticateSocketDocument.loc.source.body,
        operationName: getOperationName(ReauthenticateSocketDocument),
        variables: { accessToken },
      })

      observable.subscribe({
        next: (data) => {
          if (
            !data ||
            !(typeof data === 'object') ||
            !('reauthenticateSocket' in data) ||
            !data.reauthenticateSocket
          ) {
            resolve(false)
          }
          // expect(data).toBe({ data: { ping: 'pong' } })
          resolve(true)
        },
        complete: () => {
          resolve(true)
        },
        error: () => {
          resolve(false)
        },
      })
    })

  return {
    ...client,
    sendAccessToken,
  }
}
