import type {
  TreeActionObject,
  TreeCheckObject,
  TreeStatementObject,
} from '../GraphQL/graphql'

export type ElementTypes =
  | TreeActionObject
  | TreeCheckObject
  | TreeStatementObject

export const isTreeActionObject = (
  element: ElementTypes
): element is TreeActionObject => {
  return element.__typename === 'TreeActionObject'
}

export const isTreeCheckObject = (
  element: ElementTypes
): element is TreeCheckObject => {
  return element.__typename === 'TreeCheckObject'
}

export const isTreeStatementObject = (
  element: ElementTypes
): element is TreeStatementObject => {
  return element.__typename === 'TreeStatementObject'
}
