import classNames from 'classnames'
import type { ComponentType, FC } from 'react'
import { NavLink } from 'react-router-dom'

export enum NavigationItemStyle {
  Primary,
  Secondary,
}

export type NavigationItemType = {
  name: string
  href: string
  Icon: ComponentType<{ className?: string }>
}

type NavigationItemProps = NavigationItemType & {
  style: NavigationItemStyle
}

export const NavigationItem: FC<NavigationItemProps> = ({
  name,
  href,
  Icon,
  style,
}) => {
  return (
    <NavLink
      to={href}
      className={({ isActive }) =>
        classNames(
          isActive
            ? 'bg-primary-800 text-white'
            : 'text-primary-100 hover:bg-primary-600 hover:text-white',
          style === NavigationItemStyle.Primary ? 'text-base' : 'text-sm',
          'group flex items-center rounded-md px-2 py-2 font-medium'
        )
      }
    >
      <Icon
        className={classNames(
          style === NavigationItemStyle.Primary ? 'h-6' : 'h-5',
          'flex-shrink-0 w-6 h-6 mr-4 text-primary-200'
        )}
        aria-hidden="true"
      />
      {name}
    </NavLink>
  )
}
