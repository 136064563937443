import { ArrowPathIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import _ from 'lodash'
import type { FC, MouseEventHandler } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { DeleteButton } from '../Components/DeleteButton'
import { GoToButton } from '../Components/GoToButton'
import { StrictModeDroppable } from '../Components/StrictModeDroppable'
import type {
  ConversationTreeDataFragment,
  TreeMultiOptionContainerObject,
  TreeMultiOptionInput,
  TreeStatementInput,
  TreeStatementObject,
} from '../GraphQL/graphql'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'
import { ConversationTreeStatementMultiOption } from './ConversationTreeStatementMultiOption'
import { ConversationTreeStatementOptionNext } from './ConversationTreeStatementOptionNext'

type Props = {
  treeData: ConversationTreeDataFragment
  statement: TreeStatementObject
  optionGroup: TreeMultiOptionContainerObject | undefined
  optionGroupIndex: number
}

export const ConversationTreeStatementMultiOptionGroup: FC<Props> = ({
  treeData,
  statement,
  optionGroup: remoteOptionGroup,
  optionGroupIndex,
}) => {
  const [optionGroup, setOptionGroup] = useState(remoteOptionGroup)
  const [newOptionLoadingIndex, setNewOptionLoadingIndex] = useState(-1)

  useEffect(() => {
    setOptionGroup(remoteOptionGroup)
  }, [remoteOptionGroup])

  const {
    mutations: {
      upsertConversationTreeStatement: [upsertConversationTreeStatement],
    },
  } = useConversationTreeData()

  const deleteEmptyOptionGroup: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      async (e) => {
        const intermediateStatement = _.cloneDeep(statement)

        if (!intermediateStatement?.multiOptions?.length) return

        intermediateStatement.multiOptions.splice(optionGroupIndex, 1)

        e.currentTarget.blur()

        setOptionGroup(undefined)

        await upsertConversationTreeStatement({
          variables: { input: intermediateStatement, treeDataId: treeData.id },
        })
      },
      [
        optionGroupIndex,
        statement,
        treeData.id,
        upsertConversationTreeStatement,
      ]
    )

  const addNewOption: MouseEventHandler<HTMLButtonElement> =
    useCallback(async () => {
      const intermediateStatement: TreeStatementInput = _.cloneDeep(statement)

      if (!intermediateStatement?.multiOptions?.length) return

      const option: TreeMultiOptionInput = { text: [] }

      setNewOptionLoadingIndex(optionGroupIndex)

      intermediateStatement.multiOptions[optionGroupIndex].options.push(option)

      await upsertConversationTreeStatement({
        variables: { input: intermediateStatement, treeDataId: treeData.id },
      })
      setNewOptionLoadingIndex(-1)
    }, [
      optionGroupIndex,
      statement,
      treeData.id,
      upsertConversationTreeStatement,
    ])

  if (!optionGroup) return null

  const { id, options, next } = optionGroup

  const nextUrl = next?.nextId && `#${next?.nextId}`

  return (
    <StrictModeDroppable droppableId={id}>
      {(provided) => (
        <div
          className="divide-y divide-gray-100"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <div className="flex items-center px-4 py-3">
            <div className="flex-1 space-y-2">
              <div className="flex items-center font-semibold gap-x-4 text-primary-700">
                Group {optionGroupIndex + 1}
                {!options.length && (
                  <DeleteButton
                    onDelete={deleteEmptyOptionGroup}
                    title="remove empty option group"
                  />
                )}
              </div>

              <ConversationTreeStatementOptionNext
                option={optionGroup}
                treeData={treeData}
                statement={statement}
              />
            </div>
            <GoToButton to={nextUrl} className="flex-none p-2 -m-2" />
          </div>
          {options.map((option, index) => (
            <ConversationTreeStatementMultiOption
              key={option.id}
              option={option}
              optionGroupIndex={optionGroupIndex}
              optionIndex={index}
              statement={statement}
              treeData={treeData}
            />
          ))}
          {provided.placeholder}
          <button
            className={classNames(
              'flex w-full items-center px-4 py-3 gap-x-4 hover:bg-gray-900/5 text-gray-500 italic',
              newOptionLoadingIndex === optionGroupIndex && 'animate-pulse'
            )}
            onClick={addNewOption}
          >
            {newOptionLoadingIndex === optionGroupIndex ? (
              <ArrowPathIcon className="w-6 h-6 animate-spin text-primary-700" />
            ) : (
              <PlusCircleIcon
                className="inline w-6 h-6 text-primary-700"
                title="add empty option"
                aria-hidden="true"
              />
            )}
            New option
          </button>
        </div>
      )}
    </StrictModeDroppable>
  )
}
