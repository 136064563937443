import JSZip from 'jszip'

export const TREE_DATA_EXPORT_EXTENSION = 'drotree'

export const downloadBlobAsFile = (filename: string, blob: Blob) => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  a.click()
}

export const downloadContentAsZipFile = async (
  filename: string,
  content: string
) => {
  const zip = new JSZip()
  zip.file(filename, content)
  const blob = await zip.generateAsync({ type: 'blob', compression: 'DEFLATE' })
  downloadBlobAsFile(filename, blob)
}

export const getContentFromZipFile = async (file: File) => {
  const zip = new JSZip()
  await zip.loadAsync(file)
  return Object.values(zip.files)[0].async('string')
}
