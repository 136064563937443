import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  CreateLoginLinkMutation,
  CreateLoginLinkMutationVariables,
  CreateResearchUserMutation,
  CreateResearchUserMutationVariables,
  DeactivateLoginLinkMutation,
  DeactivateLoginLinkMutationVariables,
  DeleteResearchUserMutation,
  DeleteResearchUserMutationVariables,
  GetUserQuery,
  GetUserQueryVariables,
  UpdateUserActiveStateMutation,
  UpdateUserActiveStateMutationVariables,
  UpsertUserMutation,
  UpsertUserMutationVariables,
} from '../GraphQL/graphql'
import {
  CreateLoginLinkDocument,
  CreateResearchUserDocument,
  DeactivateLoginLinkDocument,
  DeleteResearchUserDocument,
  GetUserDocument,
  GetUserUpdatesDocument,
  UpdateUserActiveStateDocument,
  UpsertUserDocument,
} from '../GraphQL/graphql'

export const useUser = (userId?: string) => {
  const [getUser, { data, loading, subscribeToMore }] = useLazyQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(GetUserDocument)

  useEffect(() => {
    userId && getUser({ variables: { userId } })
  }, [getUser, userId])

  useEffect(() => {
    if (!userId) return
    const unsubscribe = subscribeToMore({
      document: GetUserUpdatesDocument,
      variables: { userId } satisfies GetUserQueryVariables,
      updateQuery: (_, { subscriptionData }) => subscriptionData.data,
    })
    return () => unsubscribe()
  }, [subscribeToMore, userId])

  const upsertUser = useMutation<
    UpsertUserMutation,
    UpsertUserMutationVariables
  >(UpsertUserDocument)

  const createResearchUser = useMutation<
    CreateResearchUserMutation,
    CreateResearchUserMutationVariables
  >(CreateResearchUserDocument)

  const updateUserActiveState = useMutation<
    UpdateUserActiveStateMutation,
    UpdateUserActiveStateMutationVariables
  >(UpdateUserActiveStateDocument)

  const createLoginLink = useMutation<
    CreateLoginLinkMutation,
    CreateLoginLinkMutationVariables
  >(CreateLoginLinkDocument)

  const deactivateLoginLink = useMutation<
    DeactivateLoginLinkMutation,
    DeactivateLoginLinkMutationVariables
  >(DeactivateLoginLinkDocument)

  const deleteResearchUser = useMutation<
    DeleteResearchUserMutation,
    DeleteResearchUserMutationVariables
  >(DeleteResearchUserDocument)

  const mutations = useMemo(
    () => ({
      createResearchUser,
      upsertUser,
      updateUserActiveState,
      createLoginLink,
      deactivateLoginLink,
      deleteResearchUser,
    }),
    [
      createResearchUser,
      upsertUser,
      updateUserActiveState,
      createLoginLink,
      deactivateLoginLink,
      deleteResearchUser,
    ]
  )

  return useMemo(
    () => ({
      user: userId && data?.getUser ? data?.getUser : undefined,
      userLoading: loading,
      mutations,
    }),
    [data, loading, mutations, userId]
  )
}
