import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import type { FC, MouseEventHandler } from 'react'
import { Fragment } from 'react'

export type MenuItemClickHandler = MouseEventHandler<HTMLButtonElement>

export type MenuItem = {
  label: string
  onClick: MenuItemClickHandler
  disabled?: boolean
}

type Props = {
  menuItems: MenuItem[]
}

export const DotMenu: FC<Props> = ({ menuItems }) => {
  return (
    <Menu as="div" className="inline-block text-left">
      <Menu.Button
        className="flex items-center p-2 -m-2 text-gray-700 rounded hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary-500"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <span className="sr-only">Open options</span>
        <EllipsisVerticalIcon className="w-5 h-5" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg right-2 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {menuItems.map(({ label, onClick, disabled }) => (
              <Menu.Item key={label} disabled={disabled}>
                {({ active }) => (
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      onClick(e)
                    }}
                    className={classNames(
                      disabled && 'pointer-events-none opacity-50',
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm w-full text-left'
                    )}
                  >
                    {label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
