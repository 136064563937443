import { useMutation } from '@apollo/client'
import MarkdownIt from 'markdown-it'
import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css'
import { Button } from '../Components/Button'
import type {
  EditReportMutation,
  EditReportMutationVariables,
  FreezeReportMutation,
  FreezeReportMutationVariables,
  RegenerateReportMutation,
  RegenerateReportMutationVariables,
  ReportDetailsFragment,
} from '../GraphQL/graphql'
import {
  EditReportDocument,
  FreezeReportDocument,
  RegenerateReportDocument,
} from '../GraphQL/graphql'
import { getReportTitle } from '../Helper/ReportHelper'
import { useProfile } from '../Hooks/useProfile'

type Props = {
  report?: ReportDetailsFragment
  isLoading?: boolean
}

const mdParser = new MarkdownIt()

// TODO: replace current implementation with a cool and shiny Collaboration Text Editor
export const CockpitFindingsReport: FC<Props> = ({ report, isLoading }) => {
  const { t } = useTranslation()
  const { profile } = useProfile()
  // const navigate = useNavigate()

  const reportId = report?.id

  const [text, setText] = useState(report?.text ?? '')
  // const [isUpdating, setIsUpdating] = useState(false)
  const isDirty = text !== (report?.text ?? '')

  useEffect(() => {
    setText(report?.text ?? '')
  }, [report?.text])

  const [editReportMutation, { loading: editReportLoading }] = useMutation<
    EditReportMutation,
    EditReportMutationVariables
  >(EditReportDocument)

  const [freezeReportMutation, { loading: freezeReportLoading }] = useMutation<
    FreezeReportMutation,
    FreezeReportMutationVariables
  >(FreezeReportDocument)

  const [regenerateReportMutation, { loading: regenerateReportLoading }] =
    useMutation<RegenerateReportMutation, RegenerateReportMutationVariables>(
      RegenerateReportDocument
    )

  const reportTitle = getReportTitle(t, report)
  const isInLoadingState =
    isLoading ||
    editReportLoading ||
    freezeReportLoading ||
    regenerateReportLoading

  const isEditable = !report?.released && !report?.frozen

  const freezeReport = useCallback(() => {
    if (reportId) {
      freezeReportMutation({ variables: { reportId } })
    }
  }, [freezeReportMutation, reportId])

  const saveReport = useCallback(() => {
    if (reportId) {
      editReportMutation({ variables: { reportId, text } })
    }
  }, [editReportMutation, reportId, text])

  const regenerateReport = useCallback(() => {
    if (reportId) {
      regenerateReportMutation({ variables: { reportId } })
    }
  }, [regenerateReportMutation, reportId])

  if (!report) return null

  return (
    <>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg isolate">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
          <h2 className="text-lg font-medium leading-6 text-gray-900">
            {reportTitle}
          </h2>
        </div>
        <div className="flex sm:space-x-6">
          {isLoading ? (
            [...Array(2)].map((_, i) => (
              <div key={`load-${i}`} className="flex-1 p-4 animate-pulse">
                {[...Array(9)].map((_, j) => (
                  <div
                    key={`load-${i}-${j}`}
                    className="h-3 mb-2 bg-gray-300 rounded-full even:w-[92%]"
                  />
                ))}
              </div>
            ))
          ) : (
            <MdEditor
              // TODO: check why "underlined" is not working in `mdParser`
              renderHTML={(text) => mdParser.render(text)}
              value={text}
              onChange={({ text }) => {
                setText(text)
              }}
              className="h-[60vh] flex-1 sm:rounded-b-lg overflow-hidden border-0"
              placeholder="Bericht hier schreiben"
              htmlClass="prose overflow-ellipsis overflow-x-hidden"
              syncScrollMode={['rightFollowLeft', 'leftFollowRight']}
              readOnly={!isEditable || isInLoadingState}
            />
          )}
        </div>
      </div>

      {isEditable && (
        <div className="w-full">
          <section className="grid grid-cols-4 gap-4 mx-4 sm:mx-0">
            <Button
              design="white"
              onClick={() => regenerateReport()}
              disabled={isInLoadingState}
              className="col-span-1 justify-center"
            >
              {regenerateReportLoading
                ? t('Regenerating...')
                : t('Regenerate Report')}
            </Button>
            <Button
              design="white"
              onClick={() => saveReport()}
              disabled={isInLoadingState || !isDirty}
              className="col-span-1 justify-center"
            >
              {editReportLoading ? t('Saving...') : t('Save')}
            </Button>
            {(!report.reviewer || profile?.id === report.reviewer?.id) && (
              <Button
                type="button"
                onClick={() => freezeReport()}
                className="col-span-2 justify-center"
                disabled={isInLoadingState || isDirty}
              >
                {t('Release')}
              </Button>
            )}
          </section>
        </div>
      )}
    </>
  )
}
