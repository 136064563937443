import classNames from 'classnames'
import type { FC, PropsWithChildren } from 'react'

type Props = PropsWithChildren & {
  title: string
  subtitle?: string
  noPaddings?: boolean
}

export const DetailFullViewCard: FC<Props> = ({
  title,
  subtitle,
  children,
  noPaddings,
}) => {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className={classNames(!noPaddings && 'px-4 py-6 sm:p-8')}>
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          {title}
        </h3>
        {subtitle && (
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            {subtitle}
          </p>
        )}
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">{children}</dl>
      </div>
    </div>
  )
}
