import type { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from '../Components/Button'
import { ButtonLink } from '../Components/ButtonLink'
import type {
  ConversationTreeDataInput,
  ConversationTreeInput,
} from '../GraphQL/graphql'
import { IDENTIFIER_PATTERN } from '../Helper/IdentifierHelper'
import { useConversationTree } from '../Hooks/useConversationTree'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'
import { useIllnesses } from '../Hooks/useIllnesses'
import type { AppShellComponent } from './AppShell'

type Input = {
  tree: ConversationTreeInput
  data: ConversationTreeDataInput
}

export const ConversationTreeCreate: AppShellComponent = () => {
  const navigate = useNavigate()

  const {
    mutations: {
      upsertConversationTree: [
        upsertConversationTree,
        { loading: treeLoading },
      ],
    },
  } = useConversationTree()

  const {
    mutations: {
      upsertConversationTreeData: [
        upsertConversationTreeData,
        { loading: dataLoading },
      ],
    },
  } = useConversationTreeData()

  const loading = treeLoading || dataLoading

  const { illnesses, illnessesLoading } = useIllnesses()

  const { register, handleSubmit, setError } = useForm<Input>({
    defaultValues: { tree: { illnessId: '' }, data: { identifier: 'v1' } },
  })

  const onSubmit = handleSubmit(async (userInput) => {
    const { data: treeResponse } = await upsertConversationTree({
      variables: userInput.tree,
    })

    const tree = treeResponse?.upsertConversationTree?.tree

    if (!tree) {
      setError('root', { message: 'Could not create Conversation Tree' })
      return
    }

    userInput.data.parentId = tree.id

    const { data: dataResponse } = await upsertConversationTreeData({
      variables: userInput.data,
    })

    const treeData = dataResponse?.upsertConversationTreeData?.treeData

    if (!treeData) {
      setError('root', {
        message: 'Could not create Conversation Tree Version',
      })
      return
    }

    navigate(`/conversation-trees/${tree.id}/${treeData.id}}`)
  })

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
      <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7">
            Conversation Tree Information
          </h2>
        </div>

        <form
          className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
          onSubmit={onSubmit}
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-full">
                <label
                  htmlFor="identifier"
                  className="block text-sm font-medium leading-6"
                >
                  Identifier
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="identifier"
                    autoComplete="off"
                    placeholder="identifier.for.conversation.tree"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                    {...register('tree.identifier', {
                      required: true,
                      pattern: IDENTIFIER_PATTERN,
                    })}
                  />
                </div>
              </div>
              <div className="sm:col-span-full">
                <label
                  htmlFor="identifier"
                  className="block text-sm font-medium leading-6"
                >
                  First Version Identifier
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="identifier"
                    autoComplete="off"
                    placeholder="identifier.for.conversation.tree"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                    {...register('data.identifier', {
                      required: true,
                      pattern: IDENTIFIER_PATTERN,
                    })}
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="illness"
                  className="block text-sm font-medium leading-6"
                >
                  Illness
                </label>
                <div className="mt-2">
                  <select
                    id="illness"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    disabled={illnessesLoading}
                    {...register('tree.illnessId', { required: true })}
                  >
                    <option value="" disabled>
                      Select an illness
                    </option>
                    {illnesses.map(({ id, nameLocales }) => (
                      <option key={id} value={id}>
                        {nameLocales.at(0)?.text ?? 'Illness'}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-8">
            <ButtonLink to=".." design="white">
              Cancel
            </ButtonLink>
            <Button type="submit" disabled={loading} design="secondary">
              Create Conversation Tree
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

ConversationTreeCreate.Header = (() => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
          {t('Create {{target}}', {
            replace: { target: t('Conversation Tree') },
          })}
        </h1>
      </div>
      <div className="flex mt-6 space-x-3 sm:ml-4 sm:mt-0">
        <ButtonLink to=".." design="white">
          {t('Back to {{target}} dashboard', {
            replace: { target: t('Conversation Trees') },
          })}
        </ButtonLink>
      </div>
    </>
  )
}) satisfies FC
