import classNames from 'classnames'
import type { FC } from 'react'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../Components/Button'
import { DetailViewCard } from '../Components/DetailViewCard'
import { UserAvatar } from '../Components/UserAvatar'
import type { UserInput } from '../GraphQL/graphql'
import { CountryCode } from '../GraphQL/graphql'
import { authErrorToMessage } from '../Helper/AuthHelper'
import { profileErrorToMessage } from '../Helper/UserHelper'
import { useParamUser } from '../Hooks/useParamUser'
import { useUser } from '../Hooks/useUser'
import { UserDetailsLoginLink } from '../Modules/UserDetailsLoginLink'
import { UserStateActivated } from '../Modules/UserStateActivated'
import type { AppShellComponent } from './AppShell'

type UserDetailsParams = {
  userId: string
}

export const UserDetails: AppShellComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { userId } = useParams<UserDetailsParams>()

  const {
    user,
    userLoading,
    mutations: {
      upsertUser: [upsertUser],
    },
  } = useUser(userId)

  const userDefaultValues: Partial<UserInput> = useMemo(
    () => ({
      id: user?.id ?? undefined,
      role: user?.role ?? undefined,
      birthdate: user?.birthdate ?? undefined,
      email: user?.email ?? undefined,
      firstname: user?.firstname ?? undefined,
      lastname: user?.lastname ?? undefined,
      phone: user?.phone ?? undefined,
      sex: user?.sex ?? undefined,
      address: user?.address && {
        city: user?.address.city ?? undefined,
        country: user?.address.country ?? undefined,
        instruction: user?.address.instruction ?? undefined,
        street: user?.address.street ?? undefined,
        zip: user?.address.zip ?? undefined,
      },
      isActivated: user?.isActivated ?? undefined,
    }),
    [user]
  )

  const { register, handleSubmit, setError, reset } = useForm<UserInput>({
    defaultValues: userDefaultValues,
  })

  useEffect(() => {
    reset(userDefaultValues)
  }, [reset, userDefaultValues])

  const onSubmit = handleSubmit(async (userInput) => {
    if (
      userInput.address &&
      Object.values(userInput.address).every((addressValue) => !addressValue)
    ) {
      userInput.address = null
    }

    await upsertUser({
      variables: { user: userInput },
      onCompleted: ({ upsertUser }) => {
        if (upsertUser.authError) {
          setError('root', {
            message: authErrorToMessage(upsertUser.authError, t),
          })
        } else if (upsertUser.profileErrors) {
          setError('root', {
            message: upsertUser.profileErrors
              .map((profileError) => profileErrorToMessage(profileError, t))
              .join(' '),
          })
        } else {
          reset()
        }
      },
      onError: () =>
        setError('root', {
          message: t('Error'),
        }),
    })
  })

  useEffect(() => {
    if (userLoading || !user) return
    if (!user.isResearch) return

    navigate(`/research-users/${user.id}`)
  }, [navigate, user, userLoading])

  if (userLoading || !user) {
    return null
  }

  //TODO: error handling and error display

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
      <DetailViewCard title="Personal Information" noPaddings>
        <form
          className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
          onSubmit={onSubmit}
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium leading-6"
                >
                  First name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="firstname"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                    {...register('firstname')}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium leading-6"
                >
                  Last name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="lastname"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                    {...register('lastname')}
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                    {...register('email')}
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6"
                >
                  Street
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="street-address"
                    autoComplete="street-address"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                    {...register('address.street')}
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="postal-code"
                  className="block text-sm font-medium leading-6"
                >
                  ZIP code
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="postal-code"
                    autoComplete="postal-code"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                    {...register('address.zip')}
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium leading-6"
                >
                  City
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="city"
                    autoComplete="address-level2"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                    {...register('address.city')}
                  />
                </div>
              </div>

              <div className="sm:col-span-full">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6"
                >
                  Country
                </label>
                <div className="mt-2">
                  <select
                    id="country"
                    autoComplete="countryname"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    {...register('address.country')}
                  >
                    {Object.values(CountryCode).map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-8">
            <Button design="white" onClick={() => reset()}>
              Cancel
            </Button>
            <Button type="submit" design="secondary">
              Save
            </Button>
          </div>
        </form>
      </DetailViewCard>

      <UserDetailsLoginLink user={user} />
    </div>
  )
}

UserDetails.Header = (() => {
  const { user } = useParamUser()

  if (!user) {
    return null
  }

  return (
    <>
      <div
        className={classNames(
          'flex items-center flex-1 min-w-0 space-x-3',
          !user.isActivated && 'grayscale'
        )}
      >
        <UserAvatar size={8} user={user} />
        <h1
          className={classNames(
            'text-2xl font-bold leading-7 sm:truncate sm:leading-9',
            !user.isActivated && 'line-through'
          )}
        >
          {user?.lastname}{' '}
          <span className="text-gray-500">{user?.firstname}</span>
        </h1>
      </div>
      <div className="flex items-center mt-6 space-x-3 md:ml-4 md:mt-0">
        {user && <UserStateActivated user={user} />}
      </div>
    </>
  )
}) satisfies FC
