import { PlusIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import type { FC, HTMLAttributes } from 'react'
import { useRef } from 'react'
import type { TreeCheckObject } from '../GraphQL/graphql'
import { ConversationTreeCheck } from './ConversationTreeCheck'
import { ConversationTreeCheckCreate } from './ConversationTreeCheckCreate'

type Props = HTMLAttributes<HTMLDivElement> & {
  treeDataId: string
  checks: TreeCheckObject[]
  activeId: string
}

export const ConversationTreeChecks: FC<Props> = ({
  treeDataId,
  checks,
  activeId,
  className,
  ...args
}) => {
  const newCheckIdentifierInput = useRef<HTMLInputElement>(null)

  return (
    <div
      className={classNames(
        'min-w-full overflow-hidden overflow-x-auto divide-y divide-gray-200 rounded-lg shadow',
        className
      )}
      {...args}
    >
      <div className="flex items-center justify-between p-4 gap-x-4 sm:gap-x-6 sm:p-6 text-primary-100 bg-primary-700">
        <div className="font-medium text-left">Check</div>

        <button
          className="block p-3 -m-3 rounded hover:bg-white/10"
          title="Create new Check"
          onClick={() => newCheckIdentifierInput.current?.focus()}
        >
          <PlusIcon className="w-6 h-6 text-white" aria-hidden="true" />
        </button>
      </div>
      {checks.map((check) => (
        <ConversationTreeCheck
          key={check.id}
          check={check}
          activeId={activeId}
          treeDataId={treeDataId}
        />
      ))}
      <ConversationTreeCheckCreate
        treeDataId={treeDataId}
        ref={newCheckIdentifierInput}
      />
    </div>
  )
}
