export const getVideoUrl = (
  videoPath: string,
  id = '404',
  language = ''
): string => {
  const identifier = id
    .toLowerCase()
    .replaceAll('ä', 'ae')
    .replaceAll('ö', 'oe')
    .replaceAll('ü', 'ue')
    .replaceAll('ß', 'ss')

  const videoUrl =
    process.env.REACT_APP_S3_VIDEOS_CHAT +
    `/${videoPath}/${language}/${
      language ? language + '-' : ''
    }${identifier}.mp4`.replaceAll(/\/+/g, '/')

  return videoUrl
}
