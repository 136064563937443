import type { FC } from 'react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from '../Components/Button'
import { ButtonLink } from '../Components/ButtonLink'
import { ResearchCenter, ResearchName } from '../GraphQL/graphql'
import { useUser } from '../Hooks/useUser'
import type { AppShellComponent } from './AppShell'

export const ResearchUserCreate: AppShellComponent = () => {
  const navigate = useNavigate()

  const {
    mutations: {
      createResearchUser: [createResearchUser, { loading }],
    },
  } = useUser()

  const [researchCenter, setResearchCenter] = useState(ResearchCenter.Lmu)
  const [researchName, setResearchName] = useState(
    ResearchName.AnamnesisKnee_2023
  )

  const onCreateResearchUser = useCallback(async () => {
    const { data } = await createResearchUser({
      variables: {
        researchCenter,
        researchName,
      },
    })

    navigate(`/users/${data?.createResearchUser?.user?.id}`)
  }, [createResearchUser, navigate, researchCenter, researchName])

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
      <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7">
            Research Information
          </h2>
        </div>

        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="research-center"
                  className="block text-sm font-medium leading-6"
                >
                  Research Center
                </label>
                <div className="mt-2">
                  <select
                    id="research-center"
                    name="research-center"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    value={researchCenter}
                    onChange={({ currentTarget }) =>
                      setResearchCenter(currentTarget.value as ResearchCenter)
                    }
                  >
                    {Object.values(ResearchCenter).map((center) => (
                      <option key={center} value={center}>
                        {center}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="research-name"
                  className="block text-sm font-medium leading-6"
                >
                  Research
                </label>
                <div className="mt-2">
                  <select
                    id="research-name"
                    name="research-name"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    value={researchName}
                    onChange={({ currentTarget }) =>
                      setResearchName(currentTarget.value as ResearchName)
                    }
                  >
                    {Object.values(ResearchName).map((name) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-8">
            <Button
              disabled={loading}
              design="secondary"
              onClick={onCreateResearchUser}
            >
              Create Research User
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

ResearchUserCreate.Header = (() => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
          {t('Create {{target}}', {
            replace: { target: t('Research User') },
          })}
        </h1>
      </div>
      <div className="flex mt-6 space-x-3 md:ml-4 md:mt-0">
        <ButtonLink to=".." design="white">
          {t('Back to {{target}} dashboard', {
            replace: { target: t('Users') },
          })}
        </ButtonLink>
      </div>
    </>
  )
}) satisfies FC
