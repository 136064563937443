import { use } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { EN_GB } from './locales/en_GB'

export const fallbackLng = 'en-GB'

export const resources = {
  'en-GB': {
    translation: EN_GB,
  },
} as const

use(initReactI18next).init({
  fallbackLng,
  resources,
  interpolation: {
    escapeValue: false,
  },
})
