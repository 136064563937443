import type { FC } from 'react'
import { DetailViewCard } from '../Components/DetailViewCard'
import type { UserFragment } from '../GraphQL/graphql'
import { UserDetailsSession } from './UserDetailsSession'

type Props = {
  user: UserFragment
}

export const UserDetailsSessions: FC<Props> = ({
  user: { conversationSessions },
}) => {
  return (
    <DetailViewCard title="Sessions" subtitle="All user sessions" noPaddings>
      {!conversationSessions?.length ? (
        <div className="flex items-center justify-center h-32 text-gray-500">
          No sessions yet
        </div>
      ) : (
        conversationSessions.map((session) => (
          <UserDetailsSession key={session.id} session={session} />
        ))
      )}
    </DetailViewCard>
  )
}
