import { useMutation } from '@apollo/client'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import type { FC } from 'react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type {
  DicomSeriesFragment,
  ImageOperandFragment,
  SendDicomInstancesToReportingMutation,
  SendDicomInstancesToReportingMutationVariables,
} from '../GraphQL/graphql'
import { SendDicomInstancesToReportingDocument } from '../GraphQL/graphql'

type Props = {
  imageOperands?: ImageOperandFragment[]
  dicomSeries?: DicomSeriesFragment
  isLoading?: boolean
}

export const CockpitXRayReport: FC<Props> = ({
  imageOperands = [],
  dicomSeries,
  isLoading,
}) => {
  const [isReportProcessing, setIsReportProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation()

  const [sendDicom] = useMutation<
    SendDicomInstancesToReportingMutation,
    SendDicomInstancesToReportingMutationVariables
  >(SendDicomInstancesToReportingDocument)

  const startRefetchReports = async () => {
    if (dicomSeries) {
      setIsReportProcessing(true)
      const res = await sendDicom({
        variables: {
          instanceIds: dicomSeries.instances.map(({ id }) => id),
        },
      })
      if (!res.data?.sendDicomInstancesToReporting || res.errors) {
        setErrorMessage(t('DICOM files couldn’t be sent'))
        setIsReportProcessing(false)
      }
    }
  }

  useEffect(() => {
    setIsReportProcessing(false)
  }, [imageOperands, dicomSeries])

  const xRayReport = useMemo(() => {
    if (imageOperands.length) {
      return [...imageOperands]
        .sort((a, b) => a.order - b.order)
        .map((io) => {
          if (io.uncalculateable && !io.value) {
            return [io.label, t('No automatic calculation')]
          } else {
            return [io.label, `${io.value}${io.unit ? ' ' + io.unit : ''}`]
          }
        })
    } else {
      return [['Calculating', '...']]
    }
  }, [imageOperands, t])

  return (
    <>
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h2 className="text-lg font-medium leading-6 text-gray-900">
          Automatische Bildbefundung
        </h2>
      </div>
      <div className="max-w-md p-4 sm:p-6">
        {imageOperands.length || isLoading || isReportProcessing ? (
          <dl
            className={classNames(
              'grid grid-cols-[4fr_1fr] gap-2 items-center',
              (isLoading || isReportProcessing) && 'animate-pulse'
            )}
          >
            {xRayReport.map(([key, value]) => (
              <Fragment key={key}>
                <dt className="flex items-center text-sm font-medium text-right text-gray-500">
                  {key}
                </dt>
                <dd className="flex items-center text-sm text-gray-900">
                  {isLoading || isReportProcessing ? (
                    <div className="w-12 h-3 bg-gray-300 rounded-full" />
                  ) : (
                    value || <i className="text-xs text-gray-500">fehlt</i>
                  )}
                </dd>
              </Fragment>
            ))}
          </dl>
        ) : (
          <>
            {errorMessage && (
              <div className="flex p-4 mb-4 -m-3 rounded-md bg-red-50">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="w-5 h-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-sm font-medium text-red-800">
                    {errorMessage}
                  </div>
                  <div className="mt-2 text-sm text-red-700">
                    <p>
                      Bitte probieren Sie es erneut. Falls der Fehler weiterhin
                      auftritt, wenden Sie sich bitte an unseren Support.
                    </p>
                  </div>
                </div>
              </div>
            )}
            <p>Für dieses Bild existiert bisher kein Befund.</p>
            <button
              className="inline-flex items-center justify-center w-full px-4 py-2 mt-6 text-sm font-medium text-white bg-green-500 border border-transparent rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              onClick={async () => {
                startRefetchReports()
              }}
            >
              Automatische Bildbefundung anfordern
            </button>
          </>
        )}
      </div>
    </>
  )
}
