import type { TFunction } from 'i18next'
import type { ReportFragment } from '../GraphQL/graphql'
import { ReportType } from '../GraphQL/graphql'

export const getReportTitle = (
  t: TFunction,
  report?: Pick<ReportFragment, 'reportType'>
): string => {
  switch (report?.reportType) {
    case ReportType.EvaluationReport:
      return t('Orthopedic X-ray report')
    case ReportType.MedicalReport:
      return t('Evidence-based findings report')
    default:
      return t('Report')
  }
}
