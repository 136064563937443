import { useMutation } from '@apollo/client'
import MarkdownIt from 'markdown-it'
import type { FC, FormEventHandler } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../Components/Button'
import type {
  ReleaseReportMutation,
  ReleaseReportMutationVariables,
  UnfreezeReportMutation,
  UnfreezeReportMutationVariables,
} from '../GraphQL/graphql'
import {
  ReleaseReportDocument,
  UnfreezeReportDocument,
} from '../GraphQL/graphql'
import { useReport } from '../Hooks/useReport'
import type { AppShellComponent } from './AppShell'
import { Loading } from './Loading'
import { LoadingError } from './LoadingError'

type ReportEditorReleaseParams = {
  reportId: string
}

const mdParser = new MarkdownIt()

export const ReportEditorRelease: AppShellComponent = () => {
  const { reportId } = useParams<ReportEditorReleaseParams>()

  const navigate = useNavigate()

  const [lastnameConfirm, setLastnameConfirm] = useState('')

  const { report, reportLoading } = useReport(reportId)

  useEffect(() => {
    if (reportLoading || !report) return
    if (!report.frozen) {
      navigate('..')
    }
    if (report.released) {
      navigate(`/reports/${report.id}`)
    }
  }, [navigate, report, reportLoading])

  const [unfreezeReportMutation, { loading: unfreezeLoading }] = useMutation<
    UnfreezeReportMutation,
    UnfreezeReportMutationVariables
  >(UnfreezeReportDocument)

  const [releaseReportMutation, { loading: releaseLoading }] = useMutation<
    ReleaseReportMutation,
    ReleaseReportMutationVariables
  >(ReleaseReportDocument)

  const unfreezeReport = useCallback(() => {
    if (reportId) {
      unfreezeReportMutation({ variables: { reportId } })
    }
  }, [unfreezeReportMutation, reportId])

  const html = useMemo(
    () => (report?.text ? mdParser.render(report.text) : undefined),
    [report]
  )

  const onRelease: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()

      if (!report) return

      const { data } = await releaseReportMutation({
        variables: { reportId: report.id },
      })

      const error = data?.releaseReport.error

      if (error) return

      navigate(`../..`)
    },

    [navigate, releaseReportMutation, report]
  )

  if (reportLoading) {
    return <Loading />
  }

  if (!report || !html) {
    return <LoadingError />
  }

  // if (treeLoading || !tree || treeLoading || !tree) return null

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
      <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7">Release Report</h2>
        </div>

        <form
          onSubmit={onRelease}
          className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-full">
                Do you really want to release the report?
              </div>
              <div className="sm:col-span-full">
                <label
                  htmlFor="identifier-confirm"
                  className="block text-sm font-medium leading-6"
                >
                  Type <pre className="inline">{report?.user.lastname}</pre>{' '}
                  into the field below to confirm.
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="identifier-confirm"
                    name="identifier-confirm"
                    autoComplete="off"
                    // placeholder={report?.user.lastname}
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                    value={lastnameConfirm}
                    onChange={({ currentTarget }) =>
                      setLastnameConfirm(currentTarget.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-8">
            <Button
              type="button"
              disabled={reportLoading || unfreezeLoading || releaseLoading}
              onClick={() => unfreezeReport()}
              design="white"
            >
              {unfreezeLoading ? 'Cancelling...' : 'Cancel'}
            </Button>
            <Button
              type="submit"
              disabled={
                reportLoading ||
                unfreezeLoading ||
                releaseLoading ||
                report?.user.lastname !== lastnameConfirm
              }
              design="secondary"
            >
              {releaseLoading ? 'Releasing...' : 'Release'}
            </Button>
          </div>
        </form>
      </div>
      <div className="col-span-3 overflow-hidden bg-white shadow sm:rounded-lg isolate">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
          <h2 className="text-lg font-medium leading-6 text-gray-900">
            Bericht
          </h2>
        </div>
        <div className="flex sm:space-x-6 px-4 py-5 text-gray-900">
          <div dangerouslySetInnerHTML={{ __html: html }} className="prose" />
        </div>
      </div>
    </div>
  )
}

ReportEditorRelease.Header = (() => {
  return (
    <div className="flex-1 min-w-0">
      <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
        Release Report
      </h1>
    </div>
  )
}) satisfies FC
