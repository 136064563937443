import type { FC } from 'react'
import type { NavigationItemType } from '../Components/NavigationItem'
import {
  NavigationItem,
  NavigationItemStyle,
} from '../Components/NavigationItem'
import logo from '../logo.png'

type Props = {
  navigation: NavigationItemType[]
  secondaryNavigation: NavigationItemType[]
}

export const NavigationDesktop: FC<Props> = ({
  navigation,
  secondaryNavigation,
}) => {
  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
      <div className="flex flex-col flex-grow pb-4 overflow-y-auto from-primary-700 to-primary-600 bg-gradient-to-br">
        <div className="flex items-center flex-shrink-0 px-2 py-2">
          <img
            className="w-auto h-12 invert opacity-80"
            src={logo}
            alt="DocRobin logo"
          />
        </div>
        <nav
          className="flex flex-col flex-1 px-2 mt-2 overflow-y-auto divide-y-2 divide-primary-100/20"
          aria-label="Sidebar"
        >
          <div className="space-y-1">
            {navigation.map((item) => (
              <NavigationItem
                key={item.name}
                {...item}
                style={NavigationItemStyle.Primary}
              />
            ))}
          </div>
          <div className="pt-6 mt-6 space-y-1">
            {secondaryNavigation.map((item) => (
              <NavigationItem
                key={item.name}
                {...item}
                style={NavigationItemStyle.Secondary}
              />
            ))}
          </div>
        </nav>
      </div>
    </div>
  )
}
