import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import type { FC } from 'react'
import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'

type Props = Partial<LinkProps>

export const GoToButton: FC<Props> = ({ to, className, ...args }) => {
  if (!to) return null

  return (
    <Link
      to={to}
      className={classNames(className, 'rounded hover:bg-gray-900/10')}
      {...args}
    >
      <ChevronDoubleRightIcon
        className="w-6 h-6 text-primary-700"
        aria-hidden="true"
      />
    </Link>
  )
}
