export const EN_GB = {
  'Report Editor': 'Report Editor',
  'Adjust image findings': 'Adjust image findings',
  'Link DICOM and regenerate': 'Link DICOM and regenerate',
  'Doctor`s Cockpit': 'Doctor`s Cockpit',
  'DICOM files couldn’t be sent': 'DICOM files couldn’t be sent',
  'Evidence-based findings report': 'Evidence-based findings report',
  'No automatic calculation': 'No automatic calculation',
  'Orthopedic X-ray report': 'Orthopedic X-ray report',
  Report: 'Report',
  'Regenerate Report': 'Regenerate Report',
  'Save and release {{title}}': 'Save and release {{title}}',
  'Activate {{target}}': 'Activate {{target}}',
  Actions: 'Actions',
  'Active from': 'Active from',
  'Add new Tree': 'Add new Tree',
  'Anamnesis Report': 'Anamnesis Report',
  'Back to {{target}}': 'Back to {{target}}',
  'Back to {{target}} dashboard': 'Back to {{target}} dashboard',
  Checks: 'Checks',
  'Conversation Tree': 'Conversation Tree',
  'Conversation Tree Version': 'Conversation Tree Version',
  'Conversation Trees': 'Conversation Trees',
  'Create {{target}}': 'Create {{target}}',
  Criteria: 'Criteria',
  Delete: 'Delete',
  'Delete {{target}}': 'Delete {{target}}',
  Duplicate: 'Duplicate',
  Release: 'Release',
  'Regenerating...': 'Regenerating...',
  'Saving...': 'Saving...',
  'Email address': 'Email address',
  Error: 'Unknown Error',
  Export: 'Export',
  GERMANY: 'Germany',
  Import: 'Import',
  'Medical Report': 'Medical Report',
  Password: 'Password',
  Products: 'Products',
  Orders: 'Orders',
  Reports: 'Reports',
  'Research User': 'Research User',
  'Recent registrations': 'Recent registrations',
  'Send SMS Token': 'Send SMS Token',
  'Sign in': 'Sign in',
  'Sign in to your account': 'Sign in to your account',
  'SMS Token': 'SMS Token',
  Statements: 'Statements',
  Users: 'Users',
  Version: 'Version',
  'ConversationTreeStatementInputType.NONE': 'No User Input',
  'ConversationTreeStatementInputType.OPTIONS': 'Options Selection',
  'ConversationTreeStatementInputType.FREE': 'Free Input',
  'error-activation-link-expired':
    'Der Bestätigungslink ist entweder falsch oder abgelaufen.',
  'error-already-verified': 'Der Nutzer wurde bereits verifiziert.',
  'error-duplicate-user': 'Der Nutzer existiert bereits.',
  'error-email-not-updated':
    'Die E-Mail ist bereits bekannt und wurde nicht geändert.',
  'error-incorrect-password': 'Das Passwort ist nicht korrekt.',
  'error-invalid-sms-token':
    'Das SMS-Token ist entweder falsch oder bereits abgelaufen.',
  'error-no-matching-user': 'Zu dieser E-Mail gibt es keinen Nutzer.',
  'error-registration-failed': 'Die Registrierung ist fehlgeschlagen.',
  'error-unconfirmed-email':
    'Die E-Mail muss noch über die Bestätigungsmail freigeschalten werden.',
  'error-unconfirmed-phone':
    'Die Telefonnummer muss noch über die Bestätigungs-SMS freigeschaltet werden.',
  'error-user-not-activated':
    'Der Nutzer existiert bereits, muss jedoch noch freigeschaltet werden. Bitte kontaktieren Sie unser Support-Team.',
  'error-verification-email-sending':
    'Die E-Mail mit dem Bestätigungslink konnte nicht versendet werden.',
  'error-verification-sms-sending':
    'Die SMS mit dem Aktivierungscode konnte nicht versendet werden.',
  'error-invalid-password': 'Falsches Passwort.',
  'Video path': 'Video path',
  Settings: 'Settings',
  Save: 'Save',
}
