import type { FC, FormEventHandler } from 'react'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../Components/Button'
import { ButtonLink } from '../Components/ButtonLink'
import { DetailViewCard } from '../Components/DetailViewCard'
import type { UserObject } from '../GraphQL/graphql'
import { useUser } from '../Hooks/useUser'

type Props = {
  user: Pick<UserObject, 'id' | 'researchParticipantId'>
}

export const UserDangerZone: FC<Props> = ({
  user: { id: userId, researchParticipantId },
}) => {
  const navigate = useNavigate()

  const [participantIdConfirm, setParticipantIdConfirm] = useState('')

  const {
    mutations: {
      deleteResearchUser: [deleteResearchUser],
    },
  } = useUser()

  const onDelete: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()

      if (participantIdConfirm !== researchParticipantId) return

      const { data } = await deleteResearchUser({
        variables: { userId },
      })

      if (!data?.deleteResearchUser?.success) return

      navigate('..')
    },
    [
      deleteResearchUser,
      navigate,
      participantIdConfirm,
      researchParticipantId,
      userId,
    ]
  )

  if (!researchParticipantId) return null

  return (
    <DetailViewCard title="Danger Zone" noPaddings>
      <form onSubmit={onDelete}>
        <div className="px-4 py-6 sm:p-8">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-full">
              <label
                htmlFor="identifier-confirm"
                className="block text-sm font-medium leading-6"
              >
                To delete the User, type{' '}
                <span className="font-mono">{researchParticipantId}</span> into
                the field below to confirm.
              </label>
              <input
                type="text"
                id="identifier-confirm"
                name="identifier-confirm"
                autoComplete="off"
                placeholder={researchParticipantId}
                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6 font-mono mt-2"
                value={participantIdConfirm}
                onChange={({ currentTarget }) =>
                  setParticipantIdConfirm(currentTarget.value.toUpperCase())
                }
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-8">
          <ButtonLink to=".." design="white">
            Cancel
          </ButtonLink>
          <Button
            type="submit"
            disabled={researchParticipantId !== participantIdConfirm}
            design="secondary"
          >
            Delete Research User
          </Button>
        </div>
      </form>
    </DetailViewCard>
  )
}
