import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import type {
  GetIllnessesQuery,
  GetIllnessesQueryVariables,
} from '../GraphQL/graphql'
import { GetIllnessesDocument } from '../GraphQL/graphql'

export const useIllnesses = () => {
  const { data, loading } = useQuery<
    GetIllnessesQuery,
    GetIllnessesQueryVariables
  >(GetIllnessesDocument)

  return useMemo(
    () => ({
      illnesses: data?.getIllnesses.illnesses ?? [],
      illnessesLoading: loading,
    }),
    [data, loading]
  )
}
