import { useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  GetReportsQuery,
  GetReportsQueryVariables,
  GetReportsUpdatesSubscription,
  GetReportsUpdatesSubscriptionVariables,
  InvoiceState,
} from '../GraphQL/graphql'
import {
  GetReportsDocument,
  GetReportsUpdatesDocument,
} from '../GraphQL/graphql'

interface UseReportsOptions {
  invoiceState?: InvoiceState[]
}

export const useReports = (options?: UseReportsOptions) => {
  const { data, loading, subscribeToMore } = useQuery<
    GetReportsQuery,
    GetReportsQueryVariables
  >(GetReportsDocument, {
    variables: {
      invoiceState: options?.invoiceState || [],
    },
  })

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      GetReportsUpdatesSubscription,
      GetReportsUpdatesSubscriptionVariables
    >({
      document: GetReportsUpdatesDocument,
      updateQuery: (prev, { subscriptionData: { data } }) => ({
        ...prev,
        ...data,
        __typename: prev.__typename,
      }),
    })

    return () => unsubscribe()
  }, [subscribeToMore])

  return useMemo(
    () => ({
      reports: data?.getReports.reports ?? [],
      loading,
      error: data?.getReports.error,
    }),
    [data, loading]
  )
}
