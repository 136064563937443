import type { FC } from 'react'
import { useOrderInvoice } from '../Hooks/useOrderInvoice'

interface DownloadInvoiceProps {
  orderId: string
}

export const DownloadInvoice: FC<DownloadInvoiceProps> = ({ orderId }) => {
  const { getInvoice, invoiceLoading } = useOrderInvoice()

  const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    // ToDo: fix bug
    const invoice = await getInvoice({ variables: { orderId } })

    if (invoice.data?.getOrderInvoice.error) {
      alert('Something went wrong, please try again later')
    }

    if (invoice.data?.getOrderInvoice.uri) {
      const anchor = document.createElement('a')
      anchor.href = invoice.data?.getOrderInvoice.uri
      anchor.download = `order-${orderId}.pdf`
      anchor.style.display = 'none'
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    }
  }

  return (
    <button
      type="button"
      className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      onClick={onClick}
      disabled={invoiceLoading}
    >
      {invoiceLoading ? 'Loading...' : 'Download invoice'}
    </button>
  )
}
