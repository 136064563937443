import { PlusIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import type { FC, HTMLAttributes } from 'react'
import { useRef } from 'react'
import type { TreeCriterionObject } from '../GraphQL/graphql'
import { ConversationTreeCriterion } from './ConversationTreeCriterion'
import { ConversationTreeCriterionCreate } from './ConversationTreeCriterionCreate'

type Props = HTMLAttributes<HTMLDivElement> & {
  treeDataId: string
  criteria: TreeCriterionObject[]
  activeId: string
}

export const ConversationTreeCriteria: FC<Props> = ({
  treeDataId,
  criteria,
  activeId,
  className,
  ...args
}) => {
  const newCriterionIdentifierInput = useRef<HTMLInputElement>(null)

  return (
    <div
      className={classNames(
        'min-w-full overflow-hidden overflow-x-auto divide-y divide-gray-200 rounded-lg shadow',
        className
      )}
      {...args}
    >
      <div className="flex items-center justify-between p-4 gap-x-4 sm:gap-x-6 sm:p-6 text-primary-100 bg-primary-700">
        <div className="font-medium text-left">Criterion</div>

        <button
          className="block p-3 -m-3 rounded hover:bg-white/10"
          title="Create new Criterion"
          onClick={() => newCriterionIdentifierInput.current?.focus()}
        >
          <PlusIcon className="w-6 h-6 text-white" aria-hidden="true" />
        </button>
      </div>
      {criteria.map((criterion) => (
        <ConversationTreeCriterion
          key={criterion.id}
          criterion={criterion}
          activeId={activeId}
          treeDataId={treeDataId}
        />
      ))}
      <ConversationTreeCriterionCreate
        treeDataId={treeDataId}
        ref={newCriterionIdentifierInput}
      />
    </div>
  )
}
