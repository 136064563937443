import { LinkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import type { FC } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useSearchParams } from 'react-router-dom'
import type { DicomSeriesFragment } from '../GraphQL/graphql'
import { urlSeriesRendered } from '../Helper/DicomHelper'

type Props = {
  reportId?: string
  allDicomSeries: DicomSeriesFragment[]
  setDicomSeries: (series: DicomSeriesFragment) => void
}

export const CockpitDicomPreview: FC<Props> = ({
  reportId,
  allDicomSeries,
  setDicomSeries,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { i18n } = useTranslation()
  const [dicomImages, setDicomImages] = useState<{
    [x: string]: string
  }>()

  const selectedDicom = useMemo(
    () =>
      allDicomSeries.find(({ id }) => id === searchParams.get('image')) ??
      allDicomSeries[0],
    [allDicomSeries, searchParams]
  )

  useEffect(() => {
    setDicomSeries(selectedDicom)
  }, [selectedDicom, setDicomSeries])

  useEffect(() => {
    const handle = async () =>
      setDicomImages(
        Object.fromEntries(
          await Promise.all(
            allDicomSeries.map(async ({ id }) => [
              id,
              await urlSeriesRendered(id, 300),
            ])
          )
        )
      )

    handle()
  }, [allDicomSeries])

  return (
    <ScrollContainer className="flex gap-2 p-4 overflow-x-auto sm:p-2 flex-nowrap">
      {allDicomSeries?.map(
        ({ id, date, description, modality, linkedReportIds }) => (
          <button
            key={id}
            className={classNames(
              selectedDicom?.id === id && 'active',
              'grid items-center grid-cols-[96px_auto] gap-2',
              'relative flex-none p-0 pr-2 text-sm text-left text-gray-900 border rounded-md shadow group group-active:border-green-500 group-active:bg-gray-50 group-active:ring-1 group-active:hover:ring-2 gap-y-0 bg-gray-50 hover:border-green-500 hover:bg-gray-100 hover:ring-2 ring-green-500 w-60'
            )}
            onClick={() =>
              setSearchParams((params) => ({ ...params, image: id }))
            }
          >
            <div
              className={classNames(
                !dicomImages && 'animate-pulse',
                'bg-black w-24 h-24 row-span-3 overflow-hidden rounded-l-md'
              )}
            >
              <img
                hidden={!dicomImages?.[id]}
                src={dicomImages?.[id]}
                className="object-contain w-24 h-24"
                alt={modality ?? description ?? ''}
              />
            </div>
            <div className="w-full truncate">{modality}</div>
            <div className="w-full truncate">{description}</div>
            <div className="w-full truncate">
              {date &&
                DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED, {
                  locale: i18n.language,
                })}
            </div>

            {reportId && linkedReportIds.includes(reportId) && (
              <div
                className={classNames(
                  'absolute top-1 right-1',
                  'bg-dro-gray',
                  // You can add comments to your styling code.
                  'w-5 h-5',
                  'flex items-center justify-center',
                  'rounded-full cursor-pointer'
                )}
              >
                <LinkIcon className="w-10" />
              </div>
            )}
          </button>
        )
      )}
    </ScrollContainer>
  )
}
