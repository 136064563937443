import { ArrowPathIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import _ from 'lodash'
import type { FC, MouseEventHandler } from 'react'
import { useCallback, useEffect, useState } from 'react'
import type { OnDragEndResponder } from 'react-beautiful-dnd'
import { DragDropContext } from 'react-beautiful-dnd'
import type {
  ConversationTreeDataFragment,
  TreeStatementInput,
  TreeStatementObject,
} from '../GraphQL/graphql'
import { ConversationTreeStatementInputType } from '../GraphQL/graphql'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'
import { ConversationTreeStatementMultiOptionGroup } from './ConversationTreeStatementMultiOptionGroup'

type Props = {
  treeData: ConversationTreeDataFragment
  statement: TreeStatementObject
}

export const ConversationTreeStatementMultiOptions: FC<Props> = ({
  treeData,
  statement: remoteStatement,
}) => {
  const [statement, setStatement] = useState(remoteStatement)
  const [isNewOptionGroupLoading, setIsNewOptionGroupLoading] = useState(false)

  useEffect(() => {
    setStatement(remoteStatement)
  }, [remoteStatement])

  const {
    mutations: {
      upsertConversationTreeStatement: [upsertConversationTreeStatement],
    },
  } = useConversationTreeData()

  const onDragEnd: OnDragEndResponder = useCallback(
    async ({ destination, source }) => {
      if (!destination) return

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return
      }

      const intermediateStatement = _.cloneDeep(statement)

      if (!intermediateStatement?.multiOptions?.length) return

      const sourceOptionsContainer = intermediateStatement.multiOptions.find(
        ({ id }) => id === source.droppableId
      )

      const destinationOptionsContainer =
        intermediateStatement.multiOptions.find(
          ({ id }) => id === destination.droppableId
        )

      if (!sourceOptionsContainer || !destinationOptionsContainer) return

      const [removedOption] = sourceOptionsContainer.options.splice(
        source.index,
        1
      )
      destinationOptionsContainer.options.splice(
        destination.index,
        0,
        removedOption
      )

      setStatement(intermediateStatement)

      await upsertConversationTreeStatement({
        variables: { input: intermediateStatement, treeDataId: treeData.id },
      })
    },
    [statement, treeData, upsertConversationTreeStatement]
  )

  const addNewOptionGroup: MouseEventHandler<HTMLButtonElement> =
    useCallback(async () => {
      const intermediateStatement: TreeStatementInput = _.cloneDeep(statement)

      if (!intermediateStatement) return
      intermediateStatement.multiOptions =
        intermediateStatement?.multiOptions ?? []

      setIsNewOptionGroupLoading(true)

      intermediateStatement.multiOptions.push({
        options: [],
      })

      intermediateStatement.inputType =
        ConversationTreeStatementInputType.Options

      await upsertConversationTreeStatement({
        variables: { input: intermediateStatement, treeDataId: treeData.id },
      })

      setIsNewOptionGroupLoading(false)
    }, [statement, treeData, upsertConversationTreeStatement])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="bg-white divide-y rounded-md shadow divide-primary-400">
        <div className="px-4 py-3 font-semibold text-primary-700">
          Multi Options
        </div>
        {statement.multiOptions?.map((optionGroup, index) => (
          <ConversationTreeStatementMultiOptionGroup
            key={optionGroup.id}
            optionGroup={optionGroup}
            optionGroupIndex={index}
            statement={statement}
            treeData={treeData}
          />
        ))}
        <button
          className={classNames(
            'flex w-full items-center px-4 py-3 gap-x-4 hover:bg-gray-900/5 text-gray-500 italic',
            isNewOptionGroupLoading && 'animate-pulse'
          )}
          onClick={addNewOptionGroup}
        >
          {isNewOptionGroupLoading ? (
            <ArrowPathIcon className="w-6 h-6 animate-spin text-primary-700" />
          ) : (
            <PlusCircleIcon
              className="inline w-6 h-6 text-primary-700"
              title="add empty option"
              aria-hidden="true"
            />
          )}
          New option group
        </button>
      </div>
    </DragDropContext>
  )
}
