import type { FC } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ButtonLink } from '../Components/ButtonLink'
import { DotMenu } from '../Components/DotMenu'
import { Table } from '../Components/Table'
import type { ConversationTreeFragment } from '../GraphQL/graphql'
import { useConversationTrees } from '../Hooks/useConversationTrees'
import type { AppShellComponent } from './AppShell'

export const ConversationTrees: AppShellComponent = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { trees } = useConversationTrees()

  const getMenuItems = useCallback(
    (tree: ConversationTreeFragment) => [
      {
        label: t('Delete'),
        onClick: () => navigate(`/conversation-trees/${tree.id}/delete`),
      },
    ],
    [navigate, t]
  )

  return (
    <Table className="relative">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="text-left">Identifier</Table.HeaderCell>
          <Table.HeaderCell className="text-left">Illness</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {trees?.map((tree) => (
          <Table.Row
            key={tree.id}
            onClick={(e) =>
              !e.isDefaultPrevented() &&
              navigate(`/conversation-trees/${tree.id}`)
            }
            className="cursor-pointer group hover:bg-gray-50"
          >
            <Table.Cell className="text-left text-gray-500">
              {tree.identifier}
            </Table.Cell>
            <Table.Cell className="w-full truncate max-w-0">
              {tree.illness.nameLocales.at(0)?.text}
            </Table.Cell>
            <Table.Cell className="text-left text-gray-500">
              <DotMenu menuItems={getMenuItems(tree)} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

ConversationTrees.Header = (() => {
  const { t } = useTranslation()
  return (
    <>
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
          {t('Conversation Trees')}
        </h1>
      </div>
      <div className="flex mt-6 space-x-3 sm:ml-4 sm:mt-0">
        <ButtonLink to="create" design="secondary">
          {t('Add new Tree')}
        </ButtonLink>
      </div>
    </>
  )
}) satisfies FC
