import Avatar from 'boring-avatars'
import type { FC } from 'react'
import type { UserObject } from '../GraphQL/graphql'

type Props = {
  user?: Pick<
    UserObject,
    'firstname' | 'lastname' | 'isResearch' | 'researchParticipantId'
  >
  size: number
}

export const UserAvatar: FC<Props> = ({ user, size }) => {
  const name =
    user?.isResearch && user.researchParticipantId
      ? user.researchParticipantId
      : `${user?.firstname} ${user?.lastname}`

  return (
    <Avatar
      size={size * 4}
      name={name}
      variant="marble"
      colors={['#047857', '#10b981', '#d1fae5', '#d97706', '#fbbf24']}
    />
  )
}
