import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import _ from 'lodash'
import type { FC, HTMLAttributes } from 'react'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import type { ValueEditableHandler } from '../Components/ValueEditable'
import { ValueEditable } from '../Components/ValueEditable'
import type {
  ConversationTreeDataFragment,
  TreeCriterionObject,
  TreeMultiOptionObject,
  TreeSingleOptionObject,
  TreeStatementObject,
} from '../GraphQL/graphql'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'

type Props = HTMLAttributes<HTMLDivElement> & {
  option: TreeMultiOptionObject | TreeSingleOptionObject
  statement: TreeStatementObject
  treeData: ConversationTreeDataFragment
}

export const CriterionInfo: FC<Props> = ({
  option: { id: optionId, criterionId, score: initialScore },
  statement,
  treeData,
  className,
  ...args
}) => {
  const [criterion, setCriterion] = useState(
    treeData.criteria.find(({ id }) => id === criterionId) ?? null
  )
  const [score, setScore] = useState(initialScore ?? undefined)
  const [isDisabled, setIsDisabled] = useState(true)
  const [criterionQuery, setCriterionQuery] = useState('')

  useEffect(
    () =>
      setCriterion(
        treeData.criteria.find(({ id }) => id === criterionId) ?? null
      ),
    [criterionId, treeData.criteria]
  )

  useEffect(() => setScore(initialScore ?? undefined), [initialScore])

  const filteredCriteria = useMemo(
    () => [
      null,
      ..._.sortBy(treeData.criteria, ({ identifier }) =>
        identifier.toLowerCase()
      ).filter(({ identifier }) =>
        identifier.toLowerCase().includes(criterionQuery.toLowerCase())
      ),
    ],
    [criterionQuery, treeData.criteria]
  )

  const {
    mutations: {
      upsertConversationTreeStatement: [upsertConversationTreeStatement],
    },
  } = useConversationTreeData()

  const updateOption = useCallback(
    (criterion: TreeCriterionObject | null) => {
      setCriterion(criterion)

      const intermediateStatement = _.cloneDeep(statement)

      const allOptions = [
        ...(intermediateStatement.multiOptions?.flatMap(
          ({ options }) => options
        ) ?? []),
        ...(intermediateStatement.singleOptions ?? []),
      ]

      const option = allOptions.find(({ id }) => id === optionId)

      if (!option) return

      option.criterionId = criterion?.id ?? null

      upsertConversationTreeStatement({
        variables: { input: intermediateStatement, treeDataId: treeData.id },
      })
    },
    [optionId, statement, treeData.id, upsertConversationTreeStatement]
  )

  const onScoreChange: ValueEditableHandler = useCallback(
    (value) => {
      if (score === +value) return

      const intermediateStatement = _.cloneDeep(statement)

      const allOptions = [
        ...(intermediateStatement.multiOptions?.flatMap(
          ({ options }) => options
        ) ?? []),
        ...(intermediateStatement.singleOptions ?? []),
      ]

      const intermediateOption = allOptions.find(({ id }) => id === optionId)

      if (!intermediateOption) return

      intermediateOption.score = value === '' ? null : +value

      upsertConversationTreeStatement({
        variables: { input: intermediateStatement, treeDataId: treeData.id },
      })
    },
    [optionId, score, statement, treeData.id, upsertConversationTreeStatement]
  )

  return (
    <div
      className={classNames(className, 'flex items-baseline gap-x-4')}
      {...args}
    >
      <Combobox
        as="div"
        value={criterion}
        onChange={(value) => {
          setIsDisabled(true)
          updateOption(value)
        }}
        disabled={isDisabled}
        nullable
        className="relative flex-auto"
        onDoubleClick={() => setIsDisabled(false)}
      >
        <div
          className={classNames(
            'relative w-full overflow-hidden text-left bg-white border-b rounded focus:outline-none sm:text-sm',
            isDisabled
              ? 'pointer-events-none border-transparent cursor-text'
              : 'border-secondary-700'
          )}
        >
          <Combobox.Input
            className="w-full p-0 pb-px pr-10 text-sm leading-5 text-gray-900 border-none focus:ring-0 placeholder:italic"
            defaultValue={criterion}
            displayValue={(criterion) => criterion?.identifier ?? ''}
            placeholder="No Criterion"
            onChange={(event) => {
              setCriterionQuery(event.target.value)
            }}
            autoComplete="off"
            onKeyDown={({ code }) => {
              switch (code) {
                case 'Escape':
                  setIsDisabled(true)
                  break
              }
            }}
          />
          {!isDisabled && (
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center">
              <ChevronUpDownIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          )}
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setCriterionQuery('')}
        >
          <Combobox.Options
            className={classNames(
              'absolute z-10 w-full mt-px overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
              isDisabled && 'hidden'
            )}
          >
            {filteredCriteria.map((criterion) => (
              <Combobox.Option
                key={criterion?.id ?? 'no-criterion'}
                value={criterion}
                className={classNames(
                  'relative py-2 pl-10 pr-4 text-gray-900 cursor-default select-none ui-active:bg-primary-600 ui-active:text-white',
                  !criterion?.id && 'italic'
                )}
              >
                <span className="block font-normal truncate ui-selected:font-medium">
                  {criterion?.identifier ?? 'No Criterion'}
                </span>
                <span className="absolute inset-y-0 left-0 items-center hidden pl-3 text-primary-600 ui-selected:flex ui-active:text-white">
                  <CheckIcon className="w-5 h-5" aria-hidden="true" />
                </span>
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </Combobox>

      <ValueEditable
        inputMode="numeric"
        className="flex-none w-20 text-sm text-right text-gray-500 placeholder:italic"
        value={score}
        onEditEnd={onScoreChange}
        placeholder="no Score"
        intermediatePattern={/^\d*$/g}
      />
    </div>
  )
}
