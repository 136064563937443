import type { FC } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { AdminApplication } from './GraphQL/graphql'
import { useProfile } from './Hooks/useProfile'
import { AppShell } from './Pages/AppShell'
import { ConversationTreeCreate } from './Pages/ConversationTreeCreate'
import { ConversationTreeDataActivate } from './Pages/ConversationTreeDataActivate'
import { ConversationTreeDataDelete } from './Pages/ConversationTreeDataDelete'
import { ConversationTreeDataDetails } from './Pages/ConversationTreeDataDetails'
import { ConversationTreeDataDuplicate } from './Pages/ConversationTreeDataDuplicate'
import { ConversationTreeDataImport } from './Pages/ConversationTreeDataImport'
import { ConversationTreeDelete } from './Pages/ConversationTreeDelete'
import { ConversationTreeDetails } from './Pages/ConversationTreeDetails'
import { ConversationTrees } from './Pages/ConversationTrees'
import { Dashboard } from './Pages/Dashboard'
import { Loading } from './Pages/Loading'
import { Login } from './Pages/Login'
import { Logout } from './Pages/Logout'
import { OrderDetails } from './Pages/OrderDetails'
import { Orders } from './Pages/Orders'
import { OrdersVoided } from './Pages/OrdersVoided'
import { ReportDetails } from './Pages/ReportDetails'
import { ReportEditor } from './Pages/ReportEditor'
import { ReportEditorRelease } from './Pages/ReportEditorRelease'
import { Reports } from './Pages/Reports'
import { ReportsCancelled } from './Pages/ReportsCancelled'
import { ResearchUserCreate } from './Pages/ResearchUserCreate'
import { ResearchUserDetails } from './Pages/ResearchUserDetails'
import { UserDetails } from './Pages/UserDetails'
import { Users } from './Pages/Users'
import './i18n'

export const App: FC = () => {
  const { profile, profileLoading } = useProfile()

  if (profileLoading) {
    return <Loading />
  }

  if (!profile?.isComplete || !profile.rights.adminApplications.length) {
    return <Login />
  }

  const applicationRights = profile.rights.adminApplications

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_PATH || ''}>
      <Routes>
        <Route path="/dashboard" element={<AppShell Child={Dashboard} />} />
        {applicationRights.includes(AdminApplication.Users) && (
          <>
            <Route path="/users">
              <Route index element={<AppShell Child={Users} />} />
              <Route
                path=":userId"
                element={<AppShell Child={UserDetails} />}
              />
            </Route>
            <Route path="/research-users">
              <Route index element={<Navigate replace to="/users" />} />
              <Route
                path="create"
                element={<AppShell Child={ResearchUserCreate} />}
              />
              <Route
                path=":userId"
                element={<AppShell Child={ResearchUserDetails} />}
              />
            </Route>
          </>
        )}
        {applicationRights.includes(AdminApplication.ConversationTrees) && (
          <Route path="/conversation-trees">
            <Route index element={<AppShell Child={ConversationTrees} />} />
            <Route
              path="create"
              element={<AppShell Child={ConversationTreeCreate} />}
            />
            <Route path=":treeId">
              <Route
                index
                element={<AppShell Child={ConversationTreeDetails} />}
              />
              <Route
                path="delete"
                element={<AppShell Child={ConversationTreeDelete} />}
              />
              <Route
                path="import"
                element={<AppShell Child={ConversationTreeDataImport} />}
              />
              <Route path=":treeDataId">
                <Route
                  index
                  element={<AppShell Child={ConversationTreeDataDetails} />}
                />
                <Route
                  path="duplicate"
                  element={<AppShell Child={ConversationTreeDataDuplicate} />}
                />
                <Route
                  path="delete"
                  element={<AppShell Child={ConversationTreeDataDelete} />}
                />
                <Route
                  path="activate"
                  element={<AppShell Child={ConversationTreeDataActivate} />}
                />
              </Route>
            </Route>
          </Route>
        )}
        {applicationRights.includes(AdminApplication.ReportEditor) && (
          <Route path="/reporteditor/">
            <Route index element={<AppShell Child={ReportEditor} />} />
            <Route
              path=":reportId?"
              element={<AppShell Child={ReportEditor} />}
            />
            <Route path=":reportId">
              <Route
                path="release"
                element={<AppShell Child={ReportEditorRelease} />}
              />
            </Route>
          </Route>
        )}
        {applicationRights.includes(AdminApplication.Reports) && (
          <Route path="/reports/">
            <Route index element={<AppShell Child={Reports} />} />
            <Route
              path="voided"
              element={<AppShell Child={ReportsCancelled} />}
            />
            <Route
              path=":reportId?"
              element={<AppShell Child={ReportDetails} />}
            />
          </Route>
        )}
        {applicationRights.includes(AdminApplication.Orders) && (
          <Route path="/orders/">
            <Route index element={<AppShell Child={Orders} />} />
            <Route path="voided" element={<AppShell Child={OrdersVoided} />} />
            <Route
              path=":orderId?"
              element={<AppShell Child={OrderDetails} />}
            />
          </Route>
        )}
        <Route path="/logout" element={<Logout />} />
        <Route path="/" element={<Navigate replace to="/dashboard" />} />
        <Route path="*" element={<AppShell Child={Dashboard} />} />
      </Routes>
    </BrowserRouter>
  )
}
