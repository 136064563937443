import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import type {
  GetReportsQuery,
  GetReportsQueryVariables,
} from '../GraphQL/graphql'
import { GetReportsDocument } from '../GraphQL/graphql'

type ReportParams = {
  reportId?: string
}

export const useParamReport = () => {
  const { reportId = -1 } = useParams<ReportParams>()

  const { data, loading } = useQuery<GetReportsQuery, GetReportsQueryVariables>(
    GetReportsDocument
  )

  const reports = useMemo(() => data?.getReports.reports, [data])

  const report = useMemo(
    () => reports?.find(({ id }) => id === reportId),
    [reportId, reports]
  )

  return useMemo(() => ({ report, reportLoading: loading }), [loading, report])
}
