import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  AnnotationError,
  GetImageAnnotationsQuery,
  GetImageAnnotationsQueryVariables,
  ImageAnnotationFragment,
} from '../GraphQL/graphql'
import {
  GetImageAnnotationsDocument,
  GetImageAnnotationsUpdatesDocument,
} from '../GraphQL/graphql'

export type UseInstanceAnnotationsResponse = {
  instanceAnnotations: ImageAnnotationFragment[]
  instanceAnnotationsLoading: boolean
  instanceAnnotationsError?: AnnotationError
}

export const useInstanceAnnotations = (
  instanceId?: string
): UseInstanceAnnotationsResponse => {
  const [getImageAnnotations, { data, loading, subscribeToMore }] =
    useLazyQuery<GetImageAnnotationsQuery, GetImageAnnotationsQueryVariables>(
      GetImageAnnotationsDocument
    )

  useEffect(() => {
    instanceId && getImageAnnotations({ variables: { instanceId } })
  }, [data, getImageAnnotations, loading, instanceId])

  useEffect(() => {
    if (!instanceId) {
      return
    }
    const unsubscribe = subscribeToMore({
      document: GetImageAnnotationsUpdatesDocument,
      variables: { instanceId },
      updateQuery: (_, options) => {
        return options.subscriptionData.data
      },
    })
    return () => unsubscribe()
  }, [instanceId, subscribeToMore])

  return useMemo(
    () => ({
      instanceAnnotations:
        instanceId && data?.getImageAnnotations?.annotations
          ? data?.getImageAnnotations?.annotations
          : [],
      instanceAnnotationsLoading: loading,
      instanceAnnotationsError: data?.getImageAnnotations?.error ?? undefined,
    }),
    [
      data?.getImageAnnotations?.annotations,
      data?.getImageAnnotations?.error,
      instanceId,
      loading,
    ]
  )
}
