import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  DicomPatientError,
  DicomPatientFragment,
  GetDicomPatientQuery,
  GetDicomPatientQueryVariables,
} from '../GraphQL/graphql'
import {
  GetDicomPatientDocument,
  GetDicomPatientUpdateDocument,
} from '../GraphQL/graphql'

export type UseDicomPatientResponse = {
  dicomPatient?: DicomPatientFragment
  dicomPatientError?: DicomPatientError
  dicomPatientLoading: boolean
}

export const useDicomPatient = (userId?: string): UseDicomPatientResponse => {
  const [getDicomPatient, { data, loading, subscribeToMore }] = useLazyQuery<
    GetDicomPatientQuery,
    GetDicomPatientQueryVariables
  >(GetDicomPatientDocument)

  useEffect(() => {
    getDicomPatient({ variables: { userId } })
  }, [getDicomPatient, userId])

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: GetDicomPatientUpdateDocument,
      variables: { userId },
      updateQuery: (_, options) => {
        return options.subscriptionData.data
      },
    })
    return () => unsubscribe()
  }, [subscribeToMore, userId])

  return useMemo(
    () => ({
      dicomPatient: data?.getDicomPatient.dicomPatient ?? undefined,
      dicomPatientError: data?.getDicomPatient.error ?? undefined,
      dicomPatientLoading: loading,
    }),
    [data, loading]
  )
}
