import classNames from 'classnames'
import type { FC, PropsWithChildren } from 'react'
import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import type { ButtonDesign } from './Button'
import { designClasses } from './Button'

type Props = LinkProps &
  PropsWithChildren & {
    design?: ButtonDesign
  }

export const ButtonLink: FC<Props> = ({
  children,
  design = 'primary',
  className,
  ...args
}) => {
  return (
    <Link
      className={classNames(
        designClasses[design],
        className,
        'inline-flex items-center px-3 py-2 text-sm font-semibold rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
      )}
      {...args}
    >
      {children}
    </Link>
  )
}
