import classNames from 'classnames'
import MarkdownIt from 'markdown-it'
import type { FC } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ButtonLink } from '../Components/ButtonLink'
import { datetimeToString } from '../Helper/TextHelper'
import { useParamReport } from '../Hooks/useParamReport'
import { useReport } from '../Hooks/useReport'
import { CockpitPatientInformation } from '../Modules/CockpitPatientInformation'
import type { AppShellComponent } from './AppShell'
import { Loading } from './Loading'
import { LoadingError } from './LoadingError'

type ReportDetailsParams = {
  reportId: string
}

const mdParser = new MarkdownIt()

export const ReportDetails: AppShellComponent = () => {
  // const { t } = useTranslation()
  const { reportId } = useParams<ReportDetailsParams>()

  const { report, reportLoading } = useReport(reportId)

  const html = useMemo(
    () => (report?.text ? mdParser.render(report.text) : undefined),
    [report]
  )

  if (reportLoading) {
    return <Loading />
  }

  if (!report || !html) {
    return <LoadingError />
  }

  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 md:col-span-1 space-y-4 top-4">
          <section>
            <div className=" bg-white shadow sm:rounded-lg">
              <CockpitPatientInformation title="Patient" user={report.user} />
            </div>
          </section>
        </div>
        {report.reviewer && (
          <div className="col-span-2 md:col-span-1 space-y-4 top-4">
            <section>
              <div className="bg-white shadow sm:rounded-lg">
                <CockpitPatientInformation
                  title="Doctor"
                  user={report.reviewer}
                />
              </div>
            </section>
          </div>
        )}
        <div className="col-span-2 overflow-hidden bg-white shadow sm:rounded-lg isolate">
          <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              Bericht
            </h2>
          </div>
          <div className="flex sm:space-x-6 px-4 py-5 text-gray-900">
            <div dangerouslySetInnerHTML={{ __html: html }} className="prose" />
          </div>
        </div>
      </div>
    </div>
  )
}

ReportDetails.Header = (() => {
  const { t } = useTranslation()
  const { report } = useParamReport()
  return (
    <>
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
          {t('Report')}
        </h1>
        {report && (
          <div
            className={classNames(
              '-mt-1',
              !report?.released && 'font-bold text-red-500'
            )}
          >
            {report.released
              ? 'released: ' + datetimeToString(report.releaseTimestamp)
              : 'unreleased'}
          </div>
        )}
      </div>
      {report?.conversationSession && (
        <div className="flex mt-6 space-x-3 sm:ml-4 sm:mt-0">
          <ButtonLink
            design="white"
            target="_blank"
            rel="noopener noreferrer"
            to={`${process.env.REACT_APP_WEB_URI}/anamnesis/${report.conversationSession.id}`}
          >
            Anamnesis
          </ButtonLink>
          <ButtonLink
            design="white"
            target="_blank"
            rel="noopener noreferrer"
            to={`${process.env.REACT_APP_WEB_URI}/chat/archive/${report.id}`}
          >
            Chat
          </ButtonLink>
        </div>
      )}
    </>
  )
}) satisfies FC
