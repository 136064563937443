import type { FC } from 'react'
import type { CardProps } from './Card'
import { Card } from './Card'

type Props = {
  cards: CardProps[]
}

export const CardGrid: FC<Props> = ({ cards }) => {
  return (
    <div className="grid grid-cols-1 gap-5 mt-2 sm:grid-cols-2 lg:grid-cols-3">
      {cards.map((card) => (
        <Card key={card.name} {...card} />
      ))}
    </div>
  )
}
