import { useMutation } from '@apollo/client'
import { BanknotesIcon, PlusIcon, UserIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import type { FC } from 'react'
import { useMemo } from 'react'
// import { useParamOrder } from '../Hooks/useParamOrder'
import { useParams } from 'react-router-dom'
import { Button } from '../Components/Button'
import type { CardProps } from '../Components/Card'
import { CardStyle } from '../Components/Card'
import { CardGrid } from '../Components/CardGrid'
import { DetailFullViewCard } from '../Components/DetailFullViewCard'
import { DetailFullViewCardSection } from '../Components/DetailFullViewCardSection'
import { DownloadInvoice } from '../Components/DownloadInvoice'
import type {
  CancelOrderReportMutation,
  CancelOrderReportMutationVariables,
} from '../GraphQL/graphql'
import {
  CancelOrderReportDocument,
  InvoiceParty,
  InvoiceState,
} from '../GraphQL/graphql'
import { moneyToString } from '../Helper/TextHelper'
import { useOrder } from '../Hooks/useOrder'
import { useParamOrder } from '../Hooks/useParamOrder'
import type { AppShellComponent } from './AppShell'
import { Loading } from './Loading'
import { LoadingError } from './LoadingError'

type OrderDetailsParams = {
  orderId: string
}

export const OrderDetails: AppShellComponent = () => {
  // const { t } = useTranslation()
  // const navigate = useNavigate()

  const { orderId } = useParams<OrderDetailsParams>()

  const { order, loading, error } = useOrder(orderId)

  const patientCards = useMemo((): CardProps[] => {
    const cards: CardProps[] = []

    cards.push({
      name: 'Patient Total',
      Icon: UserIcon,
      info: order?.summary.patient.values
        .map(({ total, currency }) => moneyToString(total, currency))
        .join(' | '),
      style: CardStyle.NEUTRAL,
    })

    cards.push({
      name: 'Patient Paid',
      Icon: UserIcon,
      info: order?.summary.patient.values
        .map(({ paid, currency }) => moneyToString(paid, currency))
        .join(' | '),
      style: CardStyle.NEUTRAL,
    })

    switch (order?.summary.patient.state) {
      case InvoiceState.Created:
      case InvoiceState.PartiallyPaid:
        cards.push({
          name: 'Patient Payment Incomplete',
          Icon: BanknotesIcon,
          style: CardStyle.INFO,
        })
        break
      case InvoiceState.Paid:
        cards.push({
          name: 'Patient Payment Complete',
          Icon: BanknotesIcon,
          style: CardStyle.SUCCESS,
        })
        break
      case InvoiceState.PartiallyVoid:
        cards.push({
          name: 'Patient Cancellation Incomplete',
          Icon: BanknotesIcon,
          style: CardStyle.DANGER,
        })
        break
      case InvoiceState.Void:
        cards.push({
          name: 'Patient Cancellation Complete',
          Icon: BanknotesIcon,
          style: CardStyle.NEUTRAL,
        })
        break
      default:
        break
    }
    return cards
  }, [order])

  const reviewerCards = useMemo((): CardProps[] => {
    const cards: CardProps[] = []

    cards.push({
      name: 'Reviewer Gets',
      Icon: PlusIcon,
      info: order?.summary.reviewer.values
        .map(({ total, currency }) => moneyToString(total * -1, currency))
        .join(' | '),
      style: CardStyle.NEUTRAL,
    })

    cards.push({
      name: 'Reviewer Got',
      Icon: PlusIcon,
      info: order?.summary.reviewer.values
        .map(({ paid, currency }) => moneyToString(paid * -1, currency))
        .join(' | '),
      style: CardStyle.NEUTRAL,
    })

    switch (order?.summary.reviewer.state) {
      case InvoiceState.Created:
      case InvoiceState.PartiallyPaid:
        cards.push({
          name: 'Reviewer Payment Incomplete',
          Icon: BanknotesIcon,
          style: CardStyle.INFO,
        })
        break
      case InvoiceState.Paid:
        cards.push({
          name: 'Reviewer Payment Complete',
          Icon: BanknotesIcon,
          style: CardStyle.SUCCESS,
        })
        break
      case InvoiceState.PartiallyVoid:
        cards.push({
          name: 'Reviewer Cancellation Incomplete',
          Icon: BanknotesIcon,
          style: CardStyle.DANGER,
        })
        break
      case InvoiceState.Void:
        cards.push({
          name: 'Reviewer Cancellation Complete',
          Icon: BanknotesIcon,
          style: CardStyle.NEUTRAL,
        })
        break
      default:
        break
    }
    return cards
  }, [order])

  const docrobinCards = useMemo((): CardProps[] => {
    const cards: CardProps[] = []

    cards.push({
      name: 'DocRobin Gets',
      Icon: PlusIcon,
      info: order?.summary.docrobin.values
        .map(({ total, currency }) => moneyToString(total * -1, currency))
        .join(' | '),
      style: CardStyle.NEUTRAL,
    })

    cards.push({
      name: 'DocRobin Got',
      Icon: PlusIcon,
      info: order?.summary.docrobin.values
        .map(({ paid, currency }) => moneyToString(paid * -1, currency))
        .join(' | '),
      style: CardStyle.NEUTRAL,
    })

    switch (order?.summary.patient.state) {
      case InvoiceState.Created:
      case InvoiceState.PartiallyPaid:
        cards.push({
          name: 'DocRobin Payment Incomplete',
          Icon: BanknotesIcon,
          style: CardStyle.INFO,
        })
        break
      case InvoiceState.Paid:
        cards.push({
          name: 'DocRobin Payment Complete',
          Icon: BanknotesIcon,
          style: CardStyle.SUCCESS,
        })
        break
      case InvoiceState.PartiallyVoid:
        cards.push({
          name: 'DocRobin Cancellation Incomplete',
          Icon: BanknotesIcon,
          style: CardStyle.DANGER,
        })
        break
      case InvoiceState.Void:
        cards.push({
          name: 'Patient Cancellation Complete',
          Icon: BanknotesIcon,
          style: CardStyle.NEUTRAL,
        })
        break
      default:
        break
    }
    return cards
  }, [order])

  const grouped = useMemo(
    () => _.groupBy(order?.invoices, (i) => i.buyer),
    [order]
  )

  if (loading) {
    return <Loading />
  } else if (error || !order) {
    return <LoadingError />
  }

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
      <div className="space-y-10">
        <CardGrid cards={patientCards} />
        <CardGrid cards={docrobinCards} />
        <CardGrid cards={reviewerCards} />
      </div>

      <DetailFullViewCard title={'Patient'}>
        <DetailFullViewCardSection title={'Information'}>
          <ul className="divide-y divide-gray-100">
            <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
              {order.report.user.firstname}, {order.report.user.firstname}
            </li>
            <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
              {order.report.user.email}
            </li>
            <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
              {order.report.user.phone}
            </li>
          </ul>
        </DetailFullViewCardSection>
        {grouped[InvoiceParty.Patient]?.map((invoice) => {
          return (
            <DetailFullViewCardSection
              key={invoice.id}
              title={invoice.invoiceNumber ?? ''}
            >
              <ul className="divide-y divide-gray-100">
                <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
                  <table>
                    <tbody>
                      {invoice.items.map((item) => {
                        return (
                          <tr>
                            <td>{item.amount}</td>
                            <td>{item.title}</td>
                            <td>
                              {moneyToString(item.total, invoice.currency)}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </li>
                <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
                  total: {moneyToString(invoice.total, invoice.currency)} (vat:
                  {invoice.vat && moneyToString(invoice.vat, invoice.currency)})
                </li>
                <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
                  paid: {moneyToString(invoice.paid ?? 0, invoice.currency)}
                </li>
              </ul>
            </DetailFullViewCardSection>
          )
        })}
      </DetailFullViewCard>

      <DetailFullViewCard title={'Reviewer'}>
        <DetailFullViewCardSection title={'Information'}>
          <ul className="divide-y divide-gray-100">
            <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
              {order.report.reviewer?.firstname},{' '}
              {order.report.reviewer?.firstname}
            </li>
            <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
              {order.report.reviewer?.email}
            </li>
            <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
              {order.report.reviewer?.phone}
            </li>
          </ul>
        </DetailFullViewCardSection>
        {grouped[InvoiceParty.Reviewer].map((invoice) => {
          return (
            <DetailFullViewCardSection title={invoice.invoiceNumber ?? ''}>
              <ul className="divide-y divide-gray-100">
                <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
                  <table>
                    <tbody>
                      {invoice.items.map((item) => {
                        return (
                          <tr>
                            <td>{item.amount}</td>
                            <td>{item.title}</td>
                            <td>
                              {moneyToString(item.total, invoice.currency)}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </li>
                <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
                  total: {moneyToString(invoice.total, invoice.currency)} (vat:
                  {invoice.vat && moneyToString(invoice.vat, invoice.currency)})
                </li>
                <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
                  paid: {moneyToString(invoice.paid ?? 0, invoice.currency)}
                </li>
              </ul>
            </DetailFullViewCardSection>
          )
        })}
      </DetailFullViewCard>

      {grouped[InvoiceParty.Docrobin]?.length && (
        <DetailFullViewCard title={'DocRobin'}>
          {grouped[InvoiceParty.Docrobin]?.map((invoice) => {
            return (
              <DetailFullViewCardSection
                key={invoice.id}
                title={invoice.invoiceNumber ?? ''}
              >
                <ul className="divide-y divide-gray-100">
                  <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
                    <table>
                      <tbody>
                        {invoice.items.map((item) => {
                          return (
                            <tr>
                              <td>{item.amount}</td>
                              <td>{item.title}</td>
                              <td>
                                {moneyToString(item.total, invoice.currency)}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </li>
                  <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
                    total: {moneyToString(invoice.total, invoice.currency)}{' '}
                    (vat:
                    {invoice.vat &&
                      moneyToString(invoice.vat, invoice.currency)}
                    )
                  </li>
                  <li className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
                    paid: {moneyToString(invoice.paid ?? 0, invoice.currency)}
                  </li>
                </ul>
              </DetailFullViewCardSection>
            )
          })}
        </DetailFullViewCard>
      )}
    </div>
  )
}

OrderDetails.Header = (() => {
  const { order } = useParamOrder()
  const [cancelOrderReport, { loading }] = useMutation<
    CancelOrderReportMutation,
    CancelOrderReportMutationVariables
  >(CancelOrderReportDocument)

  if (!order) {
    return null
  }

  return (
    <>
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
          {order.orderNumber}
        </h1>
      </div>
      <div className="flex mt-6 space-x-3 sm:ml-4 sm:mt-0">
        {/* <Button design="primary" onClick={() => deleteInactiveEmptyUsers()}>
          Cancel Report
        </Button> */}
        <DownloadInvoice orderId={order.id} />
        <Button
          design="white"
          onClick={() =>
            cancelOrderReport({ variables: { orderId: order.id } })
          }
          disabled={
            order.summary.patient.state === InvoiceState.Void &&
            order.summary.docrobin.state === InvoiceState.Void
          }
        >
          {loading ? 'Loading...' : 'Cancel Order'}
        </Button>
      </div>
    </>
  )
}) satisfies FC
