import { Disclosure } from '@headlessui/react'
import { PlayIcon, StopIcon } from '@heroicons/react/20/solid'
import {
  ArrowPathIcon,
  ChevronDoubleRightIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import _ from 'lodash'
import type { FC, MouseEventHandler } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { DeleteButton } from '../Components/DeleteButton'
import { StatementTypeSelector } from '../Components/StatementTypeSelector'
import type { ValueEditableHandler } from '../Components/ValueEditable'
import { ValueEditable } from '../Components/ValueEditable'
import type { TreeStatementObject } from '../GraphQL/graphql'
import {
  ConversationTreeStatementInputType,
  LanguageCode,
} from '../GraphQL/graphql'
import { getVideoUrl } from '../Helper/VideoHelper'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'
import { ConversationTreeElementNext } from './ConversationTreeElementNext'
import { ConversationTreeStatementMultiOptions } from './ConversationTreeStatementMultiOptions'
import { ConversationTreeStatementSingleOptions } from './ConversationTreeStatementSingleOptions'

type Props = {
  treeDataId: string
  statement: TreeStatementObject
  activeId: string
  videoPath: string
}

export const ConversationTreeStatement: FC<Props> = ({
  treeDataId,
  statement: remoteStatement,
  activeId,
  videoPath,
}) => {
  const [statement, setStatement] = useState(remoteStatement)
  const [statementType, setStatementType] = useState(remoteStatement.inputType)
  const [isDeleting, setIsDeleting] = useState(false)

  useEffect(() => {
    setStatement(remoteStatement)
    setStatementType(remoteStatement.inputType)
    setIsDeleting(false)
  }, [remoteStatement])

  const {
    treeData,
    validation,
    mutations: {
      deleteConversationTreeElement: [deleteConversationTreeElement],
      upsertConversationTreeStatement: [upsertConversationTreeStatement],
    },
  } = useConversationTreeData(treeDataId)

  const onIdentifierChange: ValueEditableHandler = useCallback(
    (value) => {
      if (statement.identifier === value) return

      const intermediateStatement = _.cloneDeep(statement)

      intermediateStatement.identifier = value

      setStatement(intermediateStatement)

      upsertConversationTreeStatement({
        variables: { input: intermediateStatement, treeDataId },
      })
    },
    [statement, treeDataId, upsertConversationTreeStatement]
  )

  const onNameChange: ValueEditableHandler = useCallback(
    (value) => {
      if (statement.text.at(0)?.text === value) return

      const intermediateStatement = _.cloneDeep(statement)

      const translation = intermediateStatement.text.at(0)

      if (translation) {
        translation.text = value
      } else {
        intermediateStatement.text.push({
          languageCode: LanguageCode.DeDe,
          text: value,
        })
      }

      setStatement(intermediateStatement)

      upsertConversationTreeStatement({
        variables: { input: intermediateStatement, treeDataId },
      })
    },
    [statement, treeDataId, upsertConversationTreeStatement]
  )

  const onDelete: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setIsDeleting(true)

    deleteConversationTreeElement({
      variables: { treeDataId, elementId: statement.id },
    })
  }, [deleteConversationTreeElement, statement.id, treeDataId])

  const nextUrl = statement.next?.nextId && `#${statement.next?.nextId}`
  const isStartPoint = treeData?.settings.startId === statement.id
  const isEndPoint = !(
    statement.next ||
    statement.freeInput ||
    statement.singleOptions ||
    statement.multiOptions
  )

  return (
    <Disclosure
      as="div"
      id={statement.id}
      className={classNames('bg-white', isDeleting && 'animate-pulse')}
    >
      <div
        className={classNames(
          'flex items-center p-4 gap-x-4 sm:gap-x-6 sm:px-6',
          statement.id === activeId && 'bg-primary-700/20',
          validation?.unusedElements.includes(statement.id) && 'opacity-50'
        )}
      >
        <Disclosure.Button className="flex-none">
          {isDeleting || !treeData || !statement ? (
            <ArrowPathIcon className="w-12 h-12 p-3 -m-3 animate-spin" />
          ) : (
            <ChevronRightIcon className="w-12 h-12 p-3 -m-3 transition ui-open:rotate-90" />
          )}
        </Disclosure.Button>
        <div className="flex-auto space-y-2 overflow-hidden">
          <div className="flex items-center gap-x-2">
            {isStartPoint && (
              <PlayIcon
                className="flex-none w-5 h-5 text-green-700"
                title="This is the start point of the conversation"
              />
            )}
            <ValueEditable
              className="flex-shrink w-full truncate"
              value={statement.text.at(0)?.text}
              onEditEnd={onNameChange}
              placeholder="No Display Text"
            />
          </div>
          <div className="flex items-center gap-x-2">
            <ValueEditable
              className="w-1/2 text-sm text-gray-500"
              invalid={
                validation?.invalidElementIdentifiers.includes(statement.id) ||
                validation?.duplicateElementIdentifiers.includes(statement.id)
              }
              value={statement.identifier}
              onEditEnd={onIdentifierChange}
              intermediatePattern={/^\S*$/g}
            />
          </div>
        </div>
        <div className="flex items-center flex-none gap-x-4 sm:gap-x-6">
          {nextUrl && (
            <Link
              to={nextUrl}
              className="block p-3 -m-3 rounded hover:bg-gray-900/10"
              title="On Next"
            >
              <ChevronDoubleRightIcon
                className="w-6 h-6 text-primary-700"
                aria-hidden="true"
              />
            </Link>
          )}
          {isEndPoint && (
            <StopIcon
              className="w-5 h-5 text-red-700"
              title="This is an end point of the conversation"
            />
          )}
        </div>
      </div>
      {treeData && (
        <Disclosure.Panel className="flex flex-col gap-6 px-6 py-4 text-sm bg-gray-100 sm:px-6 whitespace-nowrap sm:flex-row">
          <div className="flex-auto min-w-0 space-y-4">
            <StatementTypeSelector
              statementType={statementType}
              setStatementType={setStatementType}
              isDisabled={
                statementType === ConversationTreeStatementInputType.Options &&
                (!!statement.singleOptions?.length ||
                  !!statement.multiOptions?.length)
              }
            />

            {statementType === ConversationTreeStatementInputType.None && (
              <ConversationTreeElementNext
                element={statement}
                nextKey={'next'}
                treeData={treeData}
                statementType={statementType}
              />
            )}

            {statementType === ConversationTreeStatementInputType.Options && (
              <>
                <ConversationTreeStatementSingleOptions
                  statement={statement}
                  treeData={treeData}
                />
                <ConversationTreeStatementMultiOptions
                  statement={statement}
                  treeData={treeData}
                />
              </>
            )}

            {statementType === ConversationTreeStatementInputType.Free && (
              <>
                <ConversationTreeElementNext
                  element={statement}
                  nextKey={'next'}
                  treeData={treeData}
                  statementType={statementType}
                />
                <div className="overflow-hidden bg-white divide-y divide-gray-100 rounded-md shadow">
                  <div className="px-4 py-3 font-semibold text-primary-700">
                    Free Input
                  </div>
                  {statement.freeInput ? (
                    <div className="relative flex justify-between px-4 py-3 gap-x-4">
                      <div className="flex-auto min-w-0">
                        <p className="text-sm font-semibold leading-6 truncate">
                          {statement.freeInput.criterionId}
                        </p>
                        <p className="flex mt-1 text-xs leading-5 text-gray-500 truncate">
                          {statement.freeInput.type}
                        </p>
                      </div>
                      {statement.freeInput.criterionId && (
                        <Link
                          to={`#${statement.freeInput.criterionId}`}
                          className="flex-none p-3 rounded hover:bg-gray-900/10"
                        >
                          <ChevronDoubleRightIcon
                            className="w-6 h-6 text-primary-700"
                            aria-hidden="true"
                          />
                        </Link>
                      )}
                    </div>
                  ) : (
                    <div className="px-4 py-3 italic text-gray-500">
                      none available
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="flex-shrink w-full space-y-4 sm:w-64 md:w-72">
            <video
              playsInline
              controls
              src={getVideoUrl(
                videoPath,
                statement.identifier,
                statement.text.at(0)?.languageCode.substring(0, 2)
              )}
            />
            <div className="bg-white divide-y rounded-md shadow divide-primary-400">
              <div className="px-4 py-3 font-semibold text-primary-700">
                Statement Options
              </div>
              <div className="relative flex justify-between px-4 py-3 gap-x-4">
                <div className="flex-auto max-w-full space-y-2">
                  <div className="flex items-center justify-between gap-x-4">
                    <div className="truncate">Delete Statement</div>
                    <DeleteButton className="flex-none" onDelete={onDelete} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Disclosure.Panel>
      )}
    </Disclosure>
  )
}
