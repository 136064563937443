import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { WifiIcon } from '@heroicons/react/24/outline'
import type { AppShellComponent } from './AppShell'

export const LoadingError: AppShellComponent = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full gap-8 font-semibold">
      <WifiIcon className="max-w-[50vmin] max-h-[50vmin] w-52 h-w-52 text-gray-900/10" />

      <div className="flex p-4 border border-red-400 rounded-md bg-red-50">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="w-8 h-8 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <div className="text-xl font-semibold text-red-800">
            Error while loading
          </div>
          <div className="mt-2 text-red-700">
            <p>
              Please try to reload the page. If the error persists, please
              contact support.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
