import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  CancelOrderReportMutation,
  CancelOrderReportMutationVariables,
  GetOrderQuery,
  GetOrderQueryVariables,
  GetOrderUpdatesSubscription,
  GetOrderUpdatesSubscriptionVariables,
} from '../GraphQL/graphql'
import {
  CancelOrderReportDocument,
  GetOrderDocument,
  GetOrderUpdatesDocument,
} from '../GraphQL/graphql'

export const useOrder = (orderId?: string) => {
  const [getOrder, { data, loading, subscribeToMore }] = useLazyQuery<
    GetOrderQuery,
    GetOrderQueryVariables
  >(GetOrderDocument)

  useEffect(() => {
    orderId && getOrder({ variables: { orderId } })
  }, [getOrder, orderId])

  useEffect(() => {
    if (!orderId) {
      return
    }
    const unsubscribe = subscribeToMore<
      GetOrderUpdatesSubscription,
      GetOrderUpdatesSubscriptionVariables
    >({
      variables: { orderId },
      document: GetOrderUpdatesDocument,
      updateQuery: (prev, { subscriptionData: { data } }) => ({
        ...prev,
        ...data,
        __typename: prev.__typename,
      }),
    })
    return () => unsubscribe()
  }, [orderId, subscribeToMore])

  const cancelOrderReport = useMutation<
    CancelOrderReportMutation,
    CancelOrderReportMutationVariables
  >(CancelOrderReportDocument, { variables: { orderId } })

  const mutations = useMemo(
    () => ({
      cancelOrderReport,
    }),
    [cancelOrderReport]
  )

  return useMemo(
    () => ({
      order: orderId ? data?.getOrder.order ?? undefined : undefined,
      error: orderId ? data?.getOrder.error ?? undefined : undefined,
      loading: orderId ? loading : false,
      mutations,
    }),
    [data, loading, mutations, orderId]
  )
}
