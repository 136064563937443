import { ArrowPathIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import type { ForwardRefExoticComponent, RefAttributes } from 'react'
import { forwardRef, useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import type { ValueEditableHandler } from '../Components/ValueEditable'
import { ValueEditable } from '../Components/ValueEditable'
import type { TreeCheckInput } from '../GraphQL/graphql'
import { IDENTIFIER_PATTERN_INTERMEDIATE } from '../Helper/IdentifierHelper'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'

type Props = RefAttributes<HTMLInputElement> & {
  treeDataId: string
}

export const ConversationTreeCheckCreate: ForwardRefExoticComponent<Props> =
  forwardRef(({ treeDataId }, ref) => {
    const location = useLocation()

    const [identifier, setIdentifier] = useState('')

    const {
      mutations: {
        upsertConversationTreeCheck: [upsertConversationTreeCheck, { loading }],
      },
    } = useConversationTreeData()

    const onIdentifierChange: ValueEditableHandler = useCallback(
      async (value) => {
        if (!value) return

        setIdentifier(value)

        const intermediateCheck: TreeCheckInput = {
          identifier: value,
          validator: '',
        }

        const { data } = await upsertConversationTreeCheck({
          variables: { input: intermediateCheck, treeDataId },
        })

        const newCheck = data?.upsertConversationTreeCheck.check

        if (!newCheck) return

        location.hash = `#${newCheck.id}`
      },
      [location, treeDataId, upsertConversationTreeCheck]
    )

    return (
      <div id="check-create" className="bg-white">
        <div className="flex items-center p-4 gap-x-4 sm:gap-x-6 sm:px-6">
          {loading ? (
            <>
              <div className="flex-none">
                <ArrowPathIcon className="w-12 h-12 p-3 -m-3 animate-spin text-primary-700" />
              </div>
              <div className="flex-auto space-y-2 overflow-hidden">
                <div className="flex items-center gap-x-2">
                  Creating new Check...
                </div>
                <div className="flex items-center gap-x-2">
                  <span className="w-1/2 text-sm text-gray-500">
                    {identifier}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex-none">
                <PlusCircleIcon className="w-12 h-12 p-3 -m-3 text-primary-700" />
              </div>
              <div className="flex-auto space-y-2 overflow-hidden">
                <div className="flex items-center gap-x-2">
                  Create new Check
                </div>
                <div className="flex items-center gap-x-2">
                  <ValueEditable
                    ref={ref}
                    className="w-1/2 text-sm text-gray-500"
                    value={''}
                    isAlwaysEditable
                    onEditEnd={onIdentifierChange}
                    intermediatePattern={IDENTIFIER_PATTERN_INTERMEDIATE}
                    placeholder="New identifier"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  })
