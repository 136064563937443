import { Disclosure } from '@headlessui/react'
import { ArrowPathIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import type { TranslationObject } from '../GraphQL/graphql'

type Props = {
  treeDataId: string
  reportName: string
  reportTemplate: TranslationObject[]
  activeId: string
}

export const ConversationTreeReport: FC<Props> = ({
  reportName,
  reportTemplate: remoteReportTemplate,
  activeId,
}) => {
  const [reportTemplate, setReportTemplate] = useState(remoteReportTemplate)
  const [isDeleting, setIsDeleting] = useState(false)

  useEffect(() => {
    setReportTemplate(remoteReportTemplate)
    setIsDeleting(false)
  }, [remoteReportTemplate])

  return (
    <Disclosure
      as="div"
      id={reportName}
      className={classNames('bg-white', isDeleting && 'animate-pulse')}
    >
      <div
        className={classNames(
          'flex items-center p-4 gap-x-4 sm:gap-x-6 sm:px-6',
          reportName === activeId && 'bg-primary-700/20'
        )}
      >
        <Disclosure.Button className="flex-none">
          {isDeleting || !reportTemplate ? (
            <ArrowPathIcon className="w-12 h-12 p-3 -m-3 animate-spin" />
          ) : (
            <ChevronRightIcon className="w-12 h-12 p-3 -m-3 transition ui-open:rotate-90" />
          )}
        </Disclosure.Button>
        <div className="flex-auto space-y-2 overflow-hidden">
          <div className="flex items-center gap-x-2">{reportName}</div>
        </div>
      </div>

      <Disclosure.Panel className="flex flex-col gap-6 px-6 py-4 text-sm bg-gray-100 sm:px-6 whitespace-nowrap sm:flex-row">
        <div className="flex-auto">
          <textarea
            className="w-full font-mono text-sm bg-white border-0 rounded-md shadow resize-none h-96"
            value={reportTemplate.at(0)?.text}
            readOnly
          />
        </div>
      </Disclosure.Panel>
    </Disclosure>
  )
}
