import { useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  BaseUserFragment,
  GetRegisteredUsersQuery,
  GetRegisteredUsersQueryVariables,
} from '../GraphQL/graphql'
import {
  GetRegisteredUsersDocument,
  GetRegisteredUsersUpdatesDocument,
} from '../GraphQL/graphql'

export type UseRegisteredUsersResponse = {
  users: BaseUserFragment[]
  usersLoading: boolean
}

export const useRegisteredUsers = (): UseRegisteredUsersResponse => {
  const { data, loading, subscribeToMore } = useQuery<
    GetRegisteredUsersQuery,
    GetRegisteredUsersQueryVariables
  >(GetRegisteredUsersDocument, { fetchPolicy: 'cache-and-network' })

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: GetRegisteredUsersUpdatesDocument,
      updateQuery: (_, options) => options.subscriptionData.data,
    })
    return () => unsubscribe()
  }, [subscribeToMore])

  return useMemo(
    () => ({
      users: data?.getRegisteredUsers ?? [],
      usersLoading: loading,
    }),
    [data, loading]
  )
}
