import classNames from 'classnames'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DetailViewCard } from '../Components/DetailViewCard'
import { UserAvatar } from '../Components/UserAvatar'
import { useParamUser } from '../Hooks/useParamUser'
import { useUser } from '../Hooks/useUser'
import { UserDangerZone } from '../Modules/UserDangerZone'
import { UserDetailsLoginLink } from '../Modules/UserDetailsLoginLink'
import { UserDetailsSessions } from '../Modules/UserDetailsSessions'
import { UserStateActivated } from '../Modules/UserStateActivated'
import type { AppShellComponent } from './AppShell'

type UserDetailsParams = {
  userId: string
}

export const ResearchUserDetails: AppShellComponent = () => {
  const navigate = useNavigate()

  const { userId } = useParams<UserDetailsParams>()

  const { user, userLoading } = useUser(userId)

  useEffect(() => {
    if (userLoading || !user) return
    if (user.isResearch) return

    navigate(`/users/${user.id}`)
  }, [navigate, user, userLoading])

  if (userLoading || !user) {
    return null
  }

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
      <DetailViewCard title="Research Information">
        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="participant-id"
              className="block text-sm font-medium leading-6"
            >
              Participant ID
            </label>
            <div className="mt-2">
              <input
                type="text"
                value={user.researchParticipantId ?? undefined}
                id="participant-id"
                readOnly
                className="font-mono block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6 read-only:bg-gray-50"
              />
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="research-name"
              className="block text-sm font-medium leading-6"
            >
              Research Name
            </label>
            <div className="mt-2">
              <input
                type="text"
                value={user.researchName ?? undefined}
                id="research-name"
                readOnly
                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6 read-only:bg-gray-50"
              />
            </div>
          </div>

          <div className="sm:col-span-6">
            <label
              htmlFor="research-center"
              className="block text-sm font-medium leading-6"
            >
              Research Center
            </label>
            <div className="mt-2">
              <input
                type="text"
                value={user.researchCenter ?? undefined}
                id="research-center"
                readOnly
                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6 read-only:bg-gray-50"
              />
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="research-group"
              className="block text-sm font-medium leading-6"
            >
              Research Group
            </label>
            <div className="mt-2">
              <input
                type="text"
                value={user.researchGroup ?? undefined}
                id="research-group"
                readOnly
                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6 read-only:bg-gray-50"
              />
            </div>
          </div>
        </div>
      </DetailViewCard>

      <UserDetailsLoginLink user={user} />

      <UserDetailsSessions user={user} />

      <UserDangerZone user={user} />
    </div>
  )
}

ResearchUserDetails.Header = (() => {
  const { user } = useParamUser()

  return (
    <>
      {user && (
        <div
          className={classNames(
            'flex items-center flex-1 min-w-0 space-x-3',
            !user.isActivated && 'grayscale'
          )}
        >
          <UserAvatar size={8} user={user} />
          <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
            <span
              className={classNames(
                'font-mono',
                !user.isActivated && 'line-through'
              )}
            >
              {user?.researchParticipantId}
            </span>{' '}
            <span className="text-lg text-gray-500">Research User</span>
          </h1>
        </div>
      )}
      <div className="flex items-center mt-6 space-x-3 md:ml-4 md:mt-0">
        {user && <UserStateActivated user={user} />}
      </div>
    </>
  )
}) satisfies FC
