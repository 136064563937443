import _ from 'lodash'
import type { FC, HTMLAttributes } from 'react'
import { useEffect, useState } from 'react'
import type { ComboboxOption, ComboboxOptionHandler } from './Combobox'
import { Combobox } from './Combobox'

type Props = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  onEditEnd?: ComboboxOptionHandler
  value?: ComboboxOption
  options?: ComboboxOption[]
  placeholder?: string
}

export const ValueSelectable: FC<Props> = ({
  value: initialValue,
  options,
  placeholder,
  onEditEnd = _.noop,
  ...args
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onChange: ComboboxOptionHandler = (option) => {
    setIsEditing(false)
    setValue(option ?? undefined)
    onEditEnd(option)
  }

  return (
    <Combobox
      isDisabled={!isEditing}
      value={value}
      options={options}
      placeholder={placeholder}
      onChange={onChange}
      onDoubleClick={() => setIsEditing(true)}
      onKeyDown={({ code }) => {
        switch (code) {
          case 'Escape':
            setIsEditing(false)
            break
        }
      }}
      {...args}
    />
  )
}
