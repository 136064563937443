import type { TFunction } from 'i18next'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { DateTime } from 'luxon'
import type { ResearchGroup, UserRights, UserRole } from '../GraphQL/graphql'
import { AuthError } from '../GraphQL/graphql'

const ACCESS_TOKEN_COOKIE = 'drat'

export type AccessTokenType = {
  exp: number
  iat: number
  userId: string
  userRole: UserRole
  userRights?: UserRights
  userIsComplete: boolean
  userIsResearch: boolean
  researchGroup?: ResearchGroup
}

export const getAccessTokenContent = (token?: string) => {
  if (!token) {
    token = Cookies.get(ACCESS_TOKEN_COOKIE) ?? ''
  }
  let content: Partial<AccessTokenType> | undefined
  try {
    content = jwtDecode<Partial<AccessTokenType>>(token)
  } catch {
    // error decoding
  }
  if (DateTime.fromSeconds(content?.exp ?? 0) > DateTime.now()) {
    return content
  } else {
    return undefined
  }
}

export const setAccessToken = (token: string | undefined | null) => {
  token = token ?? ''
  const { exp } = getAccessTokenContent(token) ?? {}
  const expires = exp ? DateTime.fromSeconds(exp).toJSDate() : 0
  Cookies.set(ACCESS_TOKEN_COOKIE, token, {
    sameSite: process.env.NODE_ENV === 'production' ? 'none' : undefined,
    // domain: 'docrobin.ai',
    secure: process.env.NODE_ENV === 'production',
    expires,
  })
  // Cookies.set(ACCESS_TOKEN_COOKIE, token, {
  //   sameSite: 'None',
  //   secure: false,
  //   expires,
  // })
}

export const getAccessToken = () => {
  const token = Cookies.get(ACCESS_TOKEN_COOKIE) ?? ''
  const { exp } = getAccessTokenContent(token) ?? {}
  if (DateTime.fromSeconds(exp ?? 0) > DateTime.now()) {
    return token
  } else {
    return ''
  }
}

export const authorizationHeader = (token: string): string => {
  return token ? `bearer ${token}` : ''
}

export const authErrorToMessage = (error: AuthError, t: TFunction): string => {
  switch (error) {
    case AuthError.RegistrationFailed:
      return t('error-registration-failed')
    case AuthError.ExistingUser:
      return t('error-duplicate-user')
    case AuthError.InvalidUser:
      return t('error-registration-failed')
    case AuthError.UnactivatedUser:
      return t('error-user-not-activated')
    case AuthError.UnknownUser:
      return t('error-no-matching-user')
    case AuthError.UnverifiedEmail:
      return t('error-unconfirmed-email')
    case AuthError.UnverifiedPhone:
      return t('error-unconfirmed-phone')
    case AuthError.InvalidEmailToken:
      return t('error-activation-link-expired')
    case AuthError.InvalidPasswordToken:
      return t('error-incorrect-password')
    case AuthError.InvalidTwoFaToken:
      return t('error-invalid-sms-token')
    case AuthError.NoEmailChange:
      return t('error-email-not-updated')
    case AuthError.VerifiedUser:
      return t('error-already-verified')
    case AuthError.VerificationEmailSendingError:
      return t('error-verification-email-sending')
    case AuthError.VerificationSmsSendingError:
      return t('error-verification-sms-sending')
    case AuthError.InvalidPassword:
      return t('error-invalid-password')
  }
}
