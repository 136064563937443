import { useMutation } from '@apollo/client'
import { LockClosedIcon } from '@heroicons/react/20/solid'
import type { FC, FormEventHandler } from 'react'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../Contexts/AuthContext'
import type {
  LoginUserMutation,
  LoginUserMutationVariables,
  SendSecondAuthFactorMutation,
  SendSecondAuthFactorMutationVariables,
} from '../GraphQL/graphql'
import {
  LoginUserDocument,
  SendSecondAuthFactorDocument,
} from '../GraphQL/graphql'
import logo from '../logo.png'

enum LoginState {
  EnterEmailPassword,
  VerifyPhone,
}

export const Login: FC = () => {
  const { t } = useTranslation()

  const { updateAuth } = useContext(AuthContext)

  const [formState, setFormState] = useState<LoginState>(
    LoginState.EnterEmailPassword
  )

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [factor, setFactor] = useState('')

  const [loginUser] = useMutation<
    LoginUserMutation,
    LoginUserMutationVariables
  >(LoginUserDocument)

  const [sendSecondAuthFactor] = useMutation<
    SendSecondAuthFactorMutation,
    SendSecondAuthFactorMutationVariables
  >(SendSecondAuthFactorDocument)

  const smsTokenRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    formState === LoginState.VerifyPhone && smsTokenRef.current?.focus()
  }, [formState])

  const doLoginStep1: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()
      const { data, errors } = await sendSecondAuthFactor({
        variables: { email, password },
      })

      if (errors || !data?.sendSecondAuthFactor.success) {
        // TODO: add error message
        return
      }
      setFormState(LoginState.VerifyPhone)
    },
    [email, password, sendSecondAuthFactor]
  )

  const doLoginStep2: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()
      const { data, errors } = await loginUser({
        variables: { email, password, factor },
      })

      if (errors || data?.loginUser.error || !data?.loginUser.accessToken) {
        // TODO: add error message
      } else {
        updateAuth(data.loginUser.accessToken)
      }
    },
    [email, factor, loginUser, password, updateAuth]
  )

  return (
    <div className="flex items-center justify-center min-h-full px-6 py-12 lg:px-8">
      <form
        onSubmit={
          formState === LoginState.EnterEmailPassword
            ? doLoginStep1
            : doLoginStep2
        }
        className="w-full max-w-md -mt-12 space-y-8"
      >
        <div>
          <img className="w-auto h-16 mx-auto" src={logo} alt="DocRobin" />
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-center">
            {t('Sign in to your account')}
          </h2>
        </div>
        <div className="mt-8 space-y-6">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">
                {t('Email address')}
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                disabled={formState !== LoginState.EnterEmailPassword}
                className="relative block w-full rounded-t-md border-0 py-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 disabled:bg-gray-200"
                placeholder={t('Email address')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                {t('Password')}
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                disabled={formState !== LoginState.EnterEmailPassword}
                className="relative block w-full border-0 py-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 disabled:bg-gray-200"
                placeholder={t('Password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="smsToken" className="sr-only">
                {t('SMS Token')}
              </label>
              <input
                id="smsToken"
                name="smsToken"
                ref={smsTokenRef}
                type="text"
                inputMode="numeric"
                autoComplete="smsToken"
                required
                disabled={formState !== LoginState.VerifyPhone}
                className="relative block w-full rounded-b-md border-0 py-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 disabled:bg-gray-200"
                placeholder={t('SMS Token')}
                value={factor}
                onChange={(e) => {
                  if (/^\d{0,6}$/.test(e.target.value)) {
                    setFactor(e.target.value)
                  }
                }}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="relative flex justify-center w-full px-3 py-2 text-sm font-semibold text-white rounded-md bg-primary-600 group hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <LockClosedIcon
                  className="w-5 h-5 text-primary-500 group-hover:text-primary-400"
                  aria-hidden="true"
                />
              </span>
              {formState === LoginState.EnterEmailPassword
                ? t('Send SMS Token')
                : t('Sign in')}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
