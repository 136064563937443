import { useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  GetAdminDashboardInfoQuery,
  GetAdminDashboardInfoQueryVariables,
} from '../GraphQL/graphql'
import {
  GetAdminDashboardInfoDocument,
  GetAdminDashboardInfoUpdatesDocument,
} from '../GraphQL/graphql'

export const useDashboardInfo = () => {
  const { data, loading, subscribeToMore } = useQuery<
    GetAdminDashboardInfoQuery,
    GetAdminDashboardInfoQueryVariables
  >(GetAdminDashboardInfoDocument, { fetchPolicy: 'cache-and-network' })

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: GetAdminDashboardInfoUpdatesDocument,
      updateQuery: (_, options) => options.subscriptionData.data,
    })
    return () => unsubscribe()
  }, [subscribeToMore])

  return useMemo(
    () => ({
      infos: data?.getAdminDashboardInfo ?? [],
      loading: loading,
    }),
    [data, loading]
  )
}
