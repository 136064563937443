import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  DeleteConversationTreeMutation,
  DeleteConversationTreeMutationVariables,
  GetConversationTreeQuery,
  GetConversationTreeQueryVariables,
  UpsertConversationTreeMutation,
  UpsertConversationTreeMutationVariables,
} from '../GraphQL/graphql'
import {
  DeleteConversationTreeDocument,
  GetConversationTreeDocument,
  GetConversationTreeUpdatesDocument,
  UpsertConversationTreeDocument,
} from '../GraphQL/graphql'

export const useConversationTree = (treeId?: string) => {
  const [getConversationTree, { data, error, loading, subscribeToMore }] =
    useLazyQuery<GetConversationTreeQuery, GetConversationTreeQueryVariables>(
      GetConversationTreeDocument,
      {
        fetchPolicy: 'cache-and-network',
      }
    )

  useEffect(() => {
    treeId &&
      getConversationTree({
        variables: {
          treeId,
        },
      })
  }, [getConversationTree, treeId])

  useEffect(() => {
    if (!treeId) return
    const unsubscribe = subscribeToMore({
      document: GetConversationTreeUpdatesDocument,
      variables: {
        treeId,
      } satisfies GetConversationTreeQueryVariables,
      updateQuery: (_, { subscriptionData }) => subscriptionData.data,
    })
    return () => unsubscribe()
  }, [subscribeToMore, treeId])

  const upsertConversationTree = useMutation<
    UpsertConversationTreeMutation,
    UpsertConversationTreeMutationVariables
  >(UpsertConversationTreeDocument)

  const deleteConversationTree = useMutation<
    DeleteConversationTreeMutation,
    DeleteConversationTreeMutationVariables
  >(DeleteConversationTreeDocument)

  const mutations = useMemo(
    () => ({
      upsertConversationTree,
      deleteConversationTree,
    }),
    [deleteConversationTree, upsertConversationTree]
  )

  return useMemo(
    () => ({
      tree:
        treeId && data?.getConversationTree.tree
          ? data.getConversationTree.tree
          : undefined,
      treeLoading: loading,
      treeError: error ?? data?.getConversationTree.error,
      mutations,
    }),
    [data, error, loading, mutations, treeId]
  )
}
