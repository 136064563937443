import { Disclosure } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid'
import {
  ArrowPathIcon,
  ChevronDoubleRightIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import _ from 'lodash'
import type { FC, MouseEventHandler } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { DeleteButton } from '../Components/DeleteButton'
import type { ValueEditableHandler } from '../Components/ValueEditable'
import { ValueEditable } from '../Components/ValueEditable'
import type { TreeCheckObject } from '../GraphQL/graphql'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'
import { ConversationTreeElementNext } from './ConversationTreeElementNext'

type Props = {
  treeDataId: string
  check: TreeCheckObject
  activeId: string
}

export const ConversationTreeCheck: FC<Props> = ({
  treeDataId,
  check: remoteCheck,
  activeId,
}) => {
  const [check, setCheck] = useState(remoteCheck)
  const [isDeleting, setIsDeleting] = useState(false)

  useEffect(() => {
    setCheck(remoteCheck)
    setIsDeleting(false)
  }, [remoteCheck])

  const {
    treeData,
    validation,
    mutations: {
      deleteConversationTreeElement: [deleteConversationTreeElement],
      upsertConversationTreeCheck: [upsertConversationTreeCheck],
    },
  } = useConversationTreeData(treeDataId)

  const onIdentifierChange: ValueEditableHandler = useCallback(
    (value) => {
      if (check.identifier === value) return

      const intermediateCheck = _.cloneDeep(check)

      intermediateCheck.identifier = value

      setCheck(intermediateCheck)

      upsertConversationTreeCheck({
        variables: { input: intermediateCheck, treeDataId },
      })
    },
    [check, treeDataId, upsertConversationTreeCheck]
  )

  const onValidatorChange: ValueEditableHandler = useCallback(
    (value) => {
      if (check.validator === value) return

      const intermediateCheck = _.cloneDeep(check)

      intermediateCheck.validator = value

      setCheck(intermediateCheck)

      upsertConversationTreeCheck({
        variables: { input: intermediateCheck, treeDataId },
      })
    },
    [check, treeDataId, upsertConversationTreeCheck]
  )

  const onDelete: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setIsDeleting(true)

    deleteConversationTreeElement({
      variables: { treeDataId, elementId: check.id },
    })
  }, [deleteConversationTreeElement, check.id, treeDataId])

  const trueUrl = check.trueNext?.nextId && `#${check.trueNext?.nextId}`
  const falseUrl = check.falseNext?.nextId && `#${check.falseNext?.nextId}`

  return (
    <Disclosure
      as="div"
      id={check.id}
      className={classNames('bg-white', isDeleting && 'animate-pulse')}
    >
      <div
        className={classNames(
          'flex items-center p-4 gap-x-4 sm:gap-x-6 sm:px-6',
          check.id === activeId && 'bg-primary-700/20',
          validation?.unusedElements.includes(check.id) && 'opacity-50'
        )}
      >
        <Disclosure.Button className="flex-none">
          {isDeleting || !treeData || !check ? (
            <ArrowPathIcon className="w-12 h-12 p-3 -m-3 animate-spin" />
          ) : (
            <ChevronRightIcon className="w-12 h-12 p-3 -m-3 transition ui-open:rotate-90" />
          )}
        </Disclosure.Button>
        <div className="flex-auto space-y-2 overflow-hidden">
          <div className="flex items-center gap-x-2">
            <ValueEditable
              className="flex-shrink w-full truncate"
              value={check.validator}
              onEditEnd={onValidatorChange}
              placeholder="No Validator"
            />
          </div>
          <div className="flex items-center gap-x-2">
            <ValueEditable
              className="w-1/2 text-sm text-gray-500"
              invalid={
                validation?.invalidElementIdentifiers.includes(check.id) ||
                validation?.duplicateElementIdentifiers.includes(check.id)
              }
              value={check.identifier}
              onEditEnd={onIdentifierChange}
              intermediatePattern={/^\S*$/g}
            />
          </div>
        </div>
        <div className="flex items-center flex-none gap-x-8">
          <div className="h-6 w-7">
            {trueUrl && (
              <Link
                to={trueUrl}
                className="block p-3 -m-3 rounded hover:bg-gray-900/10"
                title="On Next"
              >
                <ChevronDoubleRightIcon
                  className="inline w-5 h-5 text-primary-700"
                  aria-hidden="true"
                />
                <CheckIcon
                  className="relative inline w-4 h-4 -mx-1 -top-1 text-primary-700"
                  aria-hidden="true"
                />
              </Link>
            )}
          </div>
          <div className="w-7">
            {falseUrl && (
              <Link
                to={falseUrl}
                className="block p-3 -m-3 rounded hover:bg-gray-900/10"
                title="On Next"
              >
                <ChevronDoubleRightIcon
                  className="inline w-5 h-5 text-primary-700"
                  aria-hidden="true"
                />
                <XMarkIcon
                  className="relative inline w-4 h-4 -mx-1 -top-1 text-primary-700"
                  aria-hidden="true"
                />
              </Link>
            )}
          </div>
        </div>
      </div>

      {treeData && (
        <Disclosure.Panel className="flex flex-col gap-6 px-6 py-4 text-sm bg-gray-100 sm:px-6 whitespace-nowrap sm:flex-row">
          <div className="flex-auto space-y-4">
            <ConversationTreeElementNext
              element={check}
              nextKey="trueNext"
              treeData={treeData}
              label="Check Valid - Next Element"
            />
            <ConversationTreeElementNext
              element={check}
              nextKey="falseNext"
              treeData={treeData}
              label="Check Invalid - Next Element"
            />
          </div>
          <div className="flex-shrink w-full space-y-4 sm:w-64 md:w-72">
            <div className="bg-white divide-y rounded-md shadow divide-primary-400">
              <div className="px-4 py-3 font-semibold text-primary-700">
                Check Options
              </div>
              <div className="relative flex justify-between px-4 py-3 gap-x-4">
                <div className="flex-auto max-w-full space-y-2">
                  <div className="flex items-center justify-between gap-x-4">
                    <div className="truncate">Delete Check</div>
                    <DeleteButton className="flex-none" onDelete={onDelete} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Disclosure.Panel>
      )}
    </Disclosure>
  )
}
