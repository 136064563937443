import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import type {
  GetReportsQueueQuery,
  GetReportsQueueQueryVariables,
} from '../GraphQL/graphql'
import {
  GetReportsQueueDocument,
  GetReportsQueueUpdatesDocument,
} from '../GraphQL/graphql'

export const useReportsQueue = () => {
  const [getReportsQueue, { data, loading, subscribeToMore }] = useLazyQuery<
    GetReportsQueueQuery,
    GetReportsQueueQueryVariables
  >(GetReportsQueueDocument)

  useEffect(() => {
    getReportsQueue({ variables: {} })
  }, [data, getReportsQueue, loading])

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: GetReportsQueueUpdatesDocument,
      variables: {},
      updateQuery: (_, options) => {
        return options.subscriptionData.data
      },
    })

    return () => unsubscribe()
  }, [subscribeToMore])

  return useMemo(
    () => ({
      reportsQueue: data?.getReportsQueue?.reports ?? [],
      reportsQueueLoading: loading,
    }),
    [data?.getReportsQueue?.reports, loading]
  )
}
