import {
  CheckIcon,
  ChevronDoubleRightIcon,
  PauseCircleIcon,
  PlayCircleIcon,
  RocketLaunchIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import _ from 'lodash'
import { DateTime } from 'luxon'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-scroll'
import { ButtonLink } from '../Components/ButtonLink'
import type { CardProps } from '../Components/Card'
import { CardStyle } from '../Components/Card'
import { CardGrid } from '../Components/CardGrid'
import type {
  ComboboxOption,
  ComboboxOptionHandler,
} from '../Components/Combobox'
import { Table } from '../Components/Table'
import { useConversationTree } from '../Hooks/useConversationTree'
import { useConversationTreeData } from '../Hooks/useConversationTreeData'
import { ConversationTreeChecks } from '../Modules/ConversationTreeChecks'
import { ConversationTreeCriteria } from '../Modules/ConversationTreeCriteria'
import { ConversationTreeReports } from '../Modules/ConversationTreeReports'
import { ConversationTreeStatements } from '../Modules/ConversationTreeStatements'
import { sleep } from '../Utils/TimingUtils'
import type { AppShellComponent } from './AppShell'
import { ConversationTreeSettings } from './ConversationTreeSettings'

type ConversationTreeDataDetailsParams = {
  treeId: string
  treeDataId: string
}

export const ConversationTreeDataDetails: AppShellComponent = () => {
  const { treeDataId, treeId } = useParams<ConversationTreeDataDetailsParams>()

  const navigate = useNavigate()
  const { hash } = useLocation()
  const { i18n } = useTranslation()

  const [activeId, setActiveId] = useState('')

  const {
    treeData,
    validation,
    treeDataLoading,
    mutations: {
      setConversationTreeDataSettings: [setConversationTreeDataSettings],
    },
  } = useConversationTreeData(treeDataId)
  const { tree } = useConversationTree(treeId)

  useEffect(() => {
    const handler = async () => {
      const id = hash.substring(1)

      setActiveId(id)

      let element: HTMLElement | null = document.getElementById(id)
      let i = 0
      while (!element && id && i <= 9) {
        await sleep(100 * i++)
        element = document.getElementById(id)
      }

      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
    handler()
  }, [hash])

  const { actions, checks, statements, criteria } = useMemo(
    () => ({
      actions: _.sortBy(treeData?.actions, [
        ({ identifier }) => identifier,
        ({ id }) => id,
      ]),
      checks: _.sortBy(
        treeData?.checks,
        [({ identifier }) => identifier],
        ({ id }) => id
      ),
      statements: _.sortBy(treeData?.statements, [
        ({ identifier }) => identifier,
        ({ id }) => id,
      ]),
      criteria: _.sortBy(
        treeData?.criteria,
        [({ identifier }) => identifier],
        ({ id }) => id
      ),
    }),
    [treeData]
  )

  const elements = useMemo(
    () => [...actions, ...checks, ...statements, ...criteria],
    [actions, checks, statements, criteria]
  )

  const errors = useMemo(
    () =>
      Object.values(validation ?? {})
        .filter((item) => Array.isArray(item))
        .flat(),
    [validation]
  )

  const startElementOptions = useMemo(
    () =>
      statements.map(
        (element): ComboboxOption => ({
          key: element.id,
          value: element.identifier,
        })
      ),
    [statements]
  )

  const onStartElementChange: ComboboxOptionHandler = useCallback(
    (startOption) => {
      const startId = startOption?.key

      const intermediateTreeData = _.cloneDeep(treeData)

      if (!intermediateTreeData || !treeData) return

      intermediateTreeData.settings.startId = startId ?? null

      setConversationTreeDataSettings({
        variables: {
          treeDataId: intermediateTreeData.id,
          input: {
            startId: intermediateTreeData.settings.startId,
          },
        },
      })
    },
    [setConversationTreeDataSettings, treeData]
  )

  const cards = useMemo((): CardProps[] => {
    if (!treeData) return []

    const cards: CardProps[] = []
    const startElement = elements.find(
      ({ id }) => id === treeData.settings.startId
    )

    const hasValidStart =
      (treeData.settings.startId && startElement) || undefined

    cards.push({
      name: 'Start Element',
      link: hasValidStart && `#${startElement?.id}`,
      linkText: hasValidStart && 'Go To Start',
      Icon: hasValidStart ? PlayCircleIcon : ShieldExclamationIcon,
      info: startElement?.identifier,
      infoPlaceholder: 'missing',
      infoOptions: startElementOptions,
      onInfoChange: onStartElementChange,
      style: hasValidStart ? CardStyle.NEUTRAL : CardStyle.DANGER,
    })

    if (errors.length) {
      cards.push({
        name: 'Errors',
        link: `#${errors.at(0)}`,
        linkText: 'View next error',
        Icon: ShieldExclamationIcon,
        info: errors.length,
        style: CardStyle.DANGER,
      })
    } else {
      cards.push({
        name: 'Errors',
        Icon: ShieldCheckIcon,
        info: errors.length,
        style: CardStyle.SUCCESS,
      })
    }

    if (treeData.isActive) {
      cards.push({
        name: '',
        Icon: RocketLaunchIcon,
        info: 'Currently active',
        style: CardStyle.SUCCESS,
      })
    } else if (treeData.activeFromTimestamp) {
      cards.push({
        name: '',
        link: 'activate',
        linkText: 'Reactivate',
        Icon: PauseCircleIcon,
        info: `Was active from ${DateTime.fromISO(
          treeData.activeFromTimestamp
        ).toLocaleString(DateTime.DATETIME_MED, { locale: i18n.language })}`,
        style: CardStyle.NEUTRAL,
      })
    } else {
      cards.push({
        name: '',
        link: 'activate',
        linkText: 'Activate',
        info: 'Not yet active',
        style: CardStyle.NEUTRAL,
      })
    }

    return cards
  }, [
    elements,
    errors,
    i18n.language,
    onStartElementChange,
    startElementOptions,
    treeData,
  ])

  if (!treeDataId || treeDataLoading || !treeData) {
    return <>loading</>
  }

  return (
    <div className="space-y-8">
      <CardGrid cards={cards} />

      <div id="actions">
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.HeaderCell className="w-20" />
              <Table.HeaderCell className="w-20" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {actions.map((action) => {
              const { id } = action
              const successUrl =
                action.successNext?.nextId && `#${action.successNext?.nextId}`
              const errorUrl =
                action.errorNext?.nextId && `#${action.errorNext?.nextId}`
              return (
                <Table.Row
                  key={id}
                  id={id}
                  className={classNames({
                    'bg-primary-700/20': id === activeId,
                    'opacity-50': validation?.unusedElements.includes(
                      action.id
                    ),
                  })}
                >
                  <Table.Cell>
                    <div className="truncate">{action.text.at(0)?.text}</div>
                    <div
                      className={classNames(
                        'mt-1 text-xs',
                        validation?.invalidElementIdentifiers.includes(
                          action.id
                        ) ||
                          validation?.duplicateElementIdentifiers.includes(
                            action.id
                          )
                          ? 'text-red-500'
                          : 'text-gray-500'
                      )}
                    >
                      {action.identifier}
                    </div>
                  </Table.Cell>
                  <Table.Cell
                    onClick={() => successUrl && navigate(successUrl)}
                    className={classNames(
                      'text-center',
                      successUrl && 'cursor-pointer hover:bg-gray-900/10'
                    )}
                    title={successUrl && 'On Success'}
                  >
                    {successUrl && (
                      <>
                        <ChevronDoubleRightIcon
                          className="inline w-5 h-5 text-primary-700"
                          aria-hidden="true"
                        />
                        <CheckIcon
                          className="relative inline w-4 h-4 -mx-1 -top-1 text-primary-700"
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Table.Cell>
                  <Table.Cell
                    onClick={() => errorUrl && navigate(errorUrl)}
                    className={classNames(
                      'text-center',
                      errorUrl && 'cursor-pointer hover:bg-gray-900/10'
                    )}
                    title={errorUrl && 'On Error'}
                  >
                    {errorUrl && (
                      <>
                        <ChevronDoubleRightIcon
                          className="inline w-5 h-5 text-primary-700"
                          aria-hidden="true"
                        />
                        <XMarkIcon
                          className="relative inline w-4 h-4 -mx-1 -top-1 text-primary-700"
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </div>

      <ConversationTreeChecks
        id="checks"
        checks={checks}
        treeDataId={treeDataId}
        activeId={activeId}
      />

      <ConversationTreeStatements
        id="statements"
        statements={statements}
        treeDataId={treeDataId}
        activeId={activeId}
        videoPath={tree?.videoPath ?? 'chat'}
      />

      <ConversationTreeCriteria
        id="criteria"
        criteria={criteria}
        treeDataId={treeDataId}
        activeId={activeId}
      />

      <ConversationTreeReports
        id="reports"
        reportTemplates={treeData.reportTemplates}
        treeDataId={treeDataId}
        activeId={activeId}
      />

      {tree && <ConversationTreeSettings treeId={tree.id} />}
    </div>
  )
}

ConversationTreeDataDetails.Header = (() => {
  const { t } = useTranslation()

  const { treeId, treeDataId } = useParams<ConversationTreeDataDetailsParams>()

  const { tree } = useConversationTree(treeId)
  const { treeData } = useConversationTreeData(treeDataId)

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex items-center justify-between">
        <div className="flex-1 min-w-0">
          <h1 className="text-2xl font-bold leading-7 sm:truncate sm:leading-9">
            {t('Conversation Tree')}
          </h1>
          <div className="-mt-1">
            {tree?.identifier ?? t('Conversation Tree Version')}:{' '}
            <span className="italic text-gray-500">{treeData?.identifier}</span>
          </div>
          <div className="text-sm text-gray-500">{treeData?.id}</div>
        </div>
        <div className="flex mt-6 space-x-3 sm:ml-4 sm:mt-0">
          <ButtonLink to=".." design="white">
            {t('Back to {{target}} dashboard', {
              replace: { target: t('Conversation Tree') },
            })}
          </ButtonLink>
        </div>
      </div>
      <div className="items-center justify-around hidden gap-4 -mb-3 sm:flex">
        {treeData &&
          (
            ['Actions', 'Checks', 'Statements', 'Criteria', 'Reports'] as const
          ).map((target) => (
            <Link
              key={target}
              to={target.toLowerCase()}
              smooth
              offset={-200}
              spy
              className="px-4 py-2 text-sm font-semibold rounded cursor-pointer text-primary-700"
              activeClass="bg-primary-700 text-white"
            >
              {t(target)}
            </Link>
          ))}
      </div>
    </div>
  )
}) satisfies FC
